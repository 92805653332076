.finish{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
}
.finish .ExploreOptionsHeading{
    margin-top: 0;
}
.FinishHeading{
    width:100%;
    margin-top: 25px;
    color: #0A1F44;
    font-family: Stag, sans-serif;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 40px;
    text-align: center;
}
.FinishSubHeading{
    margin-top: 22px;
    margin-bottom: 20px;
    width:65%;
    color: #0A1F44;
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
}
.FinishLearnMoreBanner{
    height: 153px;
    padding: 20px 59px;
    margin-top: 88px;
    margin-bottom: 46px;
}
.FinishLearnMoreBannerContent{
    font-family: Inter-Medium, sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.FinishLearnMoreBannerIcon{
    height: 47px;
    width: 73px;
    margin-right: 36px;
}
.LearnMoreButton{
    border: 1px solid #ffffff!important;
    background: transparent;
    color: #ffffff!important;
}
.FinishHeading br:lang(zh){
    display: none;
}
 .brEng:lang(zh){
    display:none;
}
.LearnMoreButton:hover:not([disabled]),
.LearnMoreButton:focus:not([disabled]) {
    border: 2px solid #1547b1;
    color: #ffffff!important;
    background: transparent!important;
    box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.1) !important;
}
.FinishInfoCard{
    margin-top: 35px;
}
.FinishEmailSentGroup{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin-top: 60px;
}
.FinishEmailSentGroup .Icon{
    width: 72px;
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.FinishEmailSentGroup .TextGroup{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 18px;

}
.FinishEmailSentGroup .Title{
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 31px;
    margin-bottom: 10px;
}
.FinishEmailSentGroup .Desc{
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 21px;
}
.FinishEmailSentGroup .Desc span{
    font-weight: bold;
    color:#00A758;
}
.FinishWording{
    color: #0A1F44;
    width:100%;
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 50px;

}
.FinishWording .link{
    color:#00A758;
    cursor: pointer;
    text-decoration: none;

}
.FinishWording .link:hover {
    text-decoration: underline;
}
.FinishReceiptInfo{
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #EDEDED;
    background-color: #ffffff;
    border-bottom: 5px solid #003DA5;
    padding: 20.53px 51px 18.22px 34px;
    margin-bottom: 21px;
    margin-top: 37px;
}
.FinishReceiptInfo .ItemGroup{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}
.FinishReceiptInfo .Key{
    color: #00A758;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 21.23px;
}
.FinishReceiptInfo .Value{
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 23px;
}
.FinishReceiptInfo .VLine{
    box-sizing: border-box;
    height: 65.94px;
    width: 1px;
    border: 1px dashed #8E90A2;
    margin-right: 39px;
    margin-left: 48px;
    margin-top: 8.1px;
}

.FinishBackToHomeButton{
    margin-bottom: 60px;
}
.FinishPromote{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #361558;
    padding-left: 37px;
    padding-right: 65px;
    padding-top: 28px;
    padding-bottom: 24px;
    color:#ffffff;
    margin-top: 60px;
    margin-bottom: 75px;
}
.FinishPromote .Icon{
    width: 43px;
    height: 43px;
    overflow: hidden;
}
.FinishPromote .Desc{
    color: #FFFFFF;
    font-family: Arial, Noto, sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
    flex-grow: 3;
    margin-left: 21px;
    margin-right: 12px;
}
.RatingTitleImgGroup{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 25px;
}
.RatingTitleImg{
    height: 49px;
    width: 49px;
    margin-right: 9px;
}

.RatingText{
    text-align: center;
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
    margin-bottom: 20px;
}
.StarGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    unicode-bidi: bidi-override;
    direction: rtl;
}
.StarGroup> svg{
    margin: 0 auto;
}
.StarGroup>svg .Star{
    stroke: #8E90A2;
    cursor: pointer;
}

.StarGroup>svg:hover .Star,
.StarGroup svg:hover~svg .Star,
.StarGroup svg.Picked .Star{
    /*outline: 5px solid green;*/
    stroke: #282B3E;fill: #282B3E;
}
.WhatWrong {
    margin-top: 28px;
    color: #282B3E;
    font-family: Arial, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
    margin-bottom: 18px;
}
.WrongGroup{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 14px;
}
.WrongGroup .Problem{
    box-sizing: border-box;
    height: 52px;
    width: 187px;
    border: 2px solid #EDEDED;
    outline: 1px solid transparent;
    margin: 0 auto 18px;
    text-align: center;
    color: #282B3E;
    font-family: Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.WrongGroup .Problem.Large{
    width: 215px;
}

.WrongGroup .Problem.Small{
    width: 98px;
}

.WrongGroup .Problem.Picked{
    border: 3px solid #00A758;
    outline: 0px solid transparent;
}
.Rating .InputGroup{
    width: 399px;
    margin-bottom: 54.29px;
    margin-left: auto;
}
.Rating .InputGroup .Label{
    color: #5E6073;
    font-family: Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
}
.Rating .ButtonGroup{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding: 0;
}
.Rating .ButtonGroup > div{
    height: 71px;
    color: #FFFFFF;
    font-family: Arial, Noto, sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
}

.RatingConfirm{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.RatingConfirm .RatingImg{
    height: 49px;
    width: 49px;
    margin-bottom: 25px;
}
.RatingConfirm .Title{
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 22px;
}

.RatingConfirm .Content{
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
}
.RatingConfirm .ButtonGroup{
    margin-top: 66px;
    padding-bottom: 20px;
}
.FinishSpace{
    height: 60px;
    height: 60px;
    overflow: hidden;
}
#vline{
    height: 26px;
    width: 1px;
    margin-left: 18px;
    margin-right: 18px;
    box-sizing: border-box;
    border-left: 1px solid #4EC2F6;
}
@media (max-width: 1440px) {
    .FinishPromote{
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #361558;
        padding-left: 37px;
        padding-right: 65px;
        padding-top: 28px;
        padding-bottom: 24px;
        color:#ffffff;
        margin-top: 60px;
        margin-bottom: 75px;
    }
    .FinishPromote .Icon{
        width: 43px;
        height: 43px;
        overflow: visible;
    }
    .FinishPromote .Desc{
        color: #FFFFFF;
        font-family: Arial, Noto, sans-serif;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 23px;
        flex-grow: 3;
        margin-left: 21px;
        margin-right: 12px;
    }

}
@media (max-width: 1024px) {
    #HeadingIcon1{
        width: 61px;
        height: 64px;
    }
    .finish{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
        padding-top: 32px;
    }
    .FinishHeading{
        font-family : stag, sans-serif;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 28px;
        text-align:center;
    }
    .FinishSubHeading{
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        word-break: break-all;
        width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
    }
    .HKG-template     .finish{
        max-width: 100vw;
        margin: 0;
    }

    .HKG-template     .FinishSubHeading{
        word-break: break-word;
    }


    .FinishEmailSentGroup {
        margin-right: 22px;
        margin-left: 11px;
        margin-top: 0;
    }
    .FinishEmailSentGroup .Icon {
        width: 64px;
        height: 64px;
    }
    .FinishEmailSentGroup .Title {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 23px;
        margin-bottom: 10px;
    }
    .FinishEmailSentGroup .Desc {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 18px;
    }
    .FinishInfoCard {
        flex-direction: column;
        align-items: center;
        width: calc(100% - 40px);
    }
    .FinishInfoCard .ItemRow {
        flex-direction: column;
        align-items: center;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
    }
    #vline {
        border: none;
    }
    .FinishReceiptInfo {
        box-sizing: border-box;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 22px 19.5px 27px 17px;
        margin: 37px 20px 21px;
        width: calc(100vw - 40px);
    }
    .FinishReceiptInfo .VLine {
        box-sizing: border-box;
        height: 1px;
        width: 100%;
        border: 1px dashed #8E90A2;
        margin:20px 0;

    }
    .FinishWording {
        margin-left: 20px;
        margin-right: 20px;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        width: calc(100% - 40px);
    }
    .LandingHeadingTitle {
        height: 81px;
        margin-top: 52px;
    }
    .LandingOtherPackage .LandingContainer {
        width: 90%;
        box-sizing: border-box;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: center;
        flex-direction: column;
    }
    .Package {
        margin-bottom: 25px;
    }
    .FinishBackToHomeButton{
        margin-left: 21px;
    }
    .FinishEmailSentGroup .Icon{
        flex-shrink: 0;
    }
    .FinishEmailSentGroup .TextGroup {
        flex:1 1 auto;
        flex-basis: 0px;
    }
    .FinishEmailSentGroup .TextGroup .Title{

    }
    .FinishEmailSentGroup .TextGroup .Desc{
    }
}

@media(max-width: 1024px){
    .HKG-template .FinishHeading,
    .HKG-template .FinishSubHeading
    {
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
    }
    .HKG-template .FinishInfoCard{
        margin-left: 20px;
        margin-right: 20px;
        width: calc(100% - 40px);
    }
    .HKG-template .InfoBanner{
        flex-direction: column;
        height: auto;
        align-items: flex-start;
        padding-left: 20px;
        padding-right: 20px;
        width: calc(100% - 40px);
        margin-left: 20px;
        margin-right: 20px;
    }
    .HKG-template .FinishLearnMoreBannerIcon{
        height: 36px;
        width: 55.91px;
        margin-bottom: 20px;
    }
    .HKG-template .InfoBanner .FlexGrow .FinishLearnMoreBannerContent{
        margin-top: 12px;
    }
    .HKG-template .InfoBanner .FlexGrow {
        margin-bottom: 24px;
    }
    .HKG-template .LandingOtherPackage .LandingContainer{
        width: 100%;
        align-items: center;
    }
    .HKG-template .FinishWording{
        width: calc(100% - 40px);
    }
    :lang(zh) .HKG-template .FinishWording br{
        display: none;
    }
}
