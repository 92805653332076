.Payment {

}

.Payment > .Title {
    color: #00A758;
    font-family: Arial, Noto, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 45px;
}

.ButtonGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 4%;
    padding-bottom: 73px;

}
.ButtonGroup button{
    width: 200px;
    height: 52px;
    border-radius: 28px;
}
.ButtonGroup .space {
    width: 28px;
}


.PaymentTitle {
    background-color: #282B3E;
    color: #FFFFFF;
    padding-top: 23px;
    padding-bottom: 17px;
    padding-left: 36px;
    font-family: Arial, Noto, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 42px;
}

.PaymentInputGroups {
    box-sizing: border-box;
    border: 1px solid #EDEDED;
    background-color: #FFFFFF;
    padding-top: 45px;
    padding-left: 36px;
    padding-bottom: 33px;
}
.PaymentInputGroups .InputRow{
    display: flex;
    flex-direction: row;
}
.PaymentInputGroups .InputRow:first-child{
    margin-bottom: 37px;
}
.PaymentInputGroups .InputRow .InputItem{
    width: 399px;
    display: flex;
    flex-direction: column;
    margin-right: 85px;

}
.PaymentInputGroups .InputRow .InputItem .label{
    color: #5E6073;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 15px;
}
.PaymentInputGroups .InputRow .InputItem .CVV{
    width: 92px;
}
.PaymentInputGroups .InputRow .InputItem .ExpiryDateGroup{
    display: flex;
    flex-direction: row;
}
.PaymentInputGroups .InputRow .InputItem .ExpiryDateGroup .ExpiryMonth,
.PaymentInputGroups .InputRow .InputItem .ExpiryDateGroup .ExpiryYear{
    width: 140px;
}
.PaymentInputGroups .InputRow .InputItem .ExpiryDateGroup .ExpiryMonth{
    margin-right: 15px;
}

.PaymentDeclarationGroups input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.PaymentDeclarationGroups .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 5px solid #eee;
    background-color: transparent;
}

/* On mouse-over, add a grey background color */
.PaymentDeclarationGroups .container:hover input[type=checkbox] ~ .checkmark {

}

/* Create the checkmark/indicator (hidden when not checked) */
.PaymentDeclarationGroups .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.PaymentDeclarationGroups .container input[type=checkbox]:checked ~ .checkmark:after {
    display: block;
}



.PaymentDeclarationGroups .container {
    position: relative;
    height: 45px;
    /*line-height: 45px;*/
    padding-left: 73px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
}

.PaymentDeclarationGroups .container .Wording{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 47px;
}



.PaymentDeclarationGroups .Link:hover {
    text-decoration: underline;
}

.PaymentCardCompany{
    height: 81px;
    padding-top: 31px;
    padding-bottom: 10px;
    padding-left: 33px;
}
.PersonalInformationCollectionStatement,
.MajorExclusions,
.Declarations{
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
}
.PersonalInformationCollectionStatement ol{
    padding-right: 10px;
}
.PersonalInformationCollectionStatement .bold{
    font-weight: bold;
}
.MajorExclusions ul,
.Declarations ul{

    list-style: none;
    padding: 0;
}

.MajorExclusions li,
.Declarations li{
    display: table-row;
}

.PersonalInformationCollectionStatement li{
    padding-left: 20px;

}

.MajorExclusions li::before,
.Declarations li::before{
    display: table-cell;
    content: '•';
    padding-left: 5px;
    padding-right: 20px;
}
.PaymentHeadingIcon{
    width: 49px;
    height: 47px;
}
.PaymentHomeIcon{
    height: 30px!important;
    width: 40px!important;
    /*margin-right: 13px;*/
}
.PaymentPlanName{
    margin-bottom: 24px;
}

.PaymentInsurancePeriod{
    color: #0A1F44;
    font-family: Inter-Bold, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    min-width: 217px;
}
.OccupancyType{
    color: #0A1F44;
    font-family: Inter-Bold, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    list-style-type:none;
    min-width: 100px;
}
.InsuredPersonMember{
    color: #0A1F44;
    font-family: Inter-Medium, sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
}
.PaymentAmountHeading{
    color: #000000;
    font-family: Inter-Bold, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    margin-bottom: 11px;
}
.PaymentAmountSGD{
    color: #000000;
    font-family: Inter-Bold, sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    margin-bottom: 48px;
}
.PaymentInfoContainer{
    margin-top: 34px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.PaymentAmountGroup{
    height: 177px;
    width: 266px;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 40px 43.5px;

}

.PaymentInfoGroup{
    margin: 40px 43.5px;
}
.PaymentInputFields{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 800px;

}
.PaymentInputField{
    width: 346px;
    height: 56px;
    min-width: 275px;
    margin:20px 20px;
    box-sizing:border-box;

}
@media (max-width: 1024px) {
    .OTMobileCenter{
        text-align: center;
        max-width: 85%;
        margin: auto;
    }
    .PaymentInfoGroup{
        margin-left: 0;
        margin-right: 0;
    }
    .PaymentInputFields{
        width: 100%;
    }
    .PaymentInputField{
        min-width: 0;
        width: 100%;
    }
    .InsuredPersonGroup {
        background-color: #FFFFFF;
        box-sizing: border-box;
        border: 1px solid #EDEDED;
        border-bottom: 5px solid #00A758;
        padding: 30px 36px 26px 22px;
        margin-top: 26px;
        position: relative;
    }
    .InsuredPersonGroup > .PersonContentInfoGroup > .Row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .InsuredPersonGroup > .PersonContentInfoGroup > .Row:not(:last-child) {
        margin-bottom: 0px;
    }

    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;
        width: 100%;
        margin-bottom: 24px;
    }

    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem > .Key {
        width: 150px;
        /*margin-bottom: 24px;*/
    }
    /*.InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem > .Value {*/
    /*    margin-bottom: 24px;*/
    /*}*/
    .Payment{
        padding: 0 20px;
    }
    .PlanSummaryInfoGroup > .RowGroup > .Row {
        padding-left: 23px;
        padding-right: 27px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    .PlanSummaryInfoGroup > .RowGroup > .Row .Empty{
        display: none;
    }

    .PlanSummaryInfoGroup > .RowGroup > .Row .Key {
        display: flex;
        flex-direction: row;
        flex-grow: 10;
        width: 100%;
        align-items: center;
    }
    .PlanSummaryInfoGroup > .RowGroup > .Row .Key.MobileOneRow {
        display: flex;
        flex-direction: row;
        flex-grow: 2;
        align-items: center;
        justify-content: space-between;
    }
    .PlanSummaryInfoGroup > .RowGroup > .Row .Mid {
        max-width: 190px;
    }

    .PlanSummaryInfoGroup > .RowGroup .Key .MiniIcon{
        height: 20px;
        width: 20px;
        color: #000000;
        margin-left: 20px;
        font-family: Arial, Noto, sans-serif;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        position: relative;
        display: inline-block;
    }
    .PlanSummaryInfoGroup > .TotalPremium .Amount {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 23px;
    }
    .PlanSummaryInfoGroup > .TotalPremium {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        padding: 14px 27px 17px 23px;
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .PaymentInputGroups {
        box-sizing: border-box;
        border: 1px solid #EDEDED;
        background-color: #FFFFFF;
        padding-top: 45px;
        padding-left: 22px;
        padding-right: 21px;
        padding-bottom: 33px;
    }
    .PaymentInputGroups .InputRow{
        display: flex;
        flex-direction: column;
    }
    .PaymentInputGroups .InputRow:first-child{
        margin-bottom: 13px;
    }
    .PaymentInputGroups .InputRow .InputItem{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-right: 85px;
        margin-bottom: 29px;
    }
    .PaymentDeclarationTitle {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
    }
    .PaymentTitle {
        padding-top: 15px;
        padding-bottom: 14px;
        padding-left: 18px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
    }
    .PaymentCardCompany{
        height: 81px;
        padding-top: 14px;
        padding-bottom: 0px;
        padding-left: 0px;
    }
    .PaymentDeclarationGroups .Item {
        color: #282B3E;
        font-family: Arial, Noto, sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        height: auto;
        margin-bottom: 14px;
        position: relative;
    }
    .PaymentDeclarationGroups .Error{
        margin-bottom: 20px;
    }

    .PaymentDeclarationGroups input[type=checkbox] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .PaymentDeclarationGroups .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        transform: scale(0.7);
        transform-origin: top left;
        border-radius: 50%;
        border: 5px solid #eee;
        background-color: transparent;
    }

    /* On mouse-over, add a grey background color */
    .PaymentDeclarationGroups .container:hover input[type=checkbox] ~ .checkmark {

    }

    /* When the checkbox is checked, add a blue background */

    /* Create the checkmark/indicator (hidden when not checked) */
    .PaymentDeclarationGroups .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .PaymentDeclarationGroups .container input[type=checkbox]:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .PaymentDeclarationGroups .container .checkmark:after {
        left: 10px;
        top: 5px;
        width: 10px;
        height: 17px;
        border: solid #702F8A;
        border-width: 0 5px 5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        color: #702F8A;
    }

    .PaymentDeclarationGroups .container {
        position: relative;
        height: auto;
        /*line-height: 45px;*/
        padding-left: 42px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: block;
    }

    .PaymentDeclarationGroups .container .Wording{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 62px;
    }

    .PaymentDeclarationGroups .Link {
        color: #009AE4;
        font-family: Arial, Noto, sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
    }

    .PaymentDeclarationGroups .Link:hover {
        text-decoration: underline;
    }
    .Payment .ButtonGroup {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 3;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 0px;
        padding-bottom: 0;
        width: 100vw;
    }
    .Payment .ButtonGroup div{
        flex-grow: 2;
    }
    .Payment .ButtonGroup .space{
        display: none;
    }
    .InsuredPersonGroup > .PersonRoleGroup > .Icon {
        overflow: hidden;
        margin-right: 7px;
    }
    .Payment > .Title {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        margin-top: 45px;
    }
    .PaymentDeclarationGroups .container .Wording {
        align-items: flex-start;
    }
    .PlanSummaryInfoGroup > .RowGroup .Key .MiniIcon .contentDiv{

        /*min-height: 200px;*/
        width: 100vw;
        box-sizing: border-box;
        left: -187px;
        height: auto;


    }
    .mobileBack{
        display: none;
    }
    .Payment > .ButtonGroup{
        height: 76px;
        background-color: #FFF;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        z-index: 2;
    }
    .Payment .ButtonGroup .space{
        display: none;
    }
    .Payment .ButtonGroup{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 76px;

    }
    .PaymentBriefInfoCard{
        flex-direction: column;
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .PaymentBriefInfoCard .PaymentBriefInfoCardOuter{
        flex-direction: column;
    }
    .KeyHeading {

        margin-right: 0px;
        margin-bottom: 4px;
    }
    .PaymentInsurancePeriod{
        text-align: center;
    }
    .PaymentBriefInfoCard .VLine {
        height: 20px;
        width: 1px;
        margin-left: 3%;
        margin-right: 3%;
        box-sizing: border-box;
        border-left: 1px solid transparent;
    }
    .PaymentPlanName{
        font-family: Inter-Bold, sans-serif;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 25px;
        text-align: center;
        padding-left: 30px!important;
        padding-right: 30px!important;
    }
    .PaymentInfoContainer{
        padding-top: 5px;
        padding-bottom: 0px;
    }
    .PaymentAmountGroup {
        margin: 40px 43.5px;
        margin-bottom: 10px;
    }
    .PaymentToggleGroup ul{
        padding-left: 28px;
    }
    .PaymentToggleGroup li{
        padding-left: 23px;
    }
    .Payment .PaymentRemarks {
        color: #000000;
        font-family: Inter, sans-serif;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        padding-left: 51px;
    }
}
