:root {
    --interR: "Inter-Regular", "Inter Regular", "Inter", sans-serif;
    --interSB: "Inter-SemiBold", "Inter SemiBold", "Inter", sans-serif;
    --interM: "Inter-Medium", "Inter Medium", "Inter", sans-serif;
    --interB: "Inter-Bold", "Inter Bold", "Inter", sans-serif;
    --stagM: "Stag-Medium", "Stag Medium", "Stag", sans-serif;
    --stagR: "Stag", sans-serif;
}

/* ENHANCEMENT - SLIDER STYLES */
.carousel {
    font-family: var(--interR);
    padding-top: 15px;
    z-index: 5;
    position:relative;
    background-color: #14284b;
}
.SliderButtonContainer {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.SliderButtonContainer > div {
    display: flex;
    gap: 10px;
}
button.buttonControls {
    position: absolute;
    top: 125px;
    z-index: 2;
    background: none;
    border: 0;
}
.carousel .carousel__slider {
    height: 120px;
}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray, .carousel__slide {
    display: flex !important;
    justify-content: center;
}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__inner-slide {
    position: unset;
    width: 75%;
    height: 120px;
    display: flex;
    border-radius: 10px;
    border: solid 2px #A7AEBB;
    background-color: white;
}

.carousel .topDots button {
    width: 100px;
    height: 100px;
}

/* .carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__slide:first-of-type {background: #78bbed;}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__slide:nth-of-type(2) {background: #62abe2;}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__slide:nth-of-type(3) {background: #4d9cd7;}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__slide:nth-of-type(4) {background: #3d8bc5;}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__slide:nth-of-type(5) {background: #2b78b2;}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__slide:nth-of-type(6) {background: #20669a;}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__slide:nth-of-type(7) {background: #165381;}
.carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__slide:nth-of-type(8) {background: #0e3f64;} */

.sliderContainerScroller {
    position: relative;
    width: 100%;
}
.sliderContainerScroller .carousel__slider {
    overflow: unset;
    height: 120px;
}
button.carousel__back-button {
    left: calc(((100vw - 1040px)/2) - 100px);
}
button.carousel__next-button {
    right: calc(((100vw - 1040px)/2) - 100px);
}
.topControlsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
}
.topControlsContainer .topControls {
    width: 1040px;
    display: flex;
    justify-content: space-between;
    gap: 0;
}
.topControlsContainer .topControls button {
    width: 20%;
    padding: 0 30px;
    background: none;
    border: 0;
}
.topDotContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}
.topControlsContainer .topControls button:not(:last-child) {
    border-right: 1px solid #ccc;
}

.CarouselImg img {
    width: 400px;
}
ul.CarouselDetailsList li::marker {
    margin-top: 2px;
}
ul.CarouselDetailsList li {
    margin: 5px 0 10px 0;
    padding-left: 10px;
    font-family: var(--interR);
    font-size: 16px;
}
ul.CarouselDetailsList li:empty {
    display: none;
}
.sampleDetails {
    padding: 20px 20px 20px 70px;
    width: 70%;
}
.sampleDetailsButton{
    width: 30%;
    margin: auto;
    text-align: center;
}
.readMoreBtn {
    height: 52px!important;
    width: 188px!important;
    background-color: #003da5!important;
    border-radius: 28px;
    font-family: Inter-Medium !important;
    font-size: 14px!important;
    font-weight: 500!important;
    letter-spacing: 0!important;
    color: #ffffff!important;
    line-height: 20px!important;
    padding: 0;
    border: none;
}
.sampleDetails .sampleDetailsTitle{
    margin: 0;
    font-family: var(--stagM);
    font-size: 20px;
    letter-spacing: 1px;
    padding-bottom: 10px;
}
.sampleDetails .sampleDetailsDescription ul {
    padding-left: 20px;
    line-height: 23px;
}
.sampleDetailsDescription {
    font-size: 14px;
}
.SliderButtonContainer .carousel__dot-group button {
    padding: 0;
    width: 10px;
    height: 10px;
    border-radius: 20px;
    border: 0;
    background: lightgrey;
}
.SliderButtonContainer .carousel__dot-group button.carousel__dot--selected {
    background: grey;
}
.topControlsTitle {
    font-size: 14px;
    font-weight: bold;
    font-family: var(--interR);
    line-height: 18px;
}
.topControls .carousel__dot--selected .topControlsTitle {
    color: #003DA5;
}
.carousel__dot--selected .topDotContainer svg > g > g {
    fill: #003DA5;
}

@media (max-width: 1024px) {
    /* Carousel mobile styles */
    .HKG-template .carousel .topControls {
        flex-direction: column;
        align-items: center;
        padding: 0 50px;
    }
    .HKG-template .carousel .topControls button.carousel__dot {
        border-right: 0;
        width: 100%;
        padding: 0;
    }
    .HKG-template .carousel .topControls button.carousel__dot .topDotContainer {
        flex-direction: row;
        margin: 6px 0;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        justify-content: flex-start;
    }
    .HKG-template .carousel .topControls button.carousel__dot:last-child .topDotContainer {
        border-bottom: 0;
    }
    .HKG-template .carousel .topControls button .topDotContainer svg {
        width: 24px;
        height: 24px;
    }
    .carousel .carousel__slider-tray-wrapper .carousel__slider-tray .carousel__inner-slide {
        width: 90% !important;
        height: auto;
        flex-direction: column;
    }
    .HKG-template .CarouselImg img {
        width: 100%;
    }
    .carousel .carousel__slider {
        height: 240px;
    }
    .SliderButtonContainer {
        height: 20px;
        padding-bottom: 5px;
    }
    .sampleDetailsDescription {
        min-height: 114px;
    }
    .sampleDetails {
        width: auto;
        padding: 10px;
    }
    .sampleDetailsButton {
        padding: 10px;
        margin: 0;
    }
    .HKG-template .sampleDetails .CarouselDetailsList {
        margin: 0;
    }
    .HKG-template button.carousel__next-button {
        right: 25%;
        top: 410px;
    }
    .HKG-template button.carousel__back-button {
        left: 25%;
        top: 410px;
    }
    .topControlsContainer {
        margin-bottom: 10px;
    }
    .readMoreBtn {
        height: 40px !important;
        width: 160px !important;
    }
}
