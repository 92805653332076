.info-card {
    min-height: 78px;
    background-color: #FFFFFF;
    box-shadow: 1px 1px 4px 0 rgba(20, 40, 75, 0.12);
    flex-grow: 1;
    background-color: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    padding: 15px;
    padding-left: 35px;
    box-sizing: border-box;
    border-bottom: 5px solid #003da5;
}
.HKG-template .info-card {
    flex-grow: 0;
}
.InfoImg{
    width: 20px;
    margin-left: -15px!important;
    margin-right: 15px;
    left: 22px;
    top: 2px;
}
.HKG-template .InfoImg{
    width: 20px;
    margin-left: 0px!important;
    margin-right: 5px;
    left: 15px;
    top: 3px;
}
.HKG-template .info-row-nw.InfoContent.price{
    margin-right: 18px;
    color: #0A1F44;
    font-family: Inter-Medium, sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    padding-top: 3px;
}
/**{*/
/*    outline: 1px solid green;*/
/*}*/
/*.info-card:after{*/
/*    content:'';*/
/*    min-height:inherit;*/
/*    font-size:0;*/
/*}*/
@media (max-width: 1350px) {
    .info-card {
        flex-direction: column;
        align-items: stretch;
        width: 100%;
    }
}

.info-heading {
    color: #009AE4;
    font-family: Inter-Bold, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    margin-right: 20px;
}
@media (max-width: 1350px) {
    .info-heading {
        margin-bottom: 4px;
    }
}
.info-row,
.infoVLine{

}
.infoVLine {
    width: 1px;
    height: 26px;
    border-left: 1px solid #4EC2F6;
    flex-grow: 0;
    margin: auto 0;
    margin-right: 27px;
}

.info-row {
    min-height: 43px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;

}
@media (max-width: 1350px) {
    .info-row {
        flex-direction: column;
        align-items: stretch;
    }
    .infoVLine{
        border: none;

    }
}
@media (max-width: 1024px) {
    .info-row {
        align-items: stretch;
    }
}

.info-row-nw {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    color: #0A1F44;
    font-family: Inter-Medium, sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
}
.info-row-nw.price1{
    color: #0A1F44;
    font-family: Inter-Bold, sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
}
.MobileSmallPriceSummary{
    display: none;
}
@media (max-width: 1024px) {
    .MobileInfoContainer, .MobileStepperContainer {
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
     .MobileSmallPriceSummary{
        display: flex;
        width: 100vw;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-left: 0;
        padding-left: 20px;
        padding-right: 40px;
        height: 51px;
        box-sizing: border-box;
        color: #fff;
        background-color: #009AE4;
        font-family: Inter-SemiBold, sans-serif;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 21px;
        position: sticky;
        top: 110px;
         z-index: 3;
    }
    .MobilePriceAmount{
        font-family: Inter-Bold, sans-serif;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 28px;
    }
}

