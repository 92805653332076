.Apply {
    padding-top: 39px;
    padding-bottom: 50px;
}

.Apply .Heading {
    padding-top: 24px;
    padding-left: 36px;
    padding-bottom: 16px;
    color: #FFFFFF;
    font-family: Arial, Noto, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
}

.Apply .Heading.Primary {
    background-color: #282B3E;
}

.Apply .Heading.Secondary {
    background-color: #00A758;
}

.Apply .RawBody {
    padding-left: 23px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.Apply .WhiteBody {
    box-sizing: border-box;
    border: 1px solid #EDEDED;
    background-color: #FFFFFF;
    padding-left: 35px;
    padding-top: 45px;
    padding-bottom: 36px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

}
.Apply .WhiteBody.WhiteBodySpacing{
    margin-bottom: 53px;
}
.Apply .WhiteBody .MiniInputGroup{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
}
.Apply .WhiteBody .MiniInputGroup .Content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /*flex-grow: 1;*/
}
.Apply .WhiteBody .MiniInputGroup .Error{
    min-height: 19px;
    margin-top: 3px;
}
.Apply .WhiteBody .MiniInputGroup .Error .Mui-error{
    margin-top: 0;
}
.Apply .WhiteBody .MiniInputGroup .CircleTick {
    margin-right: 20px;
    cursor: pointer;
}
.Apply .WhiteBody .MiniInputGroup .CircleTick .Border{
    stroke:  #8E90A2;
    opacity: 0.3;
}
.Apply .WhiteBody .MiniInputGroup .CircleTick .Tick{
    fill:  transparent;
}
.Apply .WhiteBody .MiniInputGroup .CircleTick.Picked {
    margin-right: 20px;
}
.Apply .WhiteBody .MiniInputGroup .CircleTick.Picked .Border{
    stroke:  #00A758;
    opacity: 1;
}
.Apply .WhiteBody .MiniInputGroup .CircleTick.Picked .Tick{
    fill:  #00A758;
}
.Apply .MuiIconButton-root:hover {
    background-color: transparent;
}

.Apply .WhiteBody .InputGroup {
    height: 88px;
    margin-right: 85px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}
.Apply .WhiteBody .InputGroup.EmailRemark .EmailAlertIcon{
    height: 27px;
    margin-right: 11px;
}
.Apply .WhiteBody .InputGroup.EmailRemark{
    height: 35px;
    margin-top: -3px;
    margin-right: 85px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
}
.Apply .WhiteCard .ApplicantInfoGroup .TextField input::placeholder{
    opacity: 0.3!important;
    color: #8E90A2!important;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
}

.Apply .WhiteBody .InputGroup .Row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.Apply .WhiteBody .InputGroup .Row .HKIDSelect {
    width: 140px;
    margin-right: 2px;
}
.Apply .WhiteBody .InputGroup .Row .HKIDText {
    width: 325px;
}

.Apply .WhiteBody .InputGroup .Row .Prefix {
    width: 82px;
    margin-right: 6px;
}
.Apply .WhiteBody .InputGroup .Row .Number {
    width: 311px;
}

.Apply .WhiteBody .InputGroup .label {
    margin-right: 85px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #5E6073;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
}
.Apply .Mui-error ~ .Apply .WhiteBody .InputGroup .label {
    color: #C14A36!important;
}

.Apply .InputGroup {
    width: 399px;
    height: 67px;
}

.Apply .ApplicantInfoGroup.CapitalizedInput input {
    text-transform:uppercase;
}

.Apply .ApplicantInfoGroup.CapitalizedInput .NoCap input {
    text-transform:none;
}

.Apply .InsurancePeriod {
    height: 157px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 1px 1px 4px 0 rgba(20,40,75,0.12);
}

.Apply .EndDate {
    margin-left: 33px;
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
}
.Apply .EndDate .Label{
    color: #282B3E;
}
.Apply .EndDate span {
    color: #00A758;
}

.Apply .ApplicantRadioGroup {
    height: 111px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Apply .ApplicantRadioGroup .Option {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Apply .ApplicantRadioGroup .Option .Img {
    margin-right: 20px;
    height: 30px;
    width: 26px;
}
.Apply .ApplicantRadioGroup .Option .Radio {
    margin-right: 20px;
    height: 45px;
    width: 45px;
    cursor: pointer;
}
.Apply .ApplicantRadioGroup .Option .Radio .Border{
    opacity: 0.3;
    stroke: #8E90A2;
}
.Apply .ApplicantRadioGroup .Option .Radio {
    fill: transparent;
}
.Apply .ApplicantRadioGroup .Option .Radio.Picked .Border{
    opacity: 1;
    stroke: #00A758;
}
.Apply .ApplicantRadioGroup .Option .Radio.Picked .Center{
    fill: #00A758;
}
.Apply .ApplicantRadioGroup  .VLine {
    box-sizing: border-box;
    height: 43px;
    width: 2px;
    border: 1px dashed #8E90A2;
    margin-left: 20px;
    margin-right: 18px;
}

.Apply .ApplicantRadioGroup .Option .Content .JobRole {
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: bold;
}

.Apply .ApplicantRadioGroup .Option .Content .AgeGroup {
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: normal;
}


.Apply .InsuredPerson {
    height: 105px;
}

.Apply .InsuredPerson .Children {
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
}

.Apply .InsuredPerson .Img {

}

.Apply .InsuredPerson .Img.Individual {
    height: 30px;
    width: 26px;
    margin-right: 19px;
}

.Apply .InsuredPerson .Img.Children {
    height: 30px;
    width: 30px;
    margin-right: 15px;
}

.Apply .InsuredPerson .AgeGroup {
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
}

.Apply .InsuredPerson .JobRole {
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
}


.Apply .BottomControlGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 23.58px;
}

.Apply .BottomControlGroup .ImportantNotesControl {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
}

.Apply .BottomControlGroup .ImportantNotesControl .Img {
    margin-right: 16px;
}

.Apply .BottomControlGroup .ProgressControl .space {
    width: 28px;
}

.Apply .BottomControlGroup .ProgressControl {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Apply .ImportantNotesBody{
    margin-top: 10px;
    margin-bottom: 15px;
    color: #000000;
    font-family: Inter, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
}
.Apply .ImportantNotesBody ul{
    list-style: none;
    padding-left: 0;
}

.PlanSummaryInfoGroup > .RowGroup .Key.MobileOneRow .keyTitle{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.PlanSummaryInfoGroup > .RowGroup .Key.MobileOneRow  .MobileAmount{
    display: none;
}
.ApplicantInfoContainer{
    margin-top: 34px;
    margin-bottom: 23.5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-left: 65.5px;
    padding-right: 102.5px;
    padding-top: 26.5px;
    padding-bottom: 22px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 1px 1px 4px 0 rgba(20,40,75,0.12);
}
.ApplicantTypeGroup{
    height: 177px;
    width: 266px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex-grow: 1;

}

.ApplicantInfoGroup{
    margin: 0px 43.5px;
}
.ApplicantInfoContainer .ApplicantTypeGroup .Individual{
    height: 51px;
    width: 26px;
}
.ApplicantInfoContainer .ApplicantTypeGroup .Property{
    min-height: 58px;
    min-width: 47px;
}

.ApplicantInfoContainer .ApplicantTypeGroup .Child{
    height: 42px;
    width: 22px;
}
.ApplicantInfoContainer  .MiniInputGroup{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    margin-right: auto;
    margin-left: 20px;
    margin-top: 28px;
}
.ApplicantInfoContainer  .MiniInputGroup .Content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /*flex-grow: 1;*/
}
.ApplicantInfoContainer  .MiniInputGroup .Error{
    min-height: 19px;
    margin-top: 3px;
}
.ApplicantInfoContainer  .MiniInputGroup .Error .Mui-error{
    margin-top: 0;
}
.ApplicantInfoContainer  .MiniInputGroup .CircleTick {
    margin-right: 20px;
    cursor: pointer;
}
.ApplicantInfoContainer  .MiniInputGroup .CircleTick .Border{
    stroke:  #8E90A2;
    opacity: 0.3;
}
.ApplicantInfoContainer  .MiniInputGroup .CircleTick .Tick{
    fill:  transparent;
}
.ApplicantInfoContainer  .MiniInputGroup .CircleTick.Picked {
    margin-right: 20px;
}
.ApplicantInfoContainer  .MiniInputGroup .CircleTick.Picked .Border{
    stroke:  #702F8A;
    opacity: 1;
}
.ApplicantInfoContainer .MiniInputGroup .CircleTick.Picked .Tick{
    fill:  #702F8A;
}
.ApplicantInfoContainer .ApplicantTypeGroup .Content .AgeGroup{
    color: #0A1F44;
    font-family: Inter-Bold, sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
}
.ApplicantInfoContainer .ApplicantTypeGroup .Content .JobRole{
    color: #0A1F44;
    font-family: Inter, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
}
.ApplicantInfoContainer .ApplicantTypeGroup .Content{
    margin-left: 20px;
    flex-grow: 1;
    max-width: 275px;
}
.ApplicantInputFields{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: 800px;
    flex-shrink: 1;
    /*width: 100%;*/
}

.ApplicantInputField{
    /*width: 100% !important;*/
    width: 346px;
    min-height: 56px;
    min-width: 275px;
    margin:20px 20px;
    position: relative;
    box-sizing:border-box;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center
    /*align-items: center;*/
    /*justify-content: center;*/
}

.ApplicantInputField.Full {
    width: 100%;
}

.ApplicantInputField .MuiFormHelperText-root {

     padding: 0;

}
.ApplicantSelect {
    width: 346px;
    height: 56px;
    min-width: 275px;
    margin:20px 20px;
    box-sizing:border-box;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center
}
.ApplicantInputField .Options{
    width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.ApplicantInputField.Options .Option{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 52px;
    outline: 1px solid #EDEDED;
    border: 2px solid transparent;
    color: #000000;
    font-family: Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    cursor: pointer;
}
.ApplicantInputField .RowItem {
    margin-top: 3px;
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    width: 150px;
}

.ApplicantInputField .RowItem .Key {
    margin-top: 3px;
    color: #282B3E;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
}

.ApplicantInputField .RowItem .Value {
    margin-top: 3px;
    color: #282B3E;
    font-family: Inter, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    flex-grow: 1;
}

.ApplicantInputField>span{
    width: 100%;

}
.ApplicantInputField>span2{
    width: 100%;
    font-size: 12px;
    font-family: Inter, sans-serif;
    margin-top: 9px;

}
.ApplicantInputField>span3{
    width: 100%;
    font-size: 16px;
    font-family: Inter, sans-serif;
    font-weight: bold;
    color: #0A1F44;
    margin-top: 9px;

}
.HalfField{
    width: 50%;
    margin-right: 5px;
}
.SmallField,
.PassportSelect{
    width: 60%;
    margin-right: 5px;
}
.BigField,
.PassportText{
    width: 65%;
}
.Smile{
    margin-top: 7px;
}
@media (max-width: 1440px) {
    .Apply .InputGroup {
        min-width: 370px;
        max-width: 375px;
        flex-grow: 1;
    }
    .Apply .WhiteBody .InputGroup .Row .Prefix {
        width: 82px;
        margin-right: 6px;
    }
    .Apply .WhiteBody .InputGroup .Row .Number {
        min-width: 288px;
        flex-grow: 1;
    }
    .Apply .WhiteBody .InputGroup .Row .HKIDText {
        min-width: 250px;
        flex-grow: 1;
    }
}
.MobileBr{
    display: none;
}
@media (max-width: 1136px) {
    .ApplicantInputField{
        /*width: 100% !important;*/
        width: 100%;
    }
}
@media (max-width: 1024px) {
    .HousingTypeContainer{
        margin-top: 15px!important;
        margin-bottom: 0px!important;
    }
    .EmailReminder{
        margin-top: -5px!important;
    }
    .CountryCode label{
        width: 110px;
    }
    .MobileBr{
        display: block;
        height: 8px;
        width: 100%;
    }
    .HalfField{
        width: 50%;
        margin-right: 5px;
    }
    .SmallField,
    .PassportSelect{
        width: 60%;
        margin-right: 5px;
    }
    .BigField,
    .PassportText{
        width: 64%;
    }
    .ApplicantInfoContainer{
        margin-top: 34px;
        margin-bottom: 23.5px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        padding-left: 0px!important;
        padding-right: 0px!important;
        padding-top: 26.5px;
        padding-bottom: 22px;
    }
    .ApplicantInputFields{
        width: 100% !important;
        max-width: 100%;
    }
    .ApplicantTypeGroup{
        width: 100%!important;
        height: auto!important;
        padding-left: 27.5px;
        justify-content: flex-start!important;
        flex-grow: 0;
        box-sizing: border-box;
        flex-shrink: 0;

    }
    .ApplicantInfoGroup{
        margin: 0!important;
        width: 100%!important;
    }
    .ApplicantInputField{
        width: 100% !important;
        height: 56px;
        min-width: 275px;
        margin:20px 0px;
        box-sizing:border-box;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: center
        /*align-items: center;*/
        /*justify-content: center;*/

    }
    .Apply  {
        width: calc(100vw - 40px);
        padding: 0 0px;
        overflow: hidden;
    }
    .Apply .Heading {
        padding-top: 15px;
        padding-left: 14px;
        padding-bottom: 16px;
        width: auto;
    }
    .Apply .Heading.Primary {
        margin-top: 15px;
    }
    .Apply .RawBody {
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .Apply .InsurancePeriod {
        height: auto;
        padding-top: 36px;
        padding-bottom: 29px;
        align-items: stretch;
    }
    .Apply .EndDate {
        margin-left: 22px;
        margin-top: 18px;
        line-height: 21px;
    }
    .Apply .InputGroup {
        padding: 0;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        padding-left: 22px;
        padding-right: 21px;
        min-width: unset;
        max-width: unset;
        flex-grow: 1;
    }
    .Apply .WhiteBody {
        min-height: 88px;
        box-sizing: border-box;
        padding-left: 0px;
        padding-top: 38px;
        padding-bottom: 36px;
    }
    .Apply .ApplicantRadioGroup  {
        padding-top: 29px;
        height: auto;
        margin-bottom: 23px;
    }
    .Apply .ApplicantRadioGroup .Option .Radio {
        margin-right: 13px;
        height: 30px;
        width: 30px;
        overflow: visible;
        cursor: pointer;
    }
    .Apply .ApplicantRadioGroup .Option .Img {
        margin-right: 7px;
    }
    .Apply .ApplicantRadioGroup .Option .Content {
        flex-grow: 2;
    }
    .Apply .ApplicantRadioGroup .Option {
        display: flex;
        flex-direction: row;
        align-items: center;

    }
    .Apply .InsuredPerson {
        height: auto;
        flex-direction: row;
        padding-top: 30px;
        padding-bottom: 23px;
        align-items: center;
    }
    .Apply .WhiteBody .InputGroup {
        height: auto;
        margin-right: 0;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-end;
        margin-bottom: 25px;
    }
    .Apply .WhiteBody .InputGroup.MobileHide{
        display: none;
    }
    .Apply .WhiteBody .InputGroup .Row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

    }
    .Apply .WhiteBody .InputGroup .Row .Prefix {
        min-width: 82px;
        margin-right: 6px;
    }
    .Apply .WhiteBody .InputGroup .Row .Number {
        min-width: unset;
        flex-grow: 1;
    }
    .Apply .WhiteBody .InputGroup .Row .HKIDSelect {
        min-width: 100px;
    }
    .Apply .WhiteBody .InputGroup .Row .HKIDText {
        min-width: unset;
        flex-grow: 1;
    }

    .Apply .BottomControlGroup .ProgressControl {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        z-index: 2;
        display: flex;
        flex-direction: row;
        justify-content: center!important;
    }
    .Apply .BottomControlGroup .ProgressControl div{
        flex-grow: 2;
    }
    .Apply .BottomControlGroup .ProgressControl .space{
        display: none;
    }
    .PlanSummaryInfoGroup > .RowGroup .Key.MobileOneRow .MobileAmount{
        display: inline-block;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 18px;
        font-weight: normal!important;
    }
    .PlanSummaryInfoGroup > .RowGroup .Key.MobileOneRow ~ .Amount{
        display: none;
    }
    .Apply .BottomControlGroup .ImportantNotesControl {
        margin-bottom: 85px;
        box-sizing: border-box;
        padding-top: 17px;
        padding-bottom: 19px;
        border-top: 2px solid #EDEDED;
        border-bottom: 2px solid #EDEDED;
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
    }
    .Apply .BottomControlGroup .ImportantNotesControl.noBottomPadding {
        margin-bottom: 0!important;
    }
    .Apply .ApplicantRadioGroup .VLine {
        box-sizing: border-box;
        height: 2px;
        width: 100%;
        border: 1px dashed #8E90A2;
        margin-left: 0;
        margin-right: 0;
        margin-top: 20px;
        margin-bottom: 27px;
    }
    .Apply .WhiteBody .MiniInputGroup .CircleTick {
        margin-right: 20px;
        cursor: pointer;
        width: 30px;
        height: 30px;
        margin-left: 23px;
    }
    .Apply .WhiteBody .MiniInputGroup .Error {
        margin-left: 23px;
    }
    .mobileBack{
        display: none;
    }
    .Apply .BottomControlGroup{
        height: 76px;
        background-color: #FFF;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        z-index: 2;
    }
    .QuoteApplyButton .space{
        display: none;
    }
    .Apply .BottomControlGroup .ProgressControl {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 76px;

    }
    .PolicyEffectiveInputHints1{
        margin-bottom: 18px;
    }
    .ApplicantTypeGroup .Individual{
        height: 40px!important;
        width: 20px!important;
    }
    .ApplicantTypeGroup .Property{
        height: 58px!important;
        width: 47px!important;
    }
    .ApplicantTypeGroup .AgeGroup{
        font-family: Inter-Bold, sans-serif;
        font-size: 14px!important;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
    }
    .ApplicantTypeGroup .JobRole{
        font-family: Inter, sans-serif;
        font-size: 14px!important;
        letter-spacing: 0;
        line-height: 24px;
    }
    .ApplicantTypeGroup svg{
        flex-shrink: 1;
    }
}
.MobilePayAmount{
    display: none;
}
@media (max-width: 1024px) {
    .PaymentHeadingIcon{
        height: 47px;
        width: 50px;
        flex-shrink: 0;
    }
    .ChatIcon{
        height: 47px;
        width: 55px;
        flex-shrink: 0;
    }
    .MobilePayAmount{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding-left: 24px;
        padding-right: 24px;
        background-color: #009AE4;
        color: #FFF;
        position: sticky;
        top:111px;
        padding-top: 12px;
        padding-bottom: 12px;
        z-index: 3;
    }
    .MobilePayAmount .Label{
        font-family: Inter-SemiBold, sans-serif;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 21px;
    }
    .MobilePayAmount .Amount{
        font-family: Inter-Bold, sans-serif;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 28px;
    }
}
.ApplicantInputFields .Item {
    color: #000000;
    font-family: Inter, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 35px;
    margin-bottom: 14px;
    position: relative;
    padding-left: 25px;
}

.ApplicantInputFields input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.ApplicantInputFields .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 3px solid #eee;
    background-color: transparent;
}

/* On mouse-over, add a grey background color */
.ApplicantInputFields .container:hover input[type=checkbox] ~ .checkmark {

}

/* When the checkbox is checked, add a blue background */
.ApplicantInputFields .container input[type=checkbox]:checked ~ .checkmark {
    border-color: #702F8A;
    color: #702F8A;
}

/* Create the checkmark/indicator (hidden when not checked) */
.ApplicantInputFields .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.ApplicantInputFields .container input[type=checkbox]:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.ApplicantInputFields .container .checkmark:after {
    left: 7px;
    top: 2px;
    width: 7px;
    height: 13px;
    border: solid #702F8A;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    color: #702F8A;
}

.ApplicantInputFields .container {
    position: relative;
    height: 35px;
    /*line-height: 45px;*/
    padding-left: 50px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
}


.ApplicantInputFields .container .Wording{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 35px;
    margin-top: 3px;
    font-size: 14px;
}

.ApplicantInputField.MobileOnly,
.HKG-template .ApplicantInputField.MobileOnly{
    display: none;
}
@media (max-width: 1024px) {
    .HKG-template .ApplicantInputField.TelField.MobileOnly{
        display: inline-flex;
    }
    .HKG-template .ApplicantInputField.EmailReminder {
        margin-bottom: -38px;
    }
    .HKG-template .ApplicantInputFields .Item.Checkbox {
        padding-left: 0px;
    }
    .ApplicantInputFields .ApplicantInputField.DesktopOnly{
        display: none!important;
    }
    .ApplicantInputField.MobileOnly{
        display: inline-flex;
    }

    .ApplicantInputField.EmailReminder{
        margin-bottom: -20px;
    }
    #Template:not(.HKG-template) .ApplicantInputField.EmailReminder{
        margin-bottom: -38px;
    }
    .Apply .MobileHideField{
        display: none;
    }
}

@media (min-width: 1024.0001px) {
    .Applicant .ApplicantInfoContainer{
        padding-top: 42.5px;
    }
}
@media (max-width: 600px) {
    .PolicyEffectiveInputHints1{
        padding: 0 20px;
    }
}
.MobileNumber{
    width: 80%;
}
