body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*@font-face {*/
/*  font-family: 'Stag-Medium';*/
/*  src: url(./assets/fonts/Stag-Medium.otf) format('opentype');*/
/*}*/
@font-face {
  font-family: 'Stag-Medium';
  src: url(./assets/fonts/Stag-Medium-Web.woff) format('woff');
}
/*@font-face {*/
/*  font-family: 'Stag';*/
/*  src: url(./assets/fonts/Stag-Medium.otf) format('opentype');*/
/*}*/
@font-face {
  font-family: 'Stag';
  src: url(./assets/fonts/Stag-Medium-Web.woff) format('woff');
}

@font-face {
  font-family: "Inter-Medium";
  src: url('./assets/fonts/Inter-Medium.woff') format("woff");
}
@font-face {
  font-family: "Inter";
  src: url('./assets/fonts/Inter-Regular.woff') format("woff");
}
@font-face {
  font-family: "Inter-Regular";
  src: url('./assets/fonts/Inter-Regular.woff') format("woff");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url('./assets/fonts/Inter-SemiBold.woff') format("woff");
}

@font-face {
  font-family: "Inter-Bold";
  src: url('./assets/fonts/Inter-Bold.woff') format("woff");
}
@font-face {
  font-family: "Noto-Thin";
  src: url('./assets/fonts/noto-sans-tc-Thin.woff') format("woff");
}
@font-face {
  font-family: "Noto-Light";
  src: url('./assets/fonts/noto-sans-tc-Light.woff') format("woff");
}
@font-face {
  font-family: "Noto-Regular";
  src: url('./assets/fonts/noto-sans-tc-Regular.woff') format("woff");
}
@font-face {
  font-family: "Noto";
  src: url('./assets/fonts/noto-sans-tc-Regular.woff') format("woff");
}
@font-face {
  font-family: "Noto-Medium";
  src: url('./assets/fonts/noto-sans-tc-Medium.woff') format("woff");
}
@font-face {
  font-family: "Noto-Bold";
  src: url('./assets/fonts/noto-sans-tc-Bold.woff') format("woff");
}
@font-face {
  font-family: "Noto-Black";
  src: url('./assets/fonts/noto-sans-tc-Black.woff') format("woff");
}

#splash-screen{
  position: fixed;
  height: 160px;
  width: 450px;
  overflow: hidden;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
#logo-box{
  position: absolute;
  height: 100px;
  width: 250px;
  overflow: hidden;
  margin: auto 0 auto auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#gears-box{
  height: 160px;
  width: 160px;
  overflow: hidden;
}

.Caps input {
  text-transform: uppercase;
}
.MuiSelect-select:focus{
  /*background-color: #FFFFFF!important;*/
}
.MuiListItem-root.Mui-disabled{
  display: none!important;
}

/*.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {*/
/*   background-color: #00A758!important;*/
/*  color: #FFFFFF!important;*/
/*}*/
body{
  overflow-x: hidden;

}

.PopupErrorContent {

}

.PopupErrorButtonGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 23px;
}

.PopupErrorButtonGroup .space {
  width: 28px;
}

.HugeH,.BigH,.LargeH,.MediumH,.NormalH,.SmallH{
  letter-spacing: 0.5px;
}
.primary{
  color: #003DA5;
}
.secondary{
  color: #009AE4;
}
.tertiary90{
  color: #0A1F44;
}
.black{
  color: black;
}
.KeyHeading{
  font-family: Inter-Bold, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
  margin-right: 18px;
  color: #009AE4;
  margin-right: 18px;
}

.StagM{
  font-family: Stag-Medium, sans-serif;
}

.HugeH{
  font-size: 56px;
  line-height: 64px;
  font-family: Stag-Medium, sans-serif;
}
.BigH{
  font-size: 40px;
  line-height: 48px;
  font-family: Stag-Medium, sans-serif;
}
.LargeH{
  font-size: 32px;
  line-height: 40px;
  font-family: Stag-Medium, sans-serif;
}
.MediumH{
  font-size: 24px;
  line-height: 36px;
  font-family: Stag-Medium, sans-serif;
}
.NormalH{
  font-size: 20px;
  line-height: 28px;
  font-family: Stag-Medium, sans-serif;
}
.SmallH{
  font-size: 18px;
  line-height: 26px;
  font-family: Stag-Medium, sans-serif;
}
.MarginRight64{
  margin-right: 64px;
}
.MarginRightNve33{
  margin-right: -33%;
}
.marginTB0{
  margin-top: 5px;
  margin-bottom: 5px
}
.wordBreak{
  word-break: break-all;
  max-width: 42%;
}
@media (max-width: 768px) {
  .HugeH{
    font-size: 40px;
    line-height: 48px;
  }
  .BigH{
    font-size: 32px;
    line-height: 40px;
  }
  .LargeH{
    font-size: 24px;
    line-height: 32px;
  }
  .MediumH{
    font-size: 20px;
    line-height: 28px;
  }
  .NormalH{
    font-size: 18px;
    line-height: 26px;
  }
  .SmallH{
    font-size: 18px;
    line-height: 26px;
  }
}
.center{
  text-align: center;
}
.InterB{
  font-family: Inter-Bold, sans-serif;
}
.InterM{
  font-family: Inter-Medium, sans-serif;
}
.InterSB{
  font-family: Inter-SemiBold, sans-serif;
}

.NormalSH{
  font-size: 20px;
  line-height: 28px;
}

.SmallSH{
  font-size: 18px;
  line-height: 25px;
}



.BigP{
  font-size: 18px;
  line-height: 25px;
}
.NormalP{
  font-size: 16px;
  line-height: 22px;
}
.SmallP{
  font-size: 14px;
  line-height: 20px;
}
.XSmallP{
  font-size: 12px;
  line-height: 18px;
}

.NormalP1{
  font-size: 14px;
  line-height: 20px;
}
.SmallP1{
  font-size: 14px;
  line-height: 20px;
}
.XSmallP1{
  font-size: 12px;
  line-height: 28px;
}
.PageHeading{
  margin-top: 34px;
  margin-bottom: 10.5px;
}
.PageHeading span{
  padding-left: 16px;
}
@media (max-width: 1024px) {
  .PageHeading svg{
    padding-left: 15px;
  }
  .PageHeading{
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 28px;
  }
}
.Row{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Col{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.TCard{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.WhiteCard.Col,
.TCard.Col{
  flex-direction: column;
}
.TCard.Col.start{
  align-items: flex-start;
}
.TCard.start{
  justify-content: flex-start;
}
.HLine{
  width: 100%;
  box-sizing: border-box;
  border-bottom: 2px solid #E1E4E8;
}
.HLine.blue{
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #4EC2F6;
  margin-top: 53px;
  margin-bottom: 55px;
}
.VLine{
  height: 26px;
  width: 1px;
  margin-left: 3%;
  margin-right: 3%;
  box-sizing: border-box;
  border-left: 1px solid #4EC2F6;
}
.WhiteCard{
  padding-top: 48.5px;
  padding-bottom: 47.5px;
  box-sizing: border-box;
  border: 1px solid #E1E4E8;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}
.WhiteCard.BlueBorder{
  border-bottom: 5px solid #003DA5;
}
.InfoCard{
  min-height: 76px;
  width: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  border-bottom: 5px solid #003DA5;
  box-shadow: 1px 1px 4px 0 rgba(20,40,75,0.12);
  background-color: #FFF;
  padding: 30px 33px;
}

.InfoCard .ItemRow{
  display: flex;
  flex-direction: row;
  align-items: center;

}
.link{
  cursor: pointer;
  color: #009AE4!important;
}
.full{
  width: 100%;
}
.InfoBanner{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  background: linear-gradient(90deg, #003DA5 0%, #00A8F3 100%);
  color: #FFF;
  box-sizing: border-box;
}
.FlexGrow{
  flex-grow: 1;
}
.ErrorMessage {
  color: #A50000;
  font-size: 12px;
  font-weight: 400;
}
