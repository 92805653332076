.balloontips{
    display: inline-flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    width: 18px;
    height: 18px;
}

/*@media screen and (max-width: 1024px){*/
.balloontips-backdrop{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    overscroll-behavior: contain;

    /*overflow: hidden;*/
}
/*}*/
.balloontips-icon{
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    line-height: 0;
    z-index: 2;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
}

.balloontips-icon img,
.balloontips-icon svg{
    width: 18px;
    height: 18px;
}
#info-hover-inside{
    fill: #fff;
}
.whiteOutlineInfo{
    width: 18px;
    height: 18px;
}
#info-hover-outside{
    fill: #14284B;
}
.dark #info-hover-inside{
    fill: #14284B;
}
.dark #info-hover-outside{
    fill: #fff;
}
.balloontips-icon:hover{
    position: relative;
}
.balloontips.Opened .balloontips-icon::before{
    content: '';
    position: absolute;
    box-sizing: border-box;
    height: 13px;
    width: 20px;
    top: 18px;
    left: 50%;
    transform: translateX(-50%);
    border-top: 2px solid transparent;
    border-bottom: 13px solid #003DA5;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    z-index: 5;
}
.balloontips-content{
    top: 33px!important;
    width: auto;
    min-width: 325px;
    position: absolute;
    box-sizing: border-box;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
    padding: 18px 20px;
    background-color: #003DA5;
    color: #fff;
    z-index: 5;
    font-family: Inter;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
}
.balloontips-content-mobile{
    display: none
}
@media screen and (max-width: 1024px){
    .balloontips{
        display: inline-flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
    }
    /*.balloontips-tri{*/
    /*    position: absolute;*/
    /*    box-sizing: border-box;*/
    /*    height: 13px;*/
    /*    width: 20px;*/
    /*    top: 20px;*/
    /*    left: 50%;*/
    /*    transform: translateX(-50%);*/
    /*    border-bottom: 13px solid #003DA5;*/
    /*    border-left: 15px solid transparent;*/
    /*    border-right: 15px solid transparent;*/
    /*    z-index: 5;*/
    /*}*/
    .balloontips-content{
        display: none
    }
    .balloontips-content-mobile{
        min-width: 325px;
        position: fixed;
        box-sizing: border-box;
        height: auto;
        padding: 18px 20px;
        background-color: #003DA5;
        color: #fff;
        z-index: 5;
        font-family: Inter;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        display: block;
        top: 33px;
        width: 100vw;
        left: 0;
        width: calc(100vw - 40px);
        margin-left: 20px;
        margin-right: 20px;
    }
}
