.TopTitleGroup{
    padding-top: 86px;
    width: 100%;
    height: 58px;
    background: linear-gradient(90deg, #003DA5 0%, #00A8F3 100%);
    color: #fff;

}
.TopTitle{
    width: 1440px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 45px;
    padding-right: 45px;
    /*padding-left: 63px;*/
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
}
.TopTitle .ProductName{
    margin-left: 5px;
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
}

.StepperInfoContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 76px;
    padding-top: 26px;
}
.TableHeaderGroup,
.QuoteWrapper,
.QuoteHeaderTemplateBodyContainer{
    width: 1440px;
    padding-left: 45px;
    padding-right: 45px;
    box-sizing: border-box;
}
.TableHeaderGroup,
.QuoteTableHeader{
}
.TableHeaderGroup{
    z-index: 3;
}
@media (max-width: 1440px) {
    .QuoteTemplateBodyContainer{
        width: 100%;
    }
    .TableHeaderGroup,
    .QuoteWrapper,
    .QuoteHeaderTemplateBodyContainer{
        width: 100%;
        padding-left: 8px;
        padding-right: 8px;
        box-sizing: border-box;
    }

}
.QuoteGlobalTop {
    padding-top:26px;
}
/*.TitleGroup{*/
/*//background-color: ;*/
/*    background: linear-gradient(90deg, #003DA5 0%, #00A8F3 100%);*/
/*    color: #FFF;*/
/*}*/
/*@media (max-width: 1440px) {*/
/*    .TitleGroup{*/
/*        display: flex;*/
/*        flex-direction: row;*/
/*        align-items: center;*/
/*        justify-content: space-between;*/
/*    }*/

/*}*/
@media (max-width: 1024px) {
    .TableHeaderGroup,
    .QuoteWrapper,
    .QuoteHeaderTemplateBodyContainer{
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }

}
.MobileInfoContainer,
.MobileStepperContainer{
    display: none;
}


@media (max-width: 1024px) {
    .TemplateBodyContainer{
        padding: 0 0px!important;
        width: 100vw;
    }
    .QuoteHeaderTemplateBodyContainer{
        display: none;
    }
    .MobileInfoContainer,
    .MobileStepperContainer{
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        display: flex;
    }
    .MobileStepperContainer{
        padding-top: 0;
        /*position: absolute;*/
        top: 55px;
        height: 56px;
        background-color: #F7F8F9;
        box-shadow: 1px 1px 4px 0 rgba(20,40,75,0.12);
        position: sticky;
        display: flex;
        z-index: 2;
    }
    .MobileInfoContainer{
        padding-top: 20px;
        background-color: #F7F8F9;
    }
    .MobileInfoContainer > div{
        margin-left: 0!important;
        margin-right: 0!important;

    }
    .TopTitleGroup{
        padding-top: 68px;
        padding-bottom: 8px;
        width: 100%;
        height: auto;
        background: linear-gradient(90deg, #003DA5 0%, #00A8F3 100%);
        color: #fff;
    }
    .TopTitle{
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        font-family: Inter, sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
    }
    .TopTitle .ProductName{
        margin-left: 0px;
        font-family: Inter-SemiBold, sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
    }
    .TemplateBodyContainer{
        flex-direction: column;
    }
    .StepperInfoContainer{
        padding-top: 0;
        /*position: absolute;*/
        top: 56px;
        background-color: #F7F8F9;
        box-shadow: 1px 1px 4px 0 rgba(20,40,75,0.12);
        display: none;
    }
    .NormalStepper{
        width: 100%;
        padding: 0!important;
        margin: 0!important;
    }

    .MobileStepperContainer {
        z-index: 3;
    }
}
