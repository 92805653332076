.MainLogo{
    height: 35px;
    width: 122px;
}
.AgentDetail{
    margin-right: 10px !important;
}
.AgentInfoGroupOverride{
    position: relative;
}
.Co-brandSeparator{
    height: 36px;
    width: 1px;
    max-width: 1px;
    background-color: #fff;
    margin-left: 18.69px;
    margin-right: 15.49px;
}
.AegisIcon
{
    height: 52px;
    filter: brightness(0) invert(1);

}

.CCBA-ICON {
    width: 145px;
    margin-top: -5px;
    margin-left: -56px;
    margin-right: -50px;
    filter: drop-shadow(0px 0px 2px #ffffff);
}
.CCBA-Brandname {
    font-weight: 700;
    font-size: 46px;
    margin-right: 12px;
    font-family: 'YU Gothic UI';
    letter-spacing: -2px;
    transform: translateY(-2px);
}
.AgentInfoGroupOverride:not(.AgentInfoBorderless)::after{
    transform: rotate(-135deg) translateY(-60%);
    border-radius: 0 7px 0 0;
    display:inline-block;
    height: 15px;
    width:15px;
    border-top: 1px solid #003DA5;
    border-right: 1px solid #003DA5;
    content: ' ';
    position:absolute;
    background-color: #fff;
    left: -3px;
    top: 37%;
    transition-duration: 0.3s;
}
.AgentInfoGroup{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 11.6px;
    padding-left: 25.41px;
    padding-right: 19.59px;
    padding-bottom: 12.44px;
    text-align: left;
    width: auto;
    min-width: 300px;
    border-radius: 5px;
    margin-right: 10px;
}
.AgentInfoGroup .Item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
#iamlookingfor{
    font-size: 20px;
}

.headerNotSelected.headerSelected {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    margin-left: 30px;
    border-radius: 8px;
    box-sizing: border-box;
    height: 152px;
    width: 15%;
    border: 1px solid #003DA5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}
.headerNotSelected.headerSelected::before {
    transform: rotate(-135deg) translateY(-60%);
    border-radius: 0 7px 0 0;
    display:inline-block;
    height: 15px;
    width:15px;
    border-top: 1px solid #003DA5;
    border-right: 1px solid #003DA5;
    content: ' ';
    position:absolute;
    background-color: #fff;
    left: -3px;
    top: 40%;
    transition-duration: 0.3s;
}

.headerNotSelected {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    margin-left: 30px;
    height: 152px;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid transparent;
    box-sizing: border-box;

}
.marginForTravelwording {
    margin-top: 9px;
    margin-bottom: 31px !important;
}
.marginForDomestichelper{
    margin-top: 10px;
}
.AgentInfoGroup .AgentName{
    color: #282B3E;
    font-family: Inter-Bold, sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 6px;
}
.AgentInfoGroup .AgencyName_SGP{
    margin-top: 4px;
}
.AgentInfoGroup .AgentName_SGP{
    margin-bottom: 0!important;
}
.AgentInfoGroup .AgentNo{
    color: #282B3E;
    font-family: Inter, sans-serif;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 14px;
}
.AgencyName{
    color: #282B3E;
    font-family: Inter, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 5px;
    font-weight: bold;
}
.AgentInfoGroup .AgentVLine{
    box-sizing: border-box;
    height: 46px;
    width: 2px;
    border: 1px solid #0A1F44;
    margin: 0 12.5px;
}
.MobileAgentInfoGroup{
    display: none;
}
.MobileBack{
    display: none;
}
@media (max-width: 1024px) {
    .HeaderLeftGroup{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .AgencyName{
        font-size: 16px;
        font-family: Inter-Bold, sans-serif;
        margin-bottom: 12px;
        height: 100%;line-height: 16px;
    }
    .MobileRightHeader{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto!important;
        justify-content: flex-start!important;
        padding-left: 21.5px;
        padding-right: 21.5px;
        padding-bottom: 50px;
        box-sizing: border-box!important;
        cursor: pointer;
    }
    .MobileRightHeader .MobileLookingTitle{
        width: 100%;
        color: #003DA5;
        height: 52px;
        font-family: Inter-Bold, sans-serif;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 52px;
        text-align: center;
    }
    .LookProduct{
        border-radius: 8px;
        /*color: #4EC2F6;*/
        box-sizing: border-box;
        height: 163px;
        width: 50%;
        border: 1px solid transparent;
        position: relative;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        color: #003DA5;
        font-family: Inter-SemiBold, sans-serif;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
    }
    .LookProduct.Picked{
        border-radius: 8px;
        /*color: #4EC2F6;*/
        box-sizing: border-box;
        height: 163px;
        width: 50%;
        border: 1px solid #4EC2F6;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        color: #003DA5;
        font-family: Inter-SemiBold, sans-serif;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
    }
    .LookProduct span{
        height: 60px;
    }
    .LookProduct::before{
        display: none;
    }
    .LookProduct.Picked::before{
        content: '';
        position: absolute;
        transform: rotate(-45deg) translateY(-50%);
        border-radius: 0 7px 0 0;
        display:inline-block;
        height: 15px;
        width:15px;
        border-top: 1px solid #4EC2F6;
        border-right: 1px solid #4EC2F6;

        background-color: #fff;
        left: 50%;
        top: -3px;
        transition-duration: 0.3s;
    }
    .MobileAgentInfoGroup{
        display: block;
    }
    .MobileBack{
        display: inline-block;
        padding-left: 8px;
        padding-right: 18px;
    }
    .agentboxContainer{
        background-color: transparent!important;
        border-radius: 8px;
        box-sizing: border-box;
        height: auto!important;
        padding: 11.5px 7.5px 8.5px 11.5px;
        width: auto;
        border: 1px solid #4EC2F6;
        position: relative!important;
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: stretch;
        transition-duration: 0.3s;
        margin-right: 0!important;
    }
    .agentboxContainer::after{
        display: none!important;
    }
    .MobileRightGroup{
        position: relative;
    }
    .agentboxContainer::before{
        transform: rotate(-45deg) translateY(-50%);
        border-radius: 0 7px 0 0;
        display:inline-block;
        height: 15px;
        width:15px;
        border-top: 1px solid #4EC2F6;
        border-right: 1px solid #4EC2F6;
        content: ' ';
        position:absolute;
        background-color: #fff;
        /*right: -3px;*/
        top: -3px;
        right: 103px;
        transition-duration: 0.3s;
        z-index: 100;
    }
    .MobileAgentBox.Open{
        background-color: rgba(255,255,255,0.95) !important;
    }
    .AgentInfoGroup{
        display: none;
    }
    .MobileAgentInfoGroup .AgentRow{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 8px;
    }
    .MobileAgentInfoGroup .AgentName{
        font-family: Inter-Bold, sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
        text-align: center;
    }
    .MobileAgentInfoGroup{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .MobileAgentInfoGroup .AgentContactInfo{
        font-family: Inter-SemiBold, sans-serif;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 15px;
    }
    .MobileAgentInfoGroup>div{
        text-align: left!important;
    }
    .MobileAgentInfoGroup .AgentNo{
        color: #282B3E;
        font-family: Inter, sans-serif;
        font-size: 10px;
        letter-spacing: 0;
        line-height: 14px;
    }
}
/*.Header.Transparent:hover,*/
.Header.White .AegisIcon{
    filter: brightness(1) invert(0);
    transition-duration: 0.3s;
}
.Header.White .Co-brandSeparator{
    background-color: #000;
}
.Header.White{
    background-color: white;
    transition-duration: 0.3s;
    color: #000;
}
/*.Header.Transparent:hover .headerBW,*/
.Header.White .headerBW{
    cursor: pointer;
    fill: #000;
    transition-duration: 0.3s;
}
/*.Header.Transparent:hover .sbSvg,*/
.Header.White .sbSvg{
    cursor: pointer;
    fill: #14284B;
    transition-duration: 0.1s;
}
.Header.White .abBlue{
    fill:#32C5FF;
}

.Header.White .AgentDetail{
    transition-duration: 0.3s;
    opacity: 1;
    /*display: none!important;*/
}
/*.Header.Transparent:hover .abSvg,*/
.Header.White .abSvg{
    cursor: pointer;
    fill: #14284B;
    stroke: #14284B;
    stroke-width: 0.3px;
    transition-duration: 0.1s;
}
.Header.Transparent{
    background-color: transparent;
    color: #fff;
    transition-duration: 0.3s;
}
.Header.Transparent .headerBW{
    cursor: pointer;
    fill: #fff;
    transition-duration: 0.3s;
}
.Header.Transparent .abSvg{
    cursor: pointer;
    fill: #fff;
    transition-duration: 0.1s;
    /*stroke: #fff;*/
}
.Header.Transparent .sbSvg{
    cursor: pointer;
    fill: #fff;
    transition-duration: 0.1s;
    /*stroke: #fff;*/
}
.Header.Transparent .AgentDetail{
    transition-duration: 0.3s;
    opacity: 0;
    display: none!important;
}

    #flexForHeader {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 1440px;
        margin: 0 auto;
        position: relative;
        box-sizing: border-box;
        padding-left: 45px;
        padding-right: 45px;
        transition-duration: 0.3s;
    }
@media (max-width: 1440px){
    #flexForHeader{
        padding-right: 24px;
        padding-left: 24px;
    }
}


.wording{
    color: #003DA5;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    margin-bottom: 22px;
}

@media (max-width: 1024px) {
    .MainLogo{
        width: 80px;
        height: 23px;
    }
    .Header.Transparent .abBlue{
        fill:#32C5FF;
    }
    .Header.Transparent .sbBlue{
        fill:#32C5FF;
    }
    .Header.White .SideMenu{
        fill: #14284b;
    }
    .Header.Transparent .SideMenu{
        fill: #fff;
    }
}

.LangControl .LangVLine{
    box-sizing: border-box;
    height: 18.22px;
    width: 1px;
    border: 1px solid #CCCCCC;
    margin: 0 17px;

}
@media(max-width: 1024px){
    .LangControl .LangVLine{
        margin-left: 27px;
    }

}

#languageSelector .Lang{
    color: #FFF;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
}
.Header.White #languageSelector .Lang{
    color: #0A1F44!important;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
}
.LangVLine {
    box-sizing: border-box;
    height: 27px;
    width: 0px;
    border-left: 1px solid #CCCCCC;
}


#languageSelector {
    cursor: pointer;
    display:flex;
    flex-direction: row;
    min-width: 78px;
    justify-content: space-between;
    align-items: center;
    margin-left: 22px;
}


.dropdown-toggle{

    font-family: Inter-SemiBold, sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
}
:lang(zh) .marginForTravelwording{
    margin-bottom: 22px!important;
    margin-top: 0;

}
.MobileLookingLanguage{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.MobileLookingLanguage #languageSelector{
    width: 73px;
    margin-left: 0px;
    margin-top: 50px;
}
.Header.White .MobileLookingLanguage #languageSelector .Lang {
    color: #0A1F44!important;
    font-family: Inter-SemiBold, "PingFang HK" ,Noto, sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
}
.Header.White #languageSelector .Lang.ActiveLang,
.Header.White  #languageSelector .ActiveLang {
    color: #4EC2F6!important;
}
.Header.White .LangVLine {
    box-sizing: border-box;
    height: 27px;
    width: 0px;
    border-left: 2px solid #CCCCCC;
}
.Header .MobileBack{
    display: none;
}
@media (max-width: 1024px) {
    .Header .MobileBack{
        display: inline-block;
        height: 20px;
        width: 12.5px;
    }
}
@media (max-width: 768px) {
    .Kwiksure {
        padding-top: 5px;
    }
    .QBE_aegis{
        width: 70px;
    }
    .AegisIcon
    {
        width: 65px;
        height: auto;
    }
    .Co-brandSeparator{
        height: 28px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media (max-width: 350px) {

    .MobileRightGroup.QBE_Kwiksure_right .Icon.HeaderBtn {
        margin-right: 15px;
    }
}
