.Confirm {

}

.Confirm > .Title {
    color: #003DA5;
    font-family: Inter-Bold, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 45px;
}

.ButtonGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 24.8px;
    padding-bottom: 73px;
}

.ButtonGroup .space {
    width: 28px;
}

.PlanSummaryInfoGroup {
    padding-top: 35px;
    background-color: #FFFFFF;
    margin-top: 16px;
    border: 1px solid #EDEDED;
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    border-radius: 6px;
    position: relative;
}
.PlanSummaryInfoGroup .EditButton{
    top:42px;
    right:33px;
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.EditButton2{
    top:440px;
    right:33px;
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.PlanSummaryInfoGroup > .RowGroup > .Row {
    padding-left: 103px;
    padding-right: 133px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.PlanSummaryInfoGroup > .RowGroup > .Row .Key {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.PlanSummaryInfoGroup > .RowGroup .Key .MiniIcon{
    height: 20px;
    width: 20px;
    color: #000000;
    margin-left: 20px;
    font-family: Arial, Noto, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    position: relative;
    display: inline-block;
}

.PlanSummaryInfoGroup > .RowGroup .Key .imageDiv img{
    width: 20px;
    height: 20px;
}

.PlanSummaryInfoGroup > .RowGroup .Key .MiniIcon .pointerDiv{
    top: 55px;
    left: -80px;
    position: absolute;
    border-top: 10px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 20px solid #EDEDED;
    border-right: 15px solid transparent;
}
.PlanSummaryInfoGroup > .RowGroup .Key .MiniIcon .contentDiv{
    font-family: Arial, Noto, sans-serif;
    font-size: 14px;
    background:  #EDEDED;
    /*min-height: 200px;*/
    width: 550px;
    min-width: 300px;
    margin-left: 19px;
    padding: 10px 10px 10px 20px;
    text-align: left;
    line-height: 19px;
    border-bottom: 5px solid #00A758;
    top: 80px;
    left: -200px;
    position: absolute;
    font-weight: normal;
    /*text-align: justify;*/
}
#optionalSpecified,
#optionalDomestic{
    margin-top: -25px;
}
#optionalSpecified{
    margin-top: -25px;
}
.PlanSummaryInfoGroup > .RowGroup > .Row:not(:last-child) {
    margin-bottom: 35px;
}
.maxWidth{
   max-width: 866px;
}
.PlanSummaryInfoGroup > .RowGroup > .Row:last-child {
    margin-bottom: 27px;
}

.PlanSummaryInfoGroup > .RowGroup > .Row > .Key {
    width: 161px;
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
}

.PlanSummaryInfoGroup > .RowGroup > .Row > .Mid {
    flex-grow: 3;
}

.PlanSummaryInfoGroup > .TotalPremium {
    background-color: #009AE4;
    padding-right: 125px;
    text-align: right;
    padding-top: 19px;
    padding-bottom: 20px;
    color: #FFFFFF;
    font-family: Arial, Noto, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    border-radius: 0 0 6px 6px;

}

.PlanSummaryInfoGroup > .TotalPremium > .Amount {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    margin-left: 20px;
}

.InsuredPersonGroup {
    padding-left: 103px;
    padding-right: 33px;
    margin-top: 18px;
}

.InsuredPersonGroup .EditButton{
    top: 26px;
    right:33px;
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.InsuredPersonGroup > .NoPersonRoleGroup {
    padding-top: 48.5px;
    padding-left: 103px;
    padding-right: 133px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 2;
    width: 80%;
    border-bottom: 2px solid #00A8F3;
}

.InsuredPersonGroup > .NoBorderPersonRoleGroup {
    padding-top: 48.5px;
    padding-left: 103px;
    padding-right: 133px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 2;
    width: 80%;
}
.widthAuto{
    width: auto!important;
}
.InsuredPersonGroup > .PersonRoleGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 2;
    width: 182px;
}

.InsuredPersonGroup > .PersonRoleGroup > .Icon {
    min-width: 21px;
    height: 40px;
    overflow: hidden;
    margin-right: 15px;
}

.InsuredPersonGroup > .PersonRoleGroup > .Icon2 {
    overflow: hidden;
    margin-right: 8px;
}

.InsuredPersonGroup > .PersonRoleGroup > .Icon.Children {
    height: 30px;
    width: 30px;
    overflow: hidden;
    margin-right: 15px;
}

.InsuredPersonGroup > .PersonRoleGroup > .Icon.Property {
    width: 44px;
    height: 36px;
    overflow: hidden;
    margin-right: 15px;
}

.InsuredPersonGroup > .PersonRoleGroup > .Content {
    min-width: 130px;
}

.InsuredPersonGroup > .PersonRoleGroup > .Content > .AgeGroup {
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
}

.InsuredPersonGroup > .PersonRoleGroup > .Content > .JobRole {
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    max-width: 300px;
}

.InsuredPersonGroup > .PersonContentInfoGroup{
    flex-grow: 2;
}

.InsuredPersonGroup > .PersonContentInfoGroup > .Row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -3%;
}

.InsuredPersonGroup > .PersonContentInfoGroup > .Row:not(:last-child) {
    margin-bottom: 45px;
}

.InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    width: 50%;
}
.infoImg{
    width: 20px;
    margin-left: 12px!important;
}
.SameasInsured {
    /*margin-left: -50%;*/
    width: 100%;
}

.SVblueBorder{
    margin-top: 36px;
    padding-top:0px!important;
}
.SVnoBorder{
    margin-top: 30px;
    padding-top:0px!important;
}
@media (max-width: 550px) {
    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem {
        display: flex ;
        align-items: flex-start !important;
        text-align: left;

        margin-bottom: 24px;

    }
    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem > .Value {
        min-width: auto !important;
    }
    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem > .Key {
        margin-right: 27px;
    }
    .InsuredPersonGroup > .PersonRoleGroup > .Content > .AgeGroup> br {
        display: initial !important;
    }
}
@media (min-width: 1024px) {
    .fullname {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;
        width: 100%!important;
    }
    #separator {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-top: 1px solid #32C5FF;
    }
}
.col{
    display: flex;
    flex-direction: column;
}
.InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem > .Key {
    width: 160px;
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
}
.InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem > .Key2 {
    width: 211px;
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 15px;
}

.InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem > .Value {
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    min-width: 190px;
    flex-grow: 1;
}

.InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem2 > .Value {
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    min-width: 100%;
    flex-grow: 1;
}

.PersonalInformationCollectionStatement,
.MajorExclusions,
.Declarations{
    color: #282B3E;
    font-family: Arial, Noto, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
}
.PersonalInformationCollectionStatement ol{
    padding-right: 10px;
}
.PersonalInformationCollectionStatement .bold{
    font-weight: bold;
}
.MajorExclusions ul,
.Declarations ul{

    list-style: none;
    padding: 0;
}

.MajorExclusions li,
.Declarations li{
    display: table-row;
}

.PersonalInformationCollectionStatement li{
    padding-left: 20px;

}

.MajorExclusions li::before,
.Declarations li::before{
    display: table-cell;
    content: '•';
    padding-left: 5px;
    padding-right: 20px;
}
.PaymentDeclarationTitle {
    margin-top: 45px;
    color: #282B3E;
    font-family: Inter-Bold, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 20px;
}
.PaymentDeclarationGroups .Error{
    color: #C14A36;
    font-family: Inter, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    margin-top: 8px;
    margin-bottom: 0;
}

.PaymentDeclarationGroups .Item {
    color: #000000;
    font-family: Inter, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 45px;
    margin-bottom: 14px;
    position: relative;
    margin-left: 18px;
}

.PaymentDeclarationGroups input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.PaymentDeclarationGroups .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 5px solid #eee;
    background-color: transparent;
}

/* On mouse-over, add a grey background color */
.PaymentDeclarationGroups .container:hover input[type=checkbox] ~ .checkmark {

}

/* When the checkbox is checked, add a blue background */
.PaymentDeclarationGroups .container input[type=checkbox]:checked ~ .checkmark {
    border-color: #702F8A;
    color: #702F8A;
}

/* Create the checkmark/indicator (hidden when not checked) */
.PaymentDeclarationGroups .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.PaymentDeclarationGroups .container input[type=checkbox]:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.PaymentDeclarationGroups .container .checkmark:after {
    left: 10px;
    top: 5px;
    width: 10px;
    height: 17px;
    border: solid #702F8A;
    border-width: 0 5px 5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    color: #702F8A;
}

.PaymentDeclarationGroups .container {
    position: relative;
    height: 45px;
    /*line-height: 45px;*/
    padding-left: 73px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
}

.PaymentDeclarationGroups .container .Wording{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 47px;
}

.PaymentDeclarationGroups .Link {
    color: #009AE4;
    font-family: Inter, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    cursor: pointer;
}
.EmployeeNo{
    color: #003DA5;
    font-family: Inter-Bold, sans-serif;

    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 40px;
}
#specifiedP{
    padding: 0px 0px !important;
    box-sizing: content-box !important;
    border: 0px !important;
}
#specifiedP1{
    padding: 0px 0px !important;
    box-sizing: content-box !important;
    border: 0px !important;
}
.PaymentDeclarationGroups .Link:hover {
    text-decoration: underline;
}
@media (min-width: 1024px) and (max-width: 1440px) {
    .InsuredPersonGroup > .PersonRoleGroup {
        margin-bottom: 40px;
    }
}
@media (min-width: 1024px) and (max-width: 1024px) {
    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem.FullName {
        width: 50%;
    }
    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem.Separator {
        width: 50%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-top: 1px solid #32C5FF;
    }
    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem > .Key {
        margin-bottom: 0px!important;
        min-width: 200px;
    }

    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem.SameasInsured {
        min-width: 443px;
    }
}


    .MarginrightOfStreet {
        width: 50%;
        max-width: 192px;
    }
@media (max-width: 1024px) {

    .width452{
        width: 452px;
    }
    .InsuredPersonGroup {
        background-color: #FFFFFF;
        box-sizing: border-box;
        border: 1px solid #EDEDED;
        border-bottom: 5px solid #00A758;
        padding: 30px 36px 26px 22px;
        margin-top: 26px;
        position: relative;
    }
    .InsuredPersonGroup > .PersonContentInfoGroup > .Row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .InsuredPersonGroup > .PersonContentInfoGroup > .Row:not(:last-child) {
        margin-bottom: 0px;
    }

    .MarginRight64{
        margin-right: 0%;
    }
    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;
        width: 100%;
        margin-bottom: 24px;
    }

    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem > .Key {
        width: 150px;
        /*margin-bottom: 24px;*/
    }

    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem > .Key2 {
        width: 443px;
        /*margin-bottom: 24px;*/
    }
    /*.InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem > .Value {*/
    /*    margin-bottom: 24px;*/
    /*}*/
    .Confirm{
        padding-left: 20px!important;
        padding-right: 20px!important;
        box-sizing: border-box;
    }
    .PlanSummaryInfoGroup > .RowGroup > .Row {
        padding-left: 23px;
        padding-right: 27px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    .PlanSummaryInfoGroup > .RowGroup > .Row .Empty{
        display: none;
    }

    .PlanSummaryInfoGroup > .RowGroup > .Row .Key {
        display: flex;
        flex-direction: row;
        flex-grow: 10;
        width: 100%;
        align-items: center;
    }
    .PlanSummaryInfoGroup > .RowGroup > .Row .Key.MobileOneRow {
        display: flex;
        flex-direction: row;
        flex-grow: 2;
        align-items: center;
        justify-content: space-between;
    }
    .PlanSummaryInfoGroup > .RowGroup > .Row .Mid {
        max-width: 190px;
    }

    .PlanSummaryInfoGroup > .RowGroup .Key .MiniIcon{
        height: 20px;
        width: 20px;
        color: #000000;
        margin-left: 20px;
        font-family: Arial, Noto, sans-serif;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        position: relative;
        display: inline-block;
    }
    .PlanSummaryInfoGroup > .TotalPremium .Amount {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 23px;
    }
    .PlanSummaryInfoGroup > .TotalPremium {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        padding: 14px 27px 17px 23px;
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .PaymentInputGroups {
        box-sizing: border-box;
        border: 1px solid #EDEDED;
        background-color: #FFFFFF;
        padding-top: 45px;
        padding-left: 22px;
        padding-right: 21px;
        padding-bottom: 33px;
    }
    .PaymentInputGroups .InputRow{
        display: flex;
        flex-direction: column;
    }
    .PaymentInputGroups .InputRow:first-child{
        margin-bottom: 13px;
    }
    .PaymentInputGroups .InputRow .InputItem{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-right: 85px;
        margin-bottom: 29px;
    }
    .PaymentDeclarationTitle {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
    }
    .PaymentTitle {
        padding-top: 15px;
        padding-bottom: 14px;
        padding-left: 18px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
    }
    .PaymentCardCompany{
        height: 81px;
        padding-top: 14px;
        padding-bottom: 0px;
        padding-left: 0px;
    }
    .PaymentDeclarationGroups .Item {
        color: #282B3E;
        font-family: Arial, Noto, sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        height: auto;
        margin-bottom: 14px;
        position: relative;
    }
    .PaymentDeclarationGroups .Error{
        margin-bottom: 20px;
    }

    .PaymentDeclarationGroups input[type=checkbox] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .PaymentDeclarationGroups .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        transform: scale(0.7);
        transform-origin: top left;
        border-radius: 50%;
        border: 5px solid #eee;
        background-color: transparent;
    }

    /* On mouse-over, add a grey background color */
    .PaymentDeclarationGroups .container:hover input[type=checkbox] ~ .checkmark {

    }


    /* Create the checkmark/indicator (hidden when not checked) */
    .PaymentDeclarationGroups .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .PaymentDeclarationGroups .container input[type=checkbox]:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .PaymentDeclarationGroups .container .checkmark:after {
        left: 10px;
        top: 5px;
        width: 10px;
        height: 17px;
        border-width: 0 5px 5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .PaymentDeclarationGroups .container {
        position: relative;
        height: auto;
        /*line-height: 45px;*/
        padding-left: 42px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: block;
    }

    .PaymentDeclarationGroups .container .Wording{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 62px;
    }

    .PaymentDeclarationGroups .Link {
        color: #00A758;
        font-family: Arial, Noto, sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
    }

    .PaymentDeclarationGroups .Link:hover {
        text-decoration: underline;
    }
    .Confirm .ButtonGroup {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 3;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 0px;
        padding-bottom: 0;
        width: 100vw;
    }
    .Confirm .ButtonGroup div{
        flex-grow: 2;
    }
    .Confirm .ButtonGroup .space{
        display: none;
    }
    .InsuredPersonGroup > .PersonRoleGroup > .Icon {
        overflow: hidden;
        margin-right: 7px;
    }
    .Confirm > .Title {
        font-family: Inter-Bold, sans-serif;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 25px;
        margin-top: 32px;
    }
    .PaymentDeclarationGroups .container .Wording {
        align-items: flex-start;
    }
    .PlanSummaryInfoGroup > .RowGroup .Key .MiniIcon .contentDiv{

        /*min-height: 200px;*/
        width: 100vw;
        box-sizing: border-box;
        left: -187px;
        height: auto;


    }
    .mobileBack{
        display: none;
    }
    .Confirm > .ButtonGroup{
        height: 76px;
        background-color: #FFF;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        z-index: 2;
    }
    .Confirm .ButtonGroup .space{
        display: none;
    }
    .Confirm .ButtonGroup{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 76px;

    }
}
@media (max-width: 1024px){
    .InsuredPersonGroup{
        padding-left: 24px!important;
        padding-right: 24px!important;
    }
    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem > .Key {
        width: 50%;
        /*margin-bottom: 24px;*/
    }
    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem > .Value {
        color: #282B3E;
        font-family: Arial, Noto, sans-serif;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 18px;
        min-width: 301px;
        max-width: 50%;
        flex-grow: 1;
    }
    .PersonRoleGroup.PersonContentInfoGroup{
        margin-bottom: 30px!important;
    }
}

.PaymentDeclarationGroups .Item {
    margin-left: 18px;
}
.PersonalInformationCollectionStatement{
    color: #000000;
    font-family: Inter, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
}
.PersonalInformationCollectionStatement ul,
.PersonalInformationCollectionStatement ol{
    padding-left:15px;

}
.PersonalInformationCollectionStatement ol{
    padding-top: 14px;
}
.PersonalInformationCollectionStatement li{
    padding-bottom: 14px;
    padding-left: 0px!important;
}
.PersonalInformationCollectionStatement .popup-link{
    color: #009ae4;
    text-decoration: none;

}
.PersonalInformationCollectionStatementContainer{
    width: 800px!important;
}
.PersonalInformationCollectionStatementContainer.large .ModalContent {
    padding-top: 18px;
}
.DeclarationsPopupContent{
    margin-left: 0;
    padding-left: 0;
}
.DeclarationsPopupContent ul{
    padding-left: 0!important;
    margin-left: -5px;
}
@media (max-width: 1024px) {
#Template:not(.HKG-template) .PersonContentInfoGroup{
    width: 100%;
}
    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem > .Key {
         width: 50%;
        margin-right: 0;
        /* margin-bottom: 24px; */
    }
    #Template:not(.HKG-template) .Value.MarginrightOfStreet{
        overflow-wrap: break-word;
    }
    .PersonRoleGroup.PersonContentInfoGroup .Icon{
        margin-right: 24px!important;
        margin-left: 15px;
    }
    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem > .Value {
        overflow-wrap: break-word;
        /*flex-shrink: 0;*/
        flex-grow: 0;
        max-width: 50%;
        overflow-x: hidden;
    }
    .InsuredPersonGroup > .PersonContentInfoGroup > .Row > .RowItem > .Key {
        /* width: 150px; */
        width: 50%;
        flex-shrink: 0;
        /* margin-bottom: 24px; */
    }
    .PaymentDeclarationGroups{
        margin-bottom: 60px;
    }
    .PlanSummaryInfoGroup .EditButton{
        top:26px;
        right:26px;
    }
    .Declarations{
        padding-left: 0;
    }
}
@media (max-width: 768px) {
    .MajorExclusions li::before,
    .Declarations li::before{
        padding-right: 32px;
    }
}
