.Apply .DomesticWorkerGroup{

}
.ApplySeparatorContainer{
    position: relative;
    margin-top: 25px;
}
.Apply .EmployeeNo{
    color: #003DA5;
    font-family: Inter-Bold, sans-serif;

    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
}
.PassportSelect label{
    width: 120px;
}
.ApplySeparator{
    position: absolute;
    box-sizing: border-box;
    width: 110%;
    margin-right: auto;
    left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid #32C5FF;
    overflow: visible;
}
.Apply .ApplicantInputField .MuiFormHelperText-root {
    padding: 0!important;
}
.Apply .QuoteRemarks{
    color: #000000;
    font-family: Inter, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
}

.AddSpecifiedPersonalValuables .AdjustPlus{
    margin-right: 10px;
    padding-left: 0;
}
.AddSpecifiedPersonalValuables .AdjustPlus .Content{
    fill: #28B5F5;
}
.HKGOptionalCover .Content .JobRole{
    color: #0A1F44;
    font-family: Inter, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: normal;
}
.DeleteSPV{
    width: 14px;
    height: 14px;
}
.ApplicantInputField.SBHeading{
    justify-content: space-between;
    align-items: center;
    min-height: 0;
    margin-bottom: 0px;
    margin-top: 30px;
}
.SpecifiedPersonalValuablesGroups .ApplicantInputField{
    margin-bottom: 0px;
}
.ApplicantInputField.AddSpecifiedPersonalValuables{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #000000;
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    margin-right: auto !important;
    /*margin-top: 0px;*/
    min-height: 0;
    margin-bottom: 13px;
}
.AddSpecifiedPersonalValuablesBtn{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.HKGOptionalCover{
    min-width: 322px;
    box-sizing: border-box;
    color: #0A1F44;
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px
}
@media (max-width: 1024px){
    .ApplySeparator{
        position: relative;
        width: 100%;
        left: 0;
        box-sizing: border-box;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: -10px;
    }
    .Apply{
        margin-left: auto;
        margin-right: auto;
    }
    .Apply .ApplicantInfoGroup {
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
    }


}
.ApplicantTypeGroup.ApplyDMSOptionalCover .Content,
div.ApplicantTypeGroup.HKGOptionalCover .Content{
    max-width: 320px;
}
.DomesticWorkerGroups .ApplicantInfoGroup,
.SpecifiedPersonalValuablesGroups .ApplicantInfoGroup {
    margin-left: 0;
    margin-right: 0;
}
.DomesticWorkerGroups .ApplySeparator,
.SpecifiedPersonalValuablesGroups .ApplySeparator{
    width: 100%;
}
.sgp-remarks{
    padding-left: 51px;
}
.ApplicantInputField{
    margin-top: 0;
    margin-bottom: 0;
    height: 101px;
    box-sizing: border-box;
}
.Apply:not(.Applicant) .ApplicantInputField{
    height: 116px;
}
.Apply:not(.Applicant) .ApplicantInputFields .EmployeeNo{
    margin-left: 20px;
    color: #003DA5;
    font-family: Inter-Bold, sans-serif;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
}
@media (max-width: 1024px){
    .ApplicantTypeGroup {
        margin-bottom: 20px;
    }
    .HousingTypeContainer{
        margin-top: -5px!important;
    }
    .Apply:not(.Applicant) .ApplicantInputFields .EmployeeNo {
        margin-left: 0px;

    }
    .ApplyTopIcon{
        width: 60px;
        flex-shrink: 0;
    }

}



.ApplySeparator {
    position: relative;
    box-sizing: border-box;
    width: 110%;
    margin-right: auto;
    left: 20px;
     margin-top: -32px;
    margin-bottom: 24.5px;
    border-top: 1px solid #32C5FF;
    overflow: visible;
}
@media (max-width: 1024px) {
    .ApplySeparator {
        left: 0;
        margin-left: 0!important;
    }
    .ApplicantToggleGroup ul{
        padding-left: 28px;
    }
    .ApplicantToggleGroup li{
        padding-left: 23px;
    }
    .Apply .space{
        display: none;
    }
    .Apply .ApplicantInputFields .Item {
        padding-left: 0px;
    }
    .Apply .ButtonGroup{
        position: fixed;
        z-index: 5;
        bottom: 0;
        left: 0;
        padding-bottom: 12px;
        padding-top: 12px;
        background-color: #fff;
        width: 100vw;
        padding-left: 0;
    }
}

.ApplicantInputFields .Item {
    padding-left: 20px;
    height: 25px;
}
.ApplicantInputFields .container  {
    margin-top: -11px;
}
.ApplicantInputFields .container .Wording {
    height: 25px;
}
.HKG-template .ApplicantTypeGroup.HKGOptionalCover{
    display: flex;
    flex-direction: column;
}
.HKG-template .SPVErrorMsgBox{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #A50000;
    font-family: Inter-Bold, sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 20px;
}
.HKG-template .SPVErrorMsg{
    width: 305px;
}
.HKG-template .SPVErrorIcon{
    flex-shrink: 0;
    padding-left: 12px;
    padding-right: 17px;
}
.HKG-template .SPVErrorIcon svg{
    fill: #A50000;
    height: 20px;
    width: 22px;
}
.HKG-template .SpecifiedPersonalValuablesFlex{
    display: flex;
    flex-direction: row;
}
