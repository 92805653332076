.MobileFooter{
    display: none;
}
.LandingContainer.BTTContainer{
    position: fixed;
    bottom: 72.45px;
    left: 50%;
    transform: translateX(-50%);
}
.BackToTopBtn{
    position: fixed;
    cursor: pointer;
    right: -60px;
    bottom: 0;

}
.box2{
    width: 11% !important;
}
@media (max-width: 1668px){
    .BackToTopBtn{
        right: 20px;
    }
}
@media (max-width: 1440px){
    .LandingContainer.BTTContainer{
        width: 100vw;
    }
    .BackToTopBtn{
        right: 20px;
    }
}

@media (max-width: 1024px) {
    .LandingContainer.BTTContainer{
        position: fixed;
        width: 100vw;
        bottom: 72.45px;
        right: 0;
        left: unset;
        transform: none;
    }
    .BackToTopBtn{
        right: 20px;
        bottom: 72.45px;
    }
    .SiteMap,
    .MobileFooter{
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    .MobileFooter{
        padding-top: 17.5px;
    }
    .SiteMap{
        width: 100vw;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
    .SiteMap .Site{
        box-sizing: border-box;
        padding-bottom: 11.5px;
        padding-top: 11.5px;
        border-bottom: 1px solid #FFFFFF;
        font-family: Inter, sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
    }
    .SiteMap .Site:last-child{
        border-bottom: 1px solid transparent;
    }
    .MobileNeedHelp{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        font-family: Inter, sans-serif;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
    }
    .MobileNeedHelp .Bar{
        height: 1px;
        max-height: 1px;
        border-bottom:  1px solid #4EC2F6;
        flex-grow: 1;
        margin-right: 14px;

    }
    .MobileFooter .ContactUsLink{
        text-decoration: none;
        color: #003DA5!important;

    }
    .MobileFooter .ContactUs{
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        color: #003DA5!important;
        margin: auto auto!important;
        margin-top: 12px!important;
    }
    .MobileFooter  .LogoBox {
        width: auto!important;
        display: inline-block;
        position: relative!important;
        margin-left: 0!important;
        margin: auto auto!important;
    }
    .MobileFooter  .QBELogo {
        height: 40px!important;
        width: 140px!important;
        margin: auto auto!important;
        margin-top: 35px!important;
    }
    .MobileFooter .headerBW{
        cursor: pointer;
        fill: #fff;
        transition-duration: 0.3s;
    }
    .QbeLinkContainer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 36px;
    }
    .MobileCopyrightNotice{
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
        padding-left: 33px;
        padding-right: 33px;
        padding-top: 12px;
        padding-bottom: 26px;
    }
    .ContactUs{
        width: 200px!important;
    }
}
