.LandingContainer{
    width: 1440px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    padding-left: 45px;
    padding-right: 45px;
    transition-duration: 0.3s;
}
@media (max-width: 1440px) {
    .LandingContainer{
        width: auto;
        margin: 0 auto;
        position: relative;
        box-sizing: border-box;
        padding-left: 24px;
        padding-right: 24px;
    }
    .LandingQBESGP .LandingContainer {
        height: 100%;
        margin: 0 0;
        width: 100%;
    }
}
.LandingTop{
    position: relative;
    background-image: url("../../assets/home_keyvisual_nofilter_2x.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /*height: 848px;*/
    min-height: 848px;
}
.LandingTop::after{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
}
.LandingPadding{
    height: 100px;
}
.TopErrorContainer {
    min-height: 100px;

    color: #ffffff;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    z-index: 10;
    /*opacity: 0.2;*/
}
.TopErrorContainer span.ErrorText {
    max-width: 420px;
}
.TopErrorContainer svg.ErrorImg {
    margin-right: 25px;
    height: 43px;
}
.LandingTopContainer{
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 1;
    /*padding-top: 100px;*/
}
@media (max-width: 1400px) {
    div.LandingProductTitle {
        width: 99%;
    }
}
@media (max-width: 1152px) and (max-width: 1152px) {
    div.LandingProductTitle {
        width: 82%;
    }
}
@media (max-width: 1024px) {
    .LandingContainer{
        padding: 0;
    }
    .LandingTop{
        background-image: none;
        min-height: 0px;
        width: 100vw;
        margin: 0;
        padding: 0!important;
    }
    .LandingTopContainer{
        display: flex;
        flex-direction: column;
        z-index: 1;
        padding:0;
        /*padding-top: 100px;*/
    }
}
.LandingTopLeft{
    z-index: 1;
}
.LandingTopRightImage{
    background-image: url("../../assets/Rounded.svg");
    transform: scale(1.1);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: -450px;
    top: -395px;
    height: 1118px;
    width: 1130px;
    z-index: 0;
}
.LandingProductTitle{
    height: 205px;
    width: auto;
    color: #FFFFFF;
    margin-top: 98px;
    font-family: Stag-Medium, sans-serif;
    font-size: 56px;
    font-weight: 500;
    letter-spacing: 1.4px;
    line-height: 58px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
div.MiniIconChildInsured {
    margin-left: 0%;
    margin-bottom: 15px;
    z-index: 2;
}

_:-ms-lang(x), div.MiniIconChildInsured {
    margin-bottom: -10px!important;
}
@media (max-width: 1203px) {
    .LandingProductTitle{
        height: 205px;
        width: 600px;
    }
}
.ListFeatures > ul{
    list-style:none;
    padding-left: 0;
    /*padding-left: 1em;*/
}
.ListFeatures li{
    font-family: Inter-Medium, sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    display: table-row;

}
.ListFeatures li::before{
    content: '';
    display: table-cell;
    width: 26px;
    height: 26px;
    top: 13px;
    position: relative;
    padding-right: 21px;
    background-repeat: no-repeat;
    background-image: url("../../assets/RoundedTick.svg");
}
.ListItemText{
    margin: 0;
    padding-bottom: 19px!important;
}

.FindOutMoreButton{
    height: 52px!important;
    width: 200px!important;
    background-color: #FFFFFF!important;
    border-radius: 28px;
    font-family: Inter-Medium , sans-serif !important;
    font-size: 14px!important;
    font-weight: 500!important;
    letter-spacing: 0!important;
    color: #003DA5!important;
    line-height: 20px!important;
}
.LandingTopRight{
    min-width: 395px;
    width: 395px;
    z-index: 1;
}
.LandingTopRight>.Title{
    color: #FFFFFF;
    font-family: Stag-Medium, sans-serif;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1.1px;
    line-height: 28px;
    padding-top: 24px;
    margin-top: 88px;
    border-top: 1px solid #4EC2F6;
    margin-bottom: 22px;
}
.LandingHousingTypeBalloonTipTitle{
    color: #FFFFFF;
    font-family: Inter-Bold, sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
}
.LandingHousingTypeBalloonTipContent{
    color: #FFFFFF;
    font-family: Inter, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    padding-left: 15px;
}
.LandingHousingTypeBalloonTipContent_HK {
    list-style: none;
}
.LandingFormRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 37px;
    min-height: 50px;
}
.LandingFormRow .Title{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.LandingFormRow.Small{
    margin-bottom: 18px;
}
.LandingFormRow.Notice{
    font-family: Inter, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    margin-top: 0px!important;
    margin-bottom: 32px;
}
.LandingFormRow .Key{
    font-family: Inter-Medium, sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
}
.LandingFormRow .input{
    display: flex;
    flex-direction: row;
    min-width: 200px;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    margin-top:5px;
}
.GetQuoteBtn{
    height: 52px!important;
    width: 200px!important;
    background-color: #FFFFFF!important;
    border-radius: 28px;
    font-family: Inter-Medium , sans-serif !important;
    font-size: 14px!important;
    font-weight: 500!important;
    letter-spacing: 0!important;
    color: #003DA5!important;
    line-height: 20px!important;
}
.GetQuoteBtnWidth {
    width: 180px;
}
.ChildrenDropdownError {
    position: absolute;
    margin-top: 100px;
    margin-bottom: 20px;
    margin-left: 200px;
    font-size: 12px;
    color: #a50000;
}
.HousingTypeSelector {
    color: #0A1F44;
    font-family: "Inter", sans-serif;
}
.OutlineIconButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 172px;
    height: 61px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 30px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    cursor: pointer;
    margin-right: 10px;
}
.OutlineIconButton.Landlord{
    min-width: 210px;
}
.OutlineIconButton.Small{
    height: 45px;
    min-width: 90px;
}
.OutlineIconButton .landingSVG{
    fill: #fff;
}
.OutlineIconButton.Picked{
    /*color: ;*/
    border: 1px solid #28B5F5;
    background-color: #28B5F5;
    color: #0A1F44;
    font-family: Inter-Bold, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
}
.OutlineIconButton.Picked .landingSVG{
    /*color: ;*/
    fill: #0A1F44;
}
.OutlineIconButton .IconGroup{
    margin-right: 0px;
}

.OutlineIconButton2{
    height: 116px;
    width: 111px;
    border: 1px solid #FFFFFF;
    border-radius: 20px;
    line-height: 18px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;
}
.OutlineIconButton2 svg path{
    fill: #fff;
}
.OutlineIconButton2.Small{
    height: 45px;
    min-width: 90px;
}
.OutlineIconButton2 .landingSVG{
    fill: #fff;
}
.OutlineIconButton2.Picked{
    /*color: ;*/
    border: 1px solid #28B5F5;
    background-color: #28B5F5;
    color: #0A1F44;
    font-family: Inter-Bold, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
}
.OutlineIconButton2.Picked svg path{
    fill: #0A1F44;
    /*stroke: #0A1F44;*/

}
/*.OutlineIconButton2.Picked svg path.stroke-fill{*/
/*    stroke-width: 0.7px;*/
/*}*/
/*.OutlineIconButton2.Picked .landingSVG{*/
/*    !*color: ;*!*/
/*    fill: #0A1F44;*/
/*}*/
.OutlineIconButton2 .IconGroup{
    margin-right: 0px;
}
.TenantIcon{
    margin-bottom: 12px;
}
.OwnerOccupiedIcon{
    margin-bottom: 2px;
}
.LandlordIcon{
    margin-bottom: 12px;
}
@media screen and (max-width: 1024px){
    .OutlineIconButton2{
        box-sizing: border-box;
        height: 116px;
        width: 103px;
        border: 1px solid #FFFFFF;
        border-radius: 20px;
    }
}
.LandingFormRow .Key .Title{
    font-family: Inter-Medium, sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    height: 56px;
    box-sizing: border-box;
    padding-top: 23.5px;
}
.explain .link{
    cursor: pointer;
}
.LandingFormRow .input{
    max-width: 197px;
}
.LandingFormRow .MuiSelect-selectMenu {
    height: auto;
    overflow: hidden;
    min-height: 1.1876em;
    white-space: pre-wrap;
    text-overflow: initial;
}
.LandingTopRight div.MuiFormControl-root {
    background: transparent;
}
.LandingTopRight .MuiFormHelperText-root {
    margin-top: 0;
    padding: 8px 0 0 0px!important;
    color: #0a1f44;
    display: none!important;
    font-family: Inter,Verdana,Arial,sans-serif;
}
.LandingTopRight .input span{
    width: 100%;
}
.LandingTopRight .MuiFormControl-root .MuiSelect-root,
.LandingTopRight .MuiFormControl-root .MuiFormLabel-root,
.LandingTopRight .MuiSelect-icon
{
    color: white!important;
    text-align: center;
}

.LandingTopRight .MuiSelect-icon {
    margin-top: 5px;
    margin-left: 10px;
}
.MobileLandingStartQuoteButton{
    display: none;
}
.MobileLandingTopFindOutMoreBtn{
    display: none;
}
@media (max-width: 1024px) {
    .LandingFormRow{
        margin-bottom: 30px;
    }
    .LandingFormRow .Key .Title{
        font-family: Inter-Medium, sans-serif;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        height: auto;
        box-sizing: border-box;
        padding-top: 0px;
        margin-bottom: -28px;
    }
    .LandingTopRightImage{
        display: none;
    }
    .MobileLandingStartQuoteButton{
        display: inline-block;
        margin-top: 15px;
        margin-bottom: 129px;
    }
    .MobileLandingStartQuoteButton .FindOutMoreButton{
        width: 144px!important;
    }
    .LandingProductTitle{
        height: 73px;
        width: 100%;
        color: #FFFFFF;
        margin-top: 78px;
        font-family: Stag-Medium, sans-serif;
        font-size: 32px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .ListFeatures li {
        font-family: Inter-SemiBold, sans-serif;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 21px;
        display: table-row;
    }
    .ListFeatures ul{
        margin: 0;
    }
    .LandingTopLeft{
        z-index: 1;
        padding-left: 20px!important;
        padding-right: 20px!important;
        position: relative;
        background-image: url("../../assets/home_keyvisual_nofilter_2x_Mobile.png");
        background-repeat: no-repeat;
        background-position: 48%;
        background-size: cover;
        height: auto;
    }
    .LandingTopLeft::after{
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.5;
        z-index: -1;
    }
    .ListFeatures li::before{
        content: '';
        display: table-cell;
        width: 20px;
        height: 20px;
        top: 13px;
        position: relative;
        padding-right: 12px;
        background-repeat: no-repeat;
        background-image: url("../../assets/RoundedTick.svg");
        background-size: 20px 20px;
    }
    .LandingTopFindOutMoreBtn{
        display: none;
    }
    .MobileLandingTopFindOutMoreBtn{
        width: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 115px;
        color: #FFFFFF;
        font-family: Inter-SemiBold, sans-serif;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
    }
    .MobileLandingTopFindOutMoreBtn svg{
        margin-left: 3px;
    }
    .LandingTopRight::before{
        content: '';
        /*position: ;*/
        position: absolute;
        top: -16px;
        left: 0;
        width: 100vw;
        height: 16px;
        background-color: rgba(0, 61, 165, 0.5);
    }
    .LandingTopRight {
        position: relative;
        /*border-top: 16px solid #003DA5;*/
        padding-left: 20px;
        padding-right: 20px;
        min-width: 0;
        width: auto;
        box-sizing: border-box;
        background-color: #14284B;
        padding-bottom: 30px;
    }
    .LandingTopRight>.Title{
        color: #FFFFFF;
        font-family: Stag-Medium, sans-serif;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.9px;
        line-height: 28px;
        padding-top: 30px;
        margin-top: 0px;
        border: none;
        margin-bottom: 35px;
    }
    .OutlineIconButton:not(.Small){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 0;
        width: 119px;
        height: 94px;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 20px;
    }
    .OutlineIconButton:not(.Small).Picked{
        border: 1px solid #28B5F5;
    }
    .OutlineIconButton:not(.Small) svg{
        margin: 0;
        margin-bottom: 5px;
    }

    .LandingFormRow,
    .LandingFormRow.Small{
        margin-right: 0;
        padding-left: 36px;
        padding-right: 36px;
    }
    .LandingFormRow.Small{
        flex-direction: column;
        align-items: stretch;
    }
    .LandingFormRow.Small .Key {
        text-align: center;
        margin-bottom: 10px;
    }
    .LandingFormRow.Small .Key br {
        display: none;
    }
    .LandingFormRow.GetQuoteBtnContainer {
        justify-content: center;
    }
    .LandingFormRow.Options{
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 20px;
    }
}

.LandingTop .MuiListItem-button:hover {
    background: #28B5F5 !important;
}
.LandingTopRight .MuiInput-underline:before {
    border-bottom: 1px solid #fff;
}
.LandingTopDiscountBanner{
    margin-top: 50px;
    width: 100%;
    background: linear-gradient(90deg, #003DA5 0%, #00A8F3 100%);
    position: relative;
    z-index: 1;
    padding: 20px 0;
}
.LandingTopDiscountBanner.short{
    margin-top: 89px;
}
.DiscountBannerContent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.DiscountBannerContent .LearnMoreButton{
    width: 190px;
    padding: 0 20px;
}
.DiscountIconContainer {
    width: 105px;
}
.DiscountContent{
    color: #FFFFFF;
    font-family: Inter-Bold, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-right: 20px;
}
.LandingHeadingTitle{
    height: 109px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #003DA5;
    font-family: Stag-Medium, sans-serif;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
}
@media (max-width: 1024px) {
    .LandingHeadingTitle{
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 28px;
    }
}
.LandingProductFeatures{
    background-color: #F7F8F9;
    padding-top: 54px;
    padding-bottom: 54px;

}
.LandingQBESGP{
    width: 100%;
    height: 506px!important;
    position: relative;
    background-image: url("../../assets/infobannerbg.jpg");
    background-repeat: no-repeat;
    background-position: 55% 90%;
    background-size:  139.5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.LandingQBESGP::before{
    content: '';
    height: 506px!important;
    width: 100%;
    position: absolute;
    opacity: 0.85;
    background-color: #003DA5;
    top: 0;
    left: 0;
}

.LandingQBESGP .LandingContainer{
    height: 100%;
    margin: 0 0;
}
.LandingQBESGPFamilyImg{
    background-image: url("../../assets/whyimage@2x.png");
    background-repeat: no-repeat;
    background-position: 55% 90%;
    background-size: 746px 507px ;
    height: 507px;
    width: 746px;
    position: absolute;
    left: -245px;
    bottom: 0;
}
@media (max-width: 1310px) {

    .LandingQBESGPFamilyImg {
        /*left: 0;*/
        /*transform: scale(0.6);*/
        /*transform-origin: bottom left;*/
        display: none;
    }

}
.LandingQBESGPCircle{
    height: 240px;
    width: 500px;
    box-sizing: border-box;
    border-radius: 240px;
    border: 1px solid #32C5FF;
    transform: rotate(-35deg);
    position: absolute;
    right: -490px;
    opacity: 0.7;
    top: 100px;

}
.LandingQBESGPContainer{
    padding-top: 125px;
}
.eClaimContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #fff;
    width: 770px;
    margin-left: 43%;
    position: relative;
}

.eClaimContainer .eClaimsHeading{
    margin-bottom: 35px;
    font-family: Inter-Bold, sans-serif;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
}
.eClaimContainer .eClaimsContent{
    font-family: Inter-Medium, sans-serif;
    width: 485px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 50px;
}
.eClaimContainer .LearnMoreButton{
    width: 260px!important;
}
.eClaimsPhoneImg{
    position: absolute;
    right: 130px;
    top: 50%;
    transform: translateY(-50%) ;
}
.MobileEClaimFamily{
    display: none;
}

@media (min-width: 1650px) and (max-width: 1650px) {
    .LandingQBESGPFamilyImg {
        left: -8%;
    }

    .eClaimContainer {
        margin-left: 45%;
    }

    .LandingQBESGPCircle {
        right: -25%;
    }

}

@media (min-width: 1360px) and (max-width: 1680px) {
    .LandingQBESGPCircle {
        height: 200px!important;
        right: -28%!important;
    }
}


@media (max-width: 1440px) {

    .eClaimContainer{
        margin-left: 55%;
        width: 500px;
    }
    .eClaimsPhoneImg{
        position: absolute;
        right: 0px;
        transition-duration: 0.3s;
        bottom: -20px;
        top: unset;
        transform: none;
        /*transform: translateY(-50%);*/
    }
    .LandingQBESGPFamilyImg {
        left: 0%;
    }
}

@media (max-width: 1310px) {
    .eClaimContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        color: #fff;
        width: 700px;
        margin-left: auto;
        position: relative;
    }
    .eClaimsPhoneImg {
        position: absolute;
        right: 0px;
        transition-duration: 0.3s;
        bottom: -20px;
        top: unset;
        transform: none;
        transform: translateY(-50%);
    }
}
@media (min-width: 1024px) and (max-width: 1024px) {
    .LandingOtherPackage {
        /*margin-left: 20%;*/
    }

    .eClaimContainer {
        /*width: 450px!important;*/
        /*left: -260px!important;*/
    }

}

@media (max-width: 1024px) {
    .eClaimContainer{
        margin-left: 0;
    }
    .MobileEClaimFamily{
        background-image: url("../../assets/img_whyimage_mobile_2x.png");
        width: 100vw;
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        height: 350px;
    }
    .LandingQBESGP {
        width: 100%;
        height: auto!important;
        position: relative;
        background-image: url("../../assets/img_whyimage_blue_actualsize_mobile_2x.png");
        background-repeat: no-repeat;
        background-position: 38% 53%;
        background-size: 350%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding-bottom: 32px;
    }
    .LandingQBESGPFamilyImg{
        display: none;
    }
    .LandingQBESGPContainer {
        padding-top: 25px;
    }
    .eClaimContainer{
        width: 100vw;
        padding-left: 43px;
        padding-right: 43px;
        box-sizing: border-box;
    }
    .eClaimContainer .eClaimsHeading{
        font-family: Inter-SemiBold, sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
    }
    .eClaimContainer .eClaimsContent {
        width: auto;
        font-family: Inter-Regular, sans-serif;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 10px;
    }
    .eClaimsPhoneImg.MobileDeviceIcon{
        position: static;
        /*top: 0;*/
        /*right: 0;*/
        /*height: 80px;*/
        /*width: 55px;*/
        transform: none;
        align-self: center;
        margin-bottom: 41px;
    }
}
@media (max-width: 768px) {
    .MobileEClaimFamily{
        background-image: url("../../assets/img_whyimage_mobile_2x.png");
        width: 100vw;
        display: block;
        background-size: cover;
        height: 129px;
    }
    .LearnMoreButton{
        align-self: center;
    }
}


.LandingOtherPackage{
    padding-top: 24.56px;
    padding-bottom: 71px;
}
.LandingOtherPackage .LandingContainer{
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}
.Package{
    height: 542px;
    width: 440px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 8px 0 rgba(20,40,75,0.2);
    position: relative;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-left: 25.5px;
    margin-right: 25.5px;
    cursor: pointer;
    border-bottom: 8px solid transparent;

}
.Package.coming-soon::before {
    content: attr(label-text);
    left: -10px;
    height: 30px;
    width: 129px;
    background-color: #009AE4;
    color: #FFFFFF;
    font-family: Inter-Bold, sans-serif;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    position: absolute;
    top: 14px;
}

.PackageInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding: 27px;
    padding-bottom: 11px;
}
.PackageInfo .PackageName{
    font-family: Stag-Medium, sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 36px;
    margin-bottom: 22px;
}
.PackageInfo .PackageDesc{
    font-family: Inter, sans-serif;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 27px;
    flex-grow: 1;
}
.Package:hover{
    border-bottom: 8px solid #003DA5;
    transition-duration: 0.3s;
}
.LandingHeadingTitle br{
    display: none;
}
.LandingOtherPackage .MobileBuffer{
    display: none;
}
@media (max-width: 1024px) {
    .LandingOtherPackage{
        width: 100vw;
        display: block;
        box-sizing: border-box;
    }
    .LandingOtherPackage .LandingContainer{
        width: auto;
        box-sizing: border-box;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: flex-start;
    }
    .LandingOtherPackage .MobileBuffer{
        display: block;
        min-width: 20px;
        min-height: 20px;
    }
    .LandingOtherPackage .Package{
        display: flex;
        flex-wrap: nowrap;
        height: auto;
        width: 287px;
    }
    .LandingOtherPackage .Package .PackageImg{
        display: flex;
        flex-wrap: nowrap;
        height: 153px;
        width: 287px;
    }
    .LandingOtherPackage .PackageInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        padding: 18px;
        padding-bottom: 11px;
    }
    .LandingOtherPackage .PackageName{
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.83px;
        line-height: 36px;
        margin-bottom: 10px;
    }
    .LandingOtherPackage .PackageIcon{
        height: 41px;
        width: 41px;
    }
    .LandingOtherPackage .PackageDesc{
        margin-bottom: 20px;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
    }
    .LandingHeadingTitle{
        height: 81px;
    }
    .LandingHeadingTitle br{
        display: block;
    }

}
.ProductsTable{
    width: 100%;
}
.ProductsTable .Limit{
    vertical-align: center;
    text-align: center;
}

.LandingTableSeparator{
    box-sizing: border-box;
    border-top: 1px solid #32C5FF;
}
.LandingTableFeaturePaddingTop{
    height: 20px;
}
.LandingTableFeaturePaddingBottom{
    height: 12px;
}
.LandingTableSubFeatureSeparator{
    height: 20px;
}
.LandingTableBoldSeparator{
    border-bottom: 5px solid #28B5F5;
}
.ProductsTable .LandingTableRow .Limit{
    width: 364px;
}
.ProductsTable .LandingTableRow .Limit_HK{
    text-align: center;
}
.HKG-template .ProductsTable .LandingTableRow .Limit{
    width: 182px;
    max-width: 182px;
}
.HKG-template .LimitHeader_HK.Limit{
    max-width: 182px;
    width: 182px;
}
.HKG-template .ProductsTable .LandingTableRow td.Value.LImit.LimitTwoEle{
    max-width: 182px;
    width: 182px;
}
.LandingTableRow .Icon{
    width: 103px;
}
.LandingTableRow .Icon svg{
    pointer-events: none;
}
.LandingTableRow .Arrow{
    text-align: center;
    width: 154px;
}
.LandingTableRow .Icon .HomeContentIcon{
    height: 60px;
    width: 60px;
    margin-left: 11px;
}
.LandingTableRow .Icon .BuildingIcon{
    height: 60px;
    width: 61px;
    margin-left: 10px;
}
.LandingTableRow .Icon .PersonalAccidentIcon{
    height: 65px;
    width: 43px;
    margin-left: 18px;
}
.LandingTableRow .Icon .LiabilityIcon{
    height: 59px;
    width: 58px;
    margin-left: 13px;
}
.LandingProductFeatureLabel2Format_HK{

}
.MobileHeader,
.MobileLandingTableRow{
    display: none;
}
@media screen and (max-width: 1024px){
    .DesktopHeader,
    .LandingTableRow{
        display: none;
    }
    .MobileHeader,
    .MobileLandingTableRow{
        display: table-row;
    }
    .LandingTableSubFeatureSeparator{
        height: 8px;
    }
    .LandingProductSubFeature .Item li::before {
        content: '•';
        display: table-cell;
        padding-right: 20px!important;
    }
    .MobileLandingTableRow .Icon{
        width: 53px;
    }
    .MobileLandingTableRow .Icon .HomeContentIcon{
        height: 40px;
        width: 40px;
        margin-left: 1px;
    }
    .MobileLandingTableRow .Icon .BuildingIcon{
        height: 41px;
        width: 41px;
        margin-left: 0px;
    }
    .MobileLandingTableRow .Icon .PersonalAccidentIcon{
        height: 42px;
        width: 29px;
        margin-left: 8px;
    }
    .MobileLandingTableRow .Icon .LiabilityIcon{
        height: 40px;
        width: 39px;
        margin-left: 2px;
    }
}
.ProductFeatureItemContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.FlagContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;
}
.FlagContainer_HK {
    margin-left: 55px;
}
.FlagContainer .Flag,
.FlagContainer .Flag svg{
    height: 122px;
    width: 109px;
}
.FlagContainer .Flag svg path{
    fill: #81D3F9;
}
.Flag{
    position: relative;
}
.FlagOccupancy{
    text-transform: uppercase;
    top: 40%;
    left: 50%;
    position: absolute;
    transform: translateY(-50%) translateX(-50%);
    color: #0A1F44;
    font-family: Inter-Bold, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
}
.LandingProductFeatureArrow{
    height: 10px;
    width: 16px;
}
.LandingProductFeatureArrow.Picked{
    transform: rotate(180deg);
}
.HeaderLabel{
    color: #009AE4;
    font-family: Inter-SemiBold, sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
}
.LandingProductSubFeature .Item{
    color: #282B3E;
    font-family: Inter, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 23px;
}
.LandingProductSubFeature .Item li{
    list-style: none;
    display: table-row;
}
.LandingProductSubFeature .Item li::before{
    content: '•';
    display: table-cell;
    padding-right: 8px;
}
.LandingProductSubFeature .Value{
    color: #000000;
    font-family: Inter-SemiBold, sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
}
.LandingTableRow.LandingProductFeature .Item{
    color: #000000;
    font-family: Inter-SemiBold, sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
}
.LandingTableRow.LandingProductFeature .Value{
    color: #000000;
    font-family: Inter-SemiBold, sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
}
.LandingTableRow.LandingProductSubFeature{

    margin-bottom: 20px;
}

.ProductsTable .PlanHeadButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.PlanText{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    text-align: center;
}
.PlanText .PlanText{
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
}
.PlanText .space{
    height: 8px;
}
.TableLineBreak{
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #32C5FF;
}
.BenefitItemContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: Inter-SemiBold, sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
}
.BenefitItemContainer svg{
    flex-shrink: 0;
    padding-right: 20px;
    /*max-width: 300px;*/
}
.ProductsTable .TableCol{
    font-family: Inter-SemiBold, sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    /*min-width: 150px;*/
    min-width: 168px;
}
.BenefitHighlightsText{
    color: #009AE4;
    font-family: Inter-SemiBold, sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
}
.BenefitHighlightsText:last-child{
    text-align: center;
}
.BenefitMaximum{
    text-align: center;
}
.ProductsTable .InsuredPersonTypes .Options{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

}
.ProductsTable .InsuredPersonTypes .Option{
    box-sizing: border-box;
    height: 61px;
    max-width: 200px;
    flex-grow: 1;
    border: 1px solid #4EC2F6;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 15.5px;
    cursor: pointer;
    /* padding-left: 12px;
    padding-right: 12px; */
}
.ProductsTable .InsuredPersonTypes .Option.Picked{
    box-sizing: border-box;
    background-color: #4EC2F6;
    border: 1px solid #4EC2F6;

}
.ProductsTable .InsuredPersonTypes .Option svg{
    /* margin-right: 24.3px; */
    margin-right: 5.3px;
    flex-shrink: 0;
}
.BenefitHighlightsLineBreak{
    border-bottom: 5px solid #2BB5F5;
}
.BenefitHeaderPadding{
    padding-bottom: 17px!important;
}
.explain{
    color: #000000;
    font-family: Inter, sans-serif;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: 20px;
}
.ProductFeatureRemark{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
}
.ProductFeatureRemarkBtn{

    margin: 0 12.5px!important;
}
@media (max-width: 1024px) {
    .ProductsTable{
        width: 100%;
    }
    .MobileLandingTableRow.LandingProductFeature .Item {
        color: #0A1F44;
        font-family: Inter-SemiBold, sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 27px;
    }
    .LandingProductSubFeature .Item>div{
        box-sizing: border-box;
        padding-left: 30px;
    }

    .ProductsTable .PlanHeadButton{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .ProductsTable .Limit{
        text-align: left;
    }
    .PlanText{
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        text-align: center;
    }
    .PlanText .PlanText{
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 28px;
    }
    .PlanText .space{
        height: 8px;
    }
    .TableLineBreak{
        box-sizing: border-box;
        height: 1px;
        width: 100%;
        border-bottom: 1px solid #32C5FF;
    }
    .BenefitItemContainer{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-family: Inter-SemiBold, sans-serif;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 27px;
    }
    .BenefitItemContainer svg{
        flex-shrink: 0;
        padding-right: 20px;
        /*max-width: 300px;*/
    }
    .ProductsTable .TableCol{
        font-family: Inter-SemiBold, sans-serif;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
        /*min-width: 150px;*/
        min-width: 168px;
    }
    .BenefitHighlightsText{
        color: #009AE4;
        font-family: Inter-SemiBold, sans-serif;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 25px;
    }
    .BenefitHighlightsText:last-child{
        text-align: center;
    }
    .BenefitMaximum{
        text-align: center;
    }
    .ProductsTable .InsuredPersonTypes .Options{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;

    }
    .ProductsTable .InsuredPersonTypes .Option{
        box-sizing: border-box;
        height: 61px;
        max-width: 235px;
        flex-grow: 1;
        border: 1px solid #4EC2F6;
        border-radius: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 15.5px;
        cursor: pointer;
        padding-left: 12px;
        padding-right: 12px;
    }
    .ProductsTable .InsuredPersonTypes .Option.Picked{
        box-sizing: border-box;
        background-color: #4EC2F6;
        border: 1px solid #4EC2F6;

    }
    .ProductsTable .InsuredPersonTypes .Option svg{
        margin-right: 24.3px;
        flex-shrink: 0;
    }
    .BenefitHighlightsLineBreak{
        border-bottom: 5px solid #2BB5F5;
    }
    .BenefitHeaderPadding{
        padding-bottom: 17px!important;
    }
    .explain{
        color: #000000;
        font-family: Inter, sans-serif;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 22px;
        margin-top: 20px;
    }
    .ProductFeatureRemark{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 18px;
    }
    .ProductFeatureRemarkBtn{

        margin: 0 12.5px!important;
    }
}
.MobileTableControlContainer{
    display: none;
}
.LandingMobileArrow{
    display: none;
}
.LandingMobilePlan{
    display: none;
}
.LandingMobileTableHeader{
    display: none;
}
@media (max-width: 1024px) {
    .LandingProductFeatures{
        padding-top: 12px;
    }
    .LandingProductFeatures .explain{
        padding-left: 22px;
        padding-right: 22px;
    }
    .ProductFeatureRemark{
        display: flex;
        flex-direction: column-reverse;
    }
    .ProductFeatureRemark>button{
        border-radius: 28px!important;
        width: 200px!important;
        height: 52px!important;
        margin-top: 7.5px!important;
        margin-bottom: 7.5px!important;
    }
    .LandingMobileTableHeader{
        display: table-row;
        color: #009AE4;
        font-family: Inter-Bold, sans-serif;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 25px;
    }
    .LandingDesktopTableHeader{
        display: none;
    }
    .BenefitItemContainer{

    }
    .LandingMobileArrow{
        display: table-cell;
        box-sizing: border-box;
        width: 31px;
        padding-right: 7px;

    }
    .LandingMobileArrow>div{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
    }
    .MobileTableControl{
        width: auto;
    }
    .MobileTableControlContainer{
        display: table-row;
    }
    .HeaderRow{
        display: none;
    }
    .ProductsTable .InsuredPersonTypes .Options {

        justify-content: space-between;
        align-items: center;
    }
    .ProductsTable .InsuredPersonTypes .Option {
        max-width: 160px;
        box-sizing: border-box!important;
        margin-right: 0px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 19px;
        padding-left: 10px;
        padding-right: 10px;

    }
    .ProductsTable .OccupancyTypes{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .ProductsTable .OccupancyTypes>.Title{
        color: #00A758;
        font-family: Arial,"Noto-Bold", sans-serif;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 0;
    }

    .ProductsTable .OccupancyTypes .Options .Option{
        box-sizing: border-box;
        height: 116px;
        width: 111px;
        border: 1px solid #FFFFFF;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        color: white;
        font-family: Arial,"Noto-Regular", sans-serif;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 18px;
        padding-bottom: 18px;

    }

    .ProductsTable .OccupancyTypes .Options .Option .Img{
        height: 39px;
        width: 47px;
    }

    .ProductsTable .OccupancyTypes .Options{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-left: 127px;
    }


    .ProductsTable .OccupancyTypes .Options .Option.Picked{
        height: 117px;
        width: 110px;
        border-radius: 20px;
        background-color: #28B5F5;

    }

    .BenefitHighlightsLineBreak{
        border-bottom: 3px solid #2BB5F5;
    }
    .MobileTableControl .Option svg{
        display: none;
    }
    .LandingPlans{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 23px;
        height: 110px;
    }
    .LandingPlan{
        position: relative;
        transition-duration: 0.3s;
    }
    .LandingPlan.Picked svg{
        height: 103px;
        width: 81px;
        transition-duration: 0.3s;
        fill: #28B5F5;
    }
    .LandingPlan.Picked .MobileLandingPlanBackground{
        fill: #28B5F5;
    }
    .LandingPlan.Picked .LandingPlanText{
        color: #FFF;
        opacity: 1;

    }
    .LandingPlan svg{
        height: 86px;
        width: 69px;
        fill: #E1E4E8;
    }
    .MobileLandingPlanBackground{
        fill: #E1E4E8;
    }
    .LandingPlanText{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        color: #0A1F44;
        opacity: 0.35;
        font-family: Inter-SemiBold, sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;
    }
    .ProductsTable{
        padding-left: 22px;
        padding-right: 22px;
        box-sizing: border-box;
    }
    .ProductsTable td.TableCol{
        display: none;
    }
    .LandingMobilePlan{
        display: block;
        font-family: Inter-SemiBold, sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
    }
    .LandingMobilePlan .TableCol{
        margin-top: 2px;
        font-family: Inter-SemiBold, sans-serif !important;
        font-size: 16px!important;
        font-weight: 600!important;
        letter-spacing: 0!important;
        line-height: 19px!important;
    }
    .BenefitItemContainer{
        padding-top: 20px;
        padding-bottom: 19.5px;
    }
    .PickedA tr span.TableCol:not(:nth-child(1)){
        display: none;
    }
    .PickedB tr span.TableCol:not(:nth-child(2)){
        display: none;
    }
    .PickedC tr span.TableCol:not(:nth-child(3)){
        display: none;
    }
    .PickedD tr span.TableCol:not(:nth-child(4)){
        display: none;
    }

    .LandingTableIcon{
        width: 67px;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .TableHighlightRowText{
        font-family: Inter-SemiBold, sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
    }
    .HighlightIcon{
        transform: scale(1.5);
    }
    .AccidentalDeathIcon{
        height: 37px;
        width: 41px;
    }
    .AccidentalMedicalIcon{
        height: 38px;
        width: 37px;
    }
    .TemporaryDisablementIcon{
        height: 39px;
        width: 34px;
    }
    .HospitalCashIcon{
        height: 42px;
        width: 41px;
    }
    .FractureBenefitIcon{
        height: 36px;
        width: 39px;
    }
    .MobilityAidIcon{
        height: 35px;
        width: 41px;
    }
    .PersonalEffectsIcon{
        height: 35px;
        /*width: 18px;*/
    }
    .BenefitItemContainer svg {
        flex-shrink: 0;
        padding-right: 00px;
        /* max-width: 300px; */
    }
    .TableHighlightRowText br{
        display: none;
    }
}
@media (max-width: 350px) {
    .ProductsTable {
        padding-left: 0px;
        padding-right: 0px;
        box-sizing: border-box;
    }
}
.OccupancyTypeLabel {
    height: 27px;
    width: 75px;
    color: #FFFFFF;
    font-family: Inter, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
}

.OccupancyTypeLabel.Picked  {
    height: 27px;
    width: 75px;
    color: #0A1F44;
    font-family: Inter, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
}

.OccupancyTypes{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.OccupancyTypes>.Title{
    color: #00A758;
    font-family: Arial,"Noto-Bold", sans-serif;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
}

.OccupancyTypes .Options .Option{
    box-sizing: border-box;
    height: 116px;
    width: 111px;
    border: 1px solid #FFFFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    color: white;
    font-family: Arial,"Noto-Regular", sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    padding-bottom: 18px;

}

.OccupancyTypes .Options .Option .Img{
    height: 39px;
    width: 47px;
}

.OccupancyTypes .Options{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 127px;
}


.OccupancyTypes .Options .Option.Picked{
    height: 117px;
    width: 110px;
    border-radius: 20px;
    background-color: #28B5F5;

}
:lang(zh) *{
    font-family: "Microsoft YaHei" !important;
}
:lang(zh) .chineseWordingHidden{
    display: none;
}
.PackageIcon.dmh{
    margin-bottom: 8px;
}
.LandingOtherPackage{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.LandingOtherPackage .PackageGroup{
    display: inline-flex;
    flex-direction: row;
    overflow-x: scroll;

    box-sizing: border-box;
    width: auto;
}
.OutlineIconButton .IconGroup{
    margin-right: 7px;
}
@media (max-width: 1024px) {
    .LandingOtherPackage .PackageGroup{
        flex-direction: row;
    }
    .LandingPlans{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 23px;
        height: 110px;
    }
    .LandingProductFeatures.HKG .Flag{
        position: relative;
        transition-duration: 0.3s;
        cursor: pointer;
        user-select: none;
        -webkit-tap-highlight-color: transparent;

    }
    .LandingProductFeatures.HKG .Flag.Picked svg path{

        transition-duration: 0.3s;
        fill: #28B5F5!important;
    }
    .LandingProductFeatures.HKG .Flag.Picked .MobileLandingPlanBackground{
        fill: #28B5F5;
    }
    .LandingProductFeatures.HKG .Flag.Picked .FlagOccupancy{
        color: #FFF;
        opacity: 1;
        font-size: 16px;
    }
    .LandingProductFeatures.HKG .Flag .FlagOccupancy{
        font-size: 14px;
    }
    .LandingProductFeatures.HKG .FlagContainer .Flag.Picked,
    .LandingProductFeatures.HKG .FlagContainer .Flag.Picked svg{
        height: 122px;
        width: 103px;

    }
    .LandingProductFeatures.HKG .FlagContainer{
        align-items: flex-start;
        height: 122px;
    }

    .LandingProductFeatures.HKG .FlagContainer .Flag{
        height: 104px;
        width: 88px;
    }
    .LandingProductFeatures.HKG .FlagContainer .Flag svg{
        height: 104px;
        width: 88px;
        fill: #E1E4E8!important;
        transition-duration: 0.3s;
    }
    .LandingProductFeatures.HKG .FlagContainer .Flag svg path{
        fill: #E1E4E8;
    }
    .LandingProductFeatures.HKG .FlagOccupancy{
        position: absolute;
        top: 47%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        color: #0A1F44;
        opacity: 0.35;
        font-family: Inter-SemiBold, sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;
        transition-duration: 0.3s;
        user-select: none;
    }
    .LandingProductFeatures.HKG .FlagContainer .Flag.Picked .FlagOccupancy{
        top: 45%;

    }
    .LandingProductFeatures.HKG .LandingProductSubFeature .Item>div{
        padding-left: 23px;
    }
    .LandingProductFeatures.HKG .LandingTableSubFeatureSeparator {
        height: 6px;
    }
    .LandingProductFeatures.HKG .HeaderLabel.Limit{
        color: #009AE4;
        font-family: Inter-Bold, sans-serif;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
    }
    .HKG-template .LandingHeadingTitle {
        margin-top: 0px;
    }
    .HKG-template .LandingTopRight>.Title {
        text-align: center;
    }
    .HKG-template .MobileLookingLanguage #languageSelector {
        margin-top: 30px;
    }
    .HKG-template .MobileRightHeader {
        padding-bottom: 30px;
    }
    .HKG-template .LookProduct span {
        height: 50px;
    }
    .HKG-template .LookProduct,
    .HKG-template .LookProduct.Picked {
        height: 150px;
    }
    .HKG-template .LandingFormRow,
    .HKG-template .LandingFormRow.Small {
        margin-right: 0;
        padding-left: 26px;
        padding-right: 26px;
    }
    .HKG-template .LandingFormRow .Title.Center{
        justify-content: center;
    }
    .HKG-template .LandingFormRow .input.Full{
        width: 100%;
        max-width: unset;
    }
    .HKG-template .LandingFormRow.Notice{
        margin-right: 0;
        padding-left: 20px;
        padding-right: 20px;
        width:90%;
    }
}
@media (max-width: 355px) {
    .HKG-template .LookProduct,
    .HKG-template .LookProduct.Picked {
        height: 133px;
    }

}

.DiscountCloseButton{
    display: none;
}
.LandingTopDiscountBanner{
    z-index: 1;
    position: absolute;
    bottom: 0;
}
@media (max-width: 1024px) {

    .LandingTop.discountBottom{
        padding-bottom: 0;
    }
    .LandingTopDiscountBanner{
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 5;
        /*width: 100vw;*/
        height: auto;
        padding-bottom: 42px;
    }
    .DiscountBannerContent {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;

    }
    .LandingTop .FinishLearnMoreBannerIcon {
        height: 23px;
        width: 35px;
        margin-bottom: 0px;
        margin-right: 16px;
    }
    .TermsAndConditionsButton{
        width: 100vw;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 16px;
    }
    .DiscountCloseButton{
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        justify-content: flex-end;
        margin-top: 7px;
    }
    .DiscountCloseButton>svg{
        fill: #fff;
        width: 35px;
        height: 35px;
        color: #fff;

    }
    .DiscountContent {
        color: #FFFFFF;
        font-family: Inter-SemiBold, sans-serif;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 21px;
        max-width: 80%;
    }
    .LandingTopDiscountBanner.Close{
        display: none;
    }
    .housingTypeBalloonTips{
        text-align: left;
    }
    .housingType .Key{
        align-self: center;
    }
    .housingType .input{
        width: 100%;
        max-width: unset;
    }
    .HKG-template .ProductBenefitsMobile {
        padding-bottom: 24px;
    }
    .HKG-template .ProductBenefitsMobile .Options{
        justify-content: center;
    }
    .HKG-template .ProductBenefitsMobile .Options > div{
        margin-left: auto;
        margin-right: auto;
    }
    .HKG-template .LandingProductFeatures.HKG .FlagContainer .Flag {
        margin-left: 6px;
        margin-right: 6px;
    }
}
.ProductBrochureButton,
.InstantQuoteButton {
    width: 200px;
}
.LandingOtherPackage .PackageGroup::-webkit-scrollbar {
    display: none;
}
.LandingProductTitle{
    margin-top: 30px;
}
@media (max-width: 1024px) {
    .LandingProductTitle{
        margin-top: 78px;
    }
}
.BiggerThan3000{
    color: #000000;
    font-family: Inter, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
}
.BiggerThan3000 ul{
    padding-left: 26px;
}
.BiggerThan3000 li{
    padding-left: 5px;
}
.BiggerThan3000 .ContactMethods {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #000000;
    font-family: Inter, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 32.5px;
}

.BiggerThan3000 .ContactMethods .VLine{
    height: 38px;
    width: 1px;
    border: 1px solid #4EC2F6;
    color: #4EC2F6;
    margin-left: 35px;
    margin-right: 35px;
}

.BiggerThan3000 .ContactMethods .ContactMethod{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.BiggerThan3000 .ContactMethods .ContactMethod.Phone{
    color: #282B3E;
    font-family: Inter-SemiBold, sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
    text-decoration: none!important;
}
.BiggerThan3000 .ContactMethods .ContactMethod .PhoneImg{
    height: 24px;
    width: 24px;
    margin-right: 12.99px;
}

.BiggerThan3000 .ContactMethods .ContactMethod.WhatsApp{
    color: #282B3E;
    font-family: Inter-SemiBold, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    text-decoration: none;
}
.BiggerThan3000 .ContactMethods .ContactMethod .WhatsAppImg{
    height: 35px;
    width: 31px;
    margin-right: 15px;
}
.BiggerThan3000 .ContactMethods .ContactMethod.Email{
    margin-right: 18px;
    color: #282B3E;
    font-family: Inter-SemiBold, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    text-decoration: none;
}
.BiggerThan3000 .ContactMethods .ContactMethod .EmailImg{
    height: 24px;
    width: 33px;
    margin-right: 17px;
}
.error-row{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.error-row img{
    margin-right: 12px;
    margin-top: -3px;
}
.LandingFormRow.NoMB{
    margin-bottom: 0;
}
#menu-grossFloorArea .MuiPaper-root.MuiMenu-paper::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
@media (max-width: 1024px) {
    .HKG-template .LandingProductTitle{
        width: 89%;
    }
    .BiggerThan3000 .ContactMethods {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .BiggerThan3000 .ContactMethods .VLine{
        border: none;
        height: 37px;
    }
    .TopErrorContainer svg.ErrorImg {
        margin-right: 25px;
        height: 43px;
        flex-shrink: 0;
    }

}
@media (max-width: 500px) {
    .ListItemText br{
        display: none;
    }
}
@media (max-width: 320px){
    div.LandingProductTitle {
        width: 93%;
    }
}
#mui-component-select-housingTypeVN> span::before{
    color: #fff;
}
#menu-housingTypeVN ul{
    padding-bottom: 0;
}
#menu-housingTypeVN ul li{
    background-color: #fafbfb;
}
#menu-housingTypeVN ul li:hover{
    background-color: #f1f2f4;
}
.homecontent_vn{
    height: 60px;
    width: 60px;
    margin-left: 11px;
}
.buildings_vn{
    height: 60px;
    width: 61px;
    margin-left: 10px;
}
.liability_vn{
    height: 59px;
    width: 58px;
    margin-left: 13px;
}
.Limit{
    width: 182px;
    max-width: 182px;
}
.MobileProductSubFeatureValue{
    padding-left: 32px;
}
@media screen and (max-width: 400px) {
    .MobileLandingTableRow .Arrow{
        width: 16px;
    }
    .balloontips{
        flex-shrink: 0;
    }
}
