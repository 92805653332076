.ModalBody {
    border-radius: 0px !important;
    /*padding: 42px 52px 16px 45px !important;*/
}

.ModalTop {
    margin-top: -48px !important;
    margin-left: -24px !important;
    height: 4px;
    width: 512px;
    background: linear-gradient(270deg, #68D88E 0%, #00D5BE 100%);
}

.ModalIcon {
    position: absolute;
    top: 55px;
    left: 214px;
    height: auto;
    width: auto;
}

.ModalTitle {
    text-align: left;
    color: #003DA5;
    font-family: Stag-Medium, sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 36px;
}

.ModalContent {
    font-family: Arial,Noto, sans-serif;
}

.large{
    width: 1000px;
    padding: 42px 52px 30px 45px;
}
.large .ModalContent{
    padding-top: 42px;
}

.normal{
    width: 640px;
    padding: 42px 45px 53.5px 37px;
}
.normal .ModalContent{
    padding-top: 33px;
}

.small{
    width: 511px;
    padding: 43px 42px 47px 31px;

}
.small .ModalContent{
    padding-top: 45px;
}
@media (max-width: 1024px) {
    .ModalTitle {
        color: #003DA5;
        font-family: Stag-Medium, sans-serif;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 25px;
    }

    .ModalContent {
        font-family: Arial,Noto, sans-serif;
    }

    .large{
        width: 1000px;
        padding: 21px 14px 15px 14px;
    }
    .large .ModalContent{
        padding-top: 42px;
    }

    /*.normal{*/
    /*    width: 640px;*/
    /*    padding: 42px 45px 53.5px 37px;*/
    /*}*/
    /*.normal .ModalContent{*/
    /*    padding-top: 33px;*/
    /*}*/

    .small{
        width: 511px;
        padding: 46px 18px 30px 41px;

    }
    .small .ModalContent{
        padding-top: 45px;
    }
}
