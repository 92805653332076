.Title.title-group{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

}
.Title.title-group .Icon{
    flex-shrink: 0;
}




.QuoteHeaderTemplateBodyContainer{
    width: 1440px;
    box-sizing: border-box;
    margin: 0 auto;
}
.QuoteTemplateBodyContainer{
    width: 1440px;
    box-sizing: border-box;
    /*padding-left: 62px;*/
    /*padding-right: 56px;*/
    margin: 0 auto;
}

.QuoteNumberOfEmployees {
    height: 56px;
    width: 77px;
    background-color: #F1F2F4;
    line-height: 56px;
    margin-left: 17px;
    margin-right: 17px;
}

svg{
    cursor: pointer;
}

.AdditionalCoverageModalContainer .ModalTitle {
    text-align: left;
    color: #003DA5;
    font-family: Stag-Medium, sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 36px;
    padding-bottom: 25px;
}

.AdditionalCoverageModalContainer .ModalContent {

}

.AdditionalCoverageModalContainer .ModalContent .MainContent{

}

.AdditionalCoverageModalContainer .ModalContent .OtherContent {
    color: #009AE4;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    padding-right: 10px;
    text-align: center;
    padding-top: 25px;
}

.AdditionalCoverageModalContainer .ModalContent .OtherContent .PremiumTitle{
    padding-right: 10px;
}

.AdditionalCoverageModalContainer .ModalContent .OtherContent .PremiumContent{
    color: #0A1F44;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
}
.mobileSlider{
    display: none;
}
.slider{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
#SliderMobileToolTips{
    display: none;
}
@media (max-width: 1024px) {
    #SliderMobileToolTips{
        display: block;
    }
    #SliderDesktopToolTips{
        display: none;
    }
    .AdditionalCoverageModalContainer{
        padding-left: 20px!important;
        padding-right: 20px!important;
        box-sizing: border-box;
    }
    .mobileSlider{
        display: block;
    }

    .slider{
        display: none;
    }
}

@media (max-width: 1440px) {
    /*.QuoteTemplateBodyContainer{*/
    /*    width: 100%;*/
    /*    padding-left: 0px;*/
    /*    padding-right: 0px;*/
    /*}*/
    /*.QuoteHeaderTemplateBodyContainer{*/
    /*    width: 100vw;*/
    /*    box-sizing: border-box;*/
    /*    margin: 0 auto;*/
    /*}*/

}
@media (max-width: 768px) {
    .QuoteTemplateBodyContainer{
        width: 100vw;
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
    .TableHeaderGroup {
        z-index: 1;
        top: 56px!important;
    }
}
/*.QuoteTemplateBodyContainer{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    width: 100vw;*/
/*    flex-wrap: nowrap;*/
/*    box-sizing: border-box;*/
/*}*/
/*.QuoteWrapper{*/
/*    width: 1440px;*/
/*    box-sizing: border-box;*/
/*    padding-left: 62px;*/
/*    padding-right: 56px;*/

/*}*/
/*@media (max-width: 1440px) {*/
/*    .QuoteTemplateBodyContainer{*/
/*        padding-left: 36px;*/
/*        padding-right: 27px;*/
/*    }*/
/*}*/
/*.QuoteGlobalTop{*/
/*    width: 1440px;*/
/*}*/
/*@media (max-width: 1440px) {*/
/*    .QuoteGlobalTop{*/
/*        width: 100vw;*/
/*    }*/
/*}*/
/*@media (max-width: 1024px) {*/
/*    .QuoteGlobalTop{*/
/*        width: 100vw;*/
/*    }*/
/*}*/
/*@media (max-width: 768px) {*/
/*    .QuoteGlobalTop{*/
/*        width: 100vw;*/
/*    }*/
/*}*/
.TitleDesc{
    background-color: #282B3E;
    color: white;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
}
.TitleDesc>td:first-child{
    padding-top: 16px;
    padding-left: 18px;
    padding-bottom: 12px;
}
.TitleDesc>td:last-child{
    text-align: center;
}
.QuoteMobileDiscount{
    display: none;
}
.QuoteTableHeader{
    display: flex;
    flex-direction: row;
    top:0;
    /*margin-top: 25px;*/
}

.QuoteTableHeader .Arrow {
    width: 54px;
    text-align: center;
}
.TableHeaderGroup {
    position: sticky;
    background-color: #fafafa;
    /*z-index: 3;*/
    /*margin-top:-9px;*/
    z-index: 1;
    top: 85px;
    padding-top: 6px;
}
.TableHeaderGroup.FixTop {
    width: calc(100vw - 63px);
    margin-right: 29px;
    position: fixed;
    background-color: #fafafa;
    /*z-index: 3;*/
    margin-top:-9px;
}

.TableBody{
    /*max-height: 600px;*/
    /*height: 680px;*/
    /*overflow-y: scroll;*/
    position: relative;
}
.TableBody.FixTop {
    padding-top: 286px;
    /*height:900px;*/
    /*background-color: #fafafa;*/
}
@media (max-width: 1024px) {
    .TableHeaderGroup.FixTop {
        width: 100%;
        margin-right: 29px;
        position: fixed;
        background-color: #fafafa;
        z-index: 3;
        margin-top:-9px;
    }
    .QuoteTemplateBodyContainer{
        padding-left: 0px;
        padding-right: 0px;
    }
    .TableHeaderGroup {
        z-index: 1;
        top: 56px!important;
    }
}
.TableBody::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.TableBody{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.QuoteMobileBottomSpace{
    display: none;
}

/*Top Left Group start*/
.TopLeft {
    display: flex;
    flex-direction: column;
    width: 366px;
    height: 200px;
    align-items: flex-start;
    color: #282B3E;
    font-family: Inter-Bold, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    flex-grow: 1;
}
.TopLeft .HLine {
    border-bottom: 1px dashed #8E90A2;
    width: 342px;
    max-height: 1px;
    flex-shrink: 0;
}
.TopLeft .PromoIcon {
    height: 26px;
    overflow: visible;
    margin-right: 16px;
}
.TopLeft .NavyFamily {
    height: 33px;
    overflow: visible;
    margin-right: 19px;
}
.TopLeft > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto 0;
}
.WhiteTick {
    fill: #FFFFFF;
}
@media (max-width: 1440px) {
    .TopLeft {
        min-width: 199px;
        flex-grow: 1;
        font-size: 14px;
        line-height: 16px;
    }
    .TopLeft .Discount {
        min-width: 125px;
        flex-grow: 1;
    }
    .TopLeft .HLine {
        min-width: 179px;
        flex-grow: 1;
        margin-right: 12px;
    }
}
@media (max-width: 1024px) {
    .TopLeft {
        display: none;
    }
}

/*Top Left Group end*/

/*Top Plans Group start*/
/*.Plans{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*}*/
.Plan {
    border: 1px solid #EDEDED;
    border-bottom: 1px solid transparent;
    /*outline: 1px solid transparent;*/
    box-sizing: border-box;
    background: #ffffff;
    width: 440px;
    height: 200px;
    flex-grow: 1;
    max-width: 440px;
    font-family: Inter-Bold, sans-serif;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-right: 5px;
    border-radius: 6px 6px 0 0;
    cursor: pointer;
}
.Plan .PlanName {
    margin-top: 30px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
    color: #009AE4;
    margin-bottom: 23px;
}
.Plan .Discount {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 21px;
    color: #000000;
    text-decoration: line-through;
    margin-bottom: 4px;
}
.Plan .PriceGroup {
    font-family: Inter-Bold, sans-serif;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 21px;
    font-weight: bold;
    color: #000000;
}
.Plan .PriceGroup .Price {
    font-size: 25px;
    line-height: 37px;
}
.Plan .MinPrem {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    color: #ff4c4c;
    /*min-height: 16px;*/
}
.Plan .MinPrem.Hide {
    display: none;
}

.Plan .PriceGroup .Cent {
    font-size: 25px;
    line-height: 29px;
}
.Plan .CircleTick {
    width: 46px;
    height: 46px;
    overflow: hidden;
    margin-top: auto;
    margin-bottom: auto;
    transition-duration: .1s;
    display: none;
}
.Plan .CircleTick .Border, .Plan .CircleTick .Tick {
    fill: transparent;
}
.Plan .MinPrem.Hide,
.Plan.Picked .MinPrem.Hide{
    color: transparent;
}
.Plan:hover {
    border: 1px solid #009AE4;
    /*outline: 1px solid #009AE4;*/
}
.Plan:hover .CircleTick .Tick {
    fill: #EDEDED;
}
.Plan:hover .CircleTick .Border {
    stroke: #EDEDED;
}
.Plan.Picked {
    border: 1px solid #009AE4;
    /*outline: 2px solid transparent;*/
    background: #009AE4;
}
.Plan.Picked .MinPrem {
    color: #FFF;
}

.Plan.Picked .PlanName, .Plan.Picked .Discount, .Plan.Picked .PriceGroup {
    color: #FFFFFF;
}
.Plan.Picked .CircleTick .Tick {
    fill: #FFFFFF;
}
.Plan.Picked .CircleTick .Border {
    stroke: #FFFFFF;
}
@media (max-width: 1440px) {
    .Plan {
        min-width: 440px;
        flex-grow: 1;
        margin-right: 4px;
    }
    .Plan .PlanName{
        margin-top: 25px;
        margin-bottom: 18px;
    }
    .Plan .PriceGroup .Price {
        font-size: 28px;
        line-height: 32px;
    }
    .Plan .CircleTick {
        width: 45px;
        height: 45px;
    }

}

/*Top Plans Group end*/

/*Plans Heading start*/
.TableHeading,
.OptionalHeading {
    width: 1350px;
    border-radius: 6px;
    color: #FFFFFF;
    font-family: Inter-Bold, sans-serif;
    font-weight: bold;
    letter-spacing: 0;
}
@media (max-width: 1440px) {
    .TableHeading,
    .OptionalHeading {
        width: 100%;
    }
}
@media (max-width: 1024px) {
    .TableHeading,
    .OptionalHeading {
        width: 100%;
    }
}
@media (max-width: 1024px) {
    .TableHeading {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 14px;
    }
    .TableHeading .Item {
        border-left: 20px solid transparent;
        box-sizing: border-box;
        min-width: unset!important;
        width: auto!important;
    }
    .TableHeading .Value {
        flex-grow: 2;
        text-align: center;
        min-width: unset!important;
        min-width: 0!important;
        width: auto;
    }
}
@media (max-width: 1024px) {
    .OptionalHeading {
        font-size: 14px!important;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px!important;
        padding: 17px 0 12px 0!important;
    }
}

.TableHeading {
    font-size: 16px;
    line-height: 18px;
    padding-top: 16px;
    padding-bottom: 12.48px;
    background-color: #282B3E;
    display: flex;
    flex-direction: row;
}
.TableHeading .Item {
    border-left: 19px solid transparent;
    box-sizing: border-box;
    flex-grow: 1;
}
.TableHeading .Value {
    width: 440px;
    flex-grow: 1;
    max-width: 440px;
    align-items: center;
    text-align: center;
    margin-right: 5px;
}
.HKG-template .TableHeading .Value{
    width: 660px;
    max-width: 660px;
}
.TableHeading .Arrow{
    width: 54px;
    text-align: center;
}
@media (max-width: 1440px) {
    .TableHeading .Item {
        border-left: 18px solid transparent;
        box-sizing: border-box;
        width: 199px;
    }
    .TableHeading .Item {
        min-width: 179px;
        flex-grow: 1;
    }
    .TableHeading .Value {
        /*min-width: 797px;*/
        flex-grow: 4;
    }
    .TableHeading .Arrow{
        max-width: 34px;
        width: 34px;
    }
    .HKG-template .TableHeading .Arrow{
        max-width: 54px;
        width: 54px;
        text-align: center;
    }
}

@media (max-width: 1024px) {
    .TableHeading .Arrow {
        display: none;
    }
    .Plan {
        min-height: 119px;
        height: auto;
        min-width: 179px;
        flex-grow: 1;
        margin-right: 4px;
        opacity: 0.1;
    }
    .Plan.Picked {
        position: relative;
        opacity: 1;
    }
    .Plan.Picked::before {
        content: ' ';
        background-size: 12px 18px;
        background-repeat: no-repeat;
        background-image: url("../../assets/mobilePlanArrow.png");
        height: 18px;
        width: 12px;
        position: absolute;
        left: -30px;
        top: 49px;
        pointer-events:none;
    }
    .Plan.Picked:nth-child(2)::before{
        display: none!important;
    }
    .QuoteTableHeader .Arrow{
        display: none;
    }
    .Plan.Picked:not(:nth-last-child(2))::after {
        content: ' ';
        background-size: 12px 18px;
        background-repeat: no-repeat;
        height: 18px;
        width: 12px;
        background-image: url("../../assets/mobilePlanArrow.png");
        position: absolute;
        z-index: 10;
        transform: rotate(180deg);
        right: -30px;
        top: 49px;
        pointer-events:none;
    }
    .Plan .PlanName {
        margin-top: 17px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 21px;
        color: #009AE4;
        margin-bottom: 10px;
    }
    .Plan .Discount {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        color: #000000;
        text-decoration: line-through;
        margin-bottom: 4px;
    }
    .Plan .PriceGroup {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        font-weight: bold;
        color: #000000;
    }
    .Plan .PriceGroup .Price {
        font-size: 28px;
        line-height: 32px;
    }
    .Plan .PriceGroup .Cent {
        font-size: 14px;
        line-height: 16px;
    }
    .Plan .CircleTick {
        display: none;
    }
    .Plan .MinPrem {
        margin-top: 0px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
        color: #ff4c4c;
        margin-bottom: 0px;
        text-align: center;
        display: inline-block;
        margin: 0 auto;
    }
}


.OptionalHeading {
    font-size: 18px;
    line-height: 21px;
    padding: 19px 0 20px;
    border-left: 18px solid transparent;
    box-sizing: border-box;
    background-color: #27236E;
}

/*Plans Heading end*/

/*Plan Item Row start*/
.OptionalCover, .PlanCover {
    width: 1350px;
    min-height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #282B3E;
    font-family: Inter, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 23px;
    border-radius: 6px;
}

.OptionalCover, .PlanCover.SubTop {
    border-bottom-left-radius:0;
    border-bottom-right-radius:0;
}

.OptionalCover, .PlanCover.SubMiddle {
    border-radius: 0;
}

.OptionalCover, .PlanCover.SubBottom {
    border-top-left-radius:0;
    border-top-right-radius:0;
}
.PlanCover.Even{
    border-radius: 0px;
}
.PlanCoverGroup .PlanCover:first-child {
    border-top-left-radius:6px;
    border-top-right-radius:6px;
}
.PlanCoverGroup .PlanCover:last-child {
    border-bottom-left-radius:6px;
    border-bottom-right-radius:6px;
}
.OptionalCover.Odd,
.PlanCover.Odd {
    background-color: #fafafa;
}
.OptionalCover.Odd .Value.Picked,
.PlanCover.Odd .Value.Picked,
.OptionalCover.Even .Value.Picked,
.PlanCover.Even .Value.Picked {
    flex-shrink: 0;
    background-color: #009AE4;
}
.OptionalCover.Even,
.PlanCover.Even {
    background-color: #ededed;
}

.OptionalCover.Even .Value.Picked,
.PlanCover.Even .Value.Picked {
    background-color: #0087D0;
}
.PlanCover.Odd .Title .BulletPoint,
.PlanCover.Even .Title .BulletPoint{
    display: none;
}

.PlanCover.Odd.Sub .Title,
.PlanCover.Even.Sub .Title{
    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-items: flex-start;*/
    /*white-space:pre-wrap;*/
    /*word-break:break-all;*/
}
.divList{
    list-style: none;
    display: table-row;
}
.divList::before{
    content: '•';
    display: table-cell;
    padding-right: 8px;
}

.PlanCover.Odd.Sub .Title .BulletPoint,
.PlanCover.Even.Sub .Title .BulletPoint{
    width: 3px;
    padding-right: 18px;
    /*background-color: red;*/
    display: inline-block;
}

.PlanCover .Title .Icon {
  padding-left:10px;
}

.PlanCover .Title.ToolTip .pointerDiv{
    top: 60px;
    left: -40px;
    position: absolute;
    border-top: 10px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 20px solid #003DA5;
    border-right: 15px solid transparent;
}

.PlanCover .Title.ToolTip .contentDiv {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 18px;
    color: #FFFFFF;
    text-align: left;
    box-sizing: border-box;
    z-index: 5;
    background:  #003DA5;
    width: 337px;
    height: auto;
    top: 85px;
    left: -180px;
    position: absolute;
    min-width: 300px;
    margin-left: 0px;
    padding: 10px 10px 10px 20px;
    border-bottom: 5px solid #003DA5;
}

.OptionalCover .Title .Icon {
    padding-left:10px;
}

.OptionalCover .Title.ToolTip .pointerDiv{
    top: 60px;
    left: -40px;
    position: absolute;
    border-top: 10px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 20px solid #003DA5;
    border-right: 15px solid transparent;
}

.OptionalCover .Title.ToolTip .contentDiv {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 18px;
    color: #FFFFFF;
    text-align: left;

    background:  #003DA5;
    width: 337px;
    height: 98px;
    top: 85px;
    left: -200px;
    position: absolute;
    min-width: 300px;
    margin-left: 0px;
    padding: 10px 10px 10px 20px;
    border-bottom: 5px solid #003DA5;
}


.OptionalCover .Title,
.PlanCover .Title {
    /*width: 366px;*/
    padding-left: 26px;
    box-sizing: border-box;
    flex-grow: 1;
    /*overflow-wrap: break-word;*/
    /*white-space:pre-wrap;*/
    /*word-break:break-all;*/
}

.OptionalCover .Title,
.PlanCover .Title.Bold {
    color: #282B3E;
    font-family: Inter-Bold, sans-serif;
    font-weight: bold;
    font-size: 16px;
}

.OptionalCover .Value,
.PlanCover .Value {
    width: 223px;
    min-height: 65px;
    align-self: stretch;
    margin-right: 5px;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    max-width: 440px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.BenefitListColumn .Value{
    flex-direction: column;
    padding-top: 12px;
    padding-bottom: 12px;
}
.RightGroup{
    display: flex;
    flex-grow: 1;
    max-width: 440px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
}
.RightGroup .plan{
    flex-grow: 1;
}
.RightGroup .PlanCode{
    color: #702F8A;
    font-family: Inter-Bold, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-bottom: 9px;
}
.RightGroup .PlanName{
    text-align: center;
}
.RightGroup .VLine{
    height: 152px;
}
.PlanCover .Value.Combined {
    width: 880px;
}
.OptionalCover .Value.Picked,
.PlanCover .Value.Picked {
    color: #FFFFFF;
    font-weight: bold;
}
.OptionalCover .Arrow,
.PlanCover .Arrow {
    width: 54px;
    text-align: center;
}
.OptionalCover .Arrow img,
.PlanCover .Arrow img {
    height: 12px;
    cursor: pointer;
}
.OptionalCover .Arrow img.Up,
.PlanCover .Arrow img.Up {
    transform: rotate(180deg);
}

.OptionalCover .Title {
    color: #702F8A;
    font-family: Inter-Bold, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
}
@media (max-width: 1440px) {
    .OptionalCover,
    .PlanCover {
        width: 100%;
    }
    .OptionalCover .Title,
    .PlanCover .Title {
        min-width: 201px;
        flex-grow: 1;
        padding-left: 16px;
        /*white-space:pre-wrap;*/
        /*word-break:break-all;*/
        /*overflow-x: hidden;*/
    }
    .PlanCover .Title{
        max-width: calc(100% - 440px - 37px);
        box-sizing: border-box;
    }
    .divList{
        white-space: pre-wrap;
        /*word-break: break-all;*/
    }
    .OptionalCover .Value,
    .PlanCover .Value {
        min-width: 179px;
        flex-grow: 1;
        margin-right: 4px;
    }
    .PlanCover .Value.Combined {
        min-width: 732px;
        flex-grow: 1;
    }
    .OptionalCover .Arrow,
    .PlanCover .Arrow {
        width: 28px;
        text-align: right;
    }
    .OptionalCover .Arrow,
    .PlanCover .Arrow {
        padding-right: 5.5px;
    }


}
@media (max-width: 1024px) {
    .OptionalCover,
    .PlanCover {
        width: 100%;
        height: auto;
        min-height: 78px;
    }
    .OptionalCover.Odd .Value.Picked,
    .PlanCover.Odd .Value.Picked,
    .OptionalCover.Even .Value.Picked,
    .PlanCover.Even .Value.Picked {
        flex-shrink: 1;
    }
    .OptionalCover
    {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        /*min-height: 55px;*/
    }
    .OptionalCover .Title .balloontips{
        margin-left: 8px!important;
    }
    .OptionalCover .Title{
        margin-top: 8px!important;
    }
    .OptionalCover .Title,
    .OptionalCover .Value.Picked
    {
        width: 100%;
        text-align: left;
        padding-left: 20px;
        box-sizing: border-box;
        flex-direction: row!important;
        align-items: center!important;
        justify-content: flex-start!important;
        min-height: 0;
        /*min-height: 55px;*/
    }
    .OptionalCover.Sub,
    .PlanCover.Sub{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        min-height: 55px;
    }
    .OptionalCover.Sub:last-child,
    .PlanCover.Sub:last-child{
        padding-bottom: 15px;
    }

    .OptionalCover.Sub .Value.Picked,
    .PlanCover.Sub .Value.Picked,
    .OptionalCover.Sub .Arrow,
    .PlanCover.Sub .Arrow {
        /*display: none!important;*/
    }
    .OptionalCover.Sub .Value.Picked,
    .PlanCover.Sub .Value.Picked{
        padding-left: 28px;
        box-sizing: border-box;
        width: 100%;
        text-align: left;
        /*margin-top: -15px;*/
        min-height: 0!important;
        justify-content: flex-start!important;
        align-items: flex-start;
        flex-grow: 0!important;
        /*align-items: flex-start!important;*/

    }
    .OptionalCover.Sub .Title,
    .PlanCover.Sub .Title{
        margin-bottom: 0!important;
        flex-grow: 0!important;

    }

    .OptionalCover.Sub .Title,
    .PlanCover.Sub .Title,
    .OptionalCover.Sub .Title .divList,
    .PlanCover.Sub .Title .divList {
        max-width: none;
        width: 100% ;
        flex-grow: 0!important;
    }
    .OptionalCover .Title,
    .PlanCover .Title {
        min-width: 100px;
        flex-grow: 1;
        padding-left: 20px;
        font-size: 14px!important;
    }
    .OptionalCover .Value,
    .PlanCover .Value {
        min-width: 140px;
        flex-grow: 1;
        margin-right: 4px;
        display: none;
    }

    .PlanCover .Value.Combined,
    .OptionalCover .Value.Combined,
    .OptionalCover .Value.Picked,
    .PlanCover .Value.Picked {
        display: flex!important;
        background-color: transparent !important;
        color: #009AE4;
    }
    .PlanCover .Value.Combined,
    .OptionalCover .Value.Combined{
        min-width: unset;
        width: 223px;
    }
    .OptionalCover .Arrow,
    .PlanCover .Arrow {
        width: 31px !important;
        min-width: 31px;
        flex-shrink: 0;
        text-align: left !important;
    }
    .OptionalCover .Arrow img,
    .PlanCover .Arrow img {
        margin-right: 6px;
    }

    .PlanCover.Odd.Sub .Title,
    .PlanCover.Even.Sub .Title{
        margin-top: 12px;
        margin-bottom: 12px;
        padding-left: 12px;

    }
    .PlanCover.Odd.Sub .Title .BulletPoint, .PlanCover.Even.Sub .Title .BulletPoint {
        width: 3px;
        padding-right: 12px;
        /* background-color: red; */
        display: inline-block;
    }
    .slide-in-left {
        -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation-iteration-count: 1;
    }
    .slide-in-right {
        -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation-iteration-count: 1;
    }
    /* ----------------------------------------------
 * Generated by Animista on 2020-7-29 18:22:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

    /**
     * ----------------------------------------
     * animation slide-in-left
     * ----------------------------------------
     */
    @-webkit-keyframes slide-in-left {
        0% {
            -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
            opacity: 0;
        }
        80%{
            opacity: 0.1;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }
    @keyframes slide-in-left {
        0% {
            -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
            opacity: 0;
        }
        80%{
            opacity: 0.1;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }

    /* ----------------------------------------------
 * Generated by Animista on 2020-7-29 18:12:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

    /**
     * ----------------------------------------
     * animation slide-in-right
     * ----------------------------------------
     */
    @-webkit-keyframes slide-in-right {
        0% {
            -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }
    @keyframes slide-in-right {
        0% {
            -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }
}




.AddOn {
    width: 1350px;
    min-height: 88px;
    display: flex;
    font-family: Inter, sans-serif;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid #EDEDED;
    border-radius: 6px;
    background-color: #FFFFFF;
    margin-bottom: 4px;
}
.AddOn .InsuredPersonBox {
    width: 366px;
    padding-left: 36px;
    box-sizing: border-box;
    overflow-wrap: break-word;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
}
.AddOn .InsuredPersonBox .Icon {
    height: 30px;
    margin-right: 20px;
    overflow: visible;
}
.AddOn .InsuredPersonBox .AgeGroup {
    color: #282B3E;
    font-family: Inter-Bold, sans-serif;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
}
.AddOn .InsuredPersonBox .Job {
    color: #282B3E;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
}
.AddOn .Value {
    /*width: 223px;*/
    min-height: 88px;
    align-self: stretch;
    margin-right: 5px;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    color: #282B3E;
    flex-grow: 1;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
}
.AddOn .Value.Picked {
    opacity: 1;
    font-size: 22px;
    line-height: 31px;
}
.AddOn .Value.Picked .CircleTick {
    margin-right: 12px;
}
.AddOn .Value.Picked .CircleTick .Tick {
    fill: transparent;
}
.AddOn .Value.Picked .CircleTick .Border {
    stroke: #8E90A2;
    opacity: 0.3;
}
.AddOn .Value.Picked .CircleTick.Checked .Tick {
    fill: #702F8A;
    opacity: 1;
}
.AddOn .Value.Picked .CircleTick.Checked .Border {
    stroke: #702F8A;
    opacity: 1;
}
.AddOn .Arrow {
    width: 58px;
    text-align: center;
}
.AddOn .NotApplicableValue {
    flex-grow: 2;
    text-align: center;
    min-height: 88px;
    line-height: 88px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}
.AddOn .MobileCheckbox{
    display: none;
}
@media (max-width: 1440px) {
    .AddOn {
        width: 100%;
        padding-top: 12px;
    }
    .AddOn .InsuredPersonBox {
        min-width: 201px;
        flex-grow: 1;
        padding-left: 26px;
    }
    .AddOn .InsuredPersonBox .CircleTick {
        margin-right: 12px;
        width: auto;
    }
    .AddOn .InsuredPersonBox .CircleTick .Tick {
        fill: transparent;
    }
    .AddOn .InsuredPersonBox .CircleTick .Border {
        stroke: #8E90A2;
        opacity: 0.3;
    }
    .AddOn .InsuredPersonBox .CircleTick.Checked .Tick {
        fill: #702F8A;
        opacity: 1;
    }
    .AddOn .InsuredPersonBox .CircleTick.Checked .Border {
        stroke: #702F8A;
        opacity: 1;
    }
    .AddOn .Value {
        min-width: 179px;
        flex-grow: 1;
        margin-right: 4px;
    }
    .AddOn .Arrow {
        width: 33px;
        text-align: right;
    }
}

@media (max-width: 1024px) {
    .AddOn {
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        flex-direction: column;
    }
    .AddOn .InsuredPersonBox {
        min-width: unset;
        width: 100%;
        flex-grow: 1;
        padding-left: 11px;
        font-size: 14px;
        line-height: 16px;
        overflow-wrap: break-word;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .AddOn .RightGroup{
        flex-direction: column;
        width: 100% !important;
        max-width: none;
    }
    .AddOn .RightGroup .VLine{
        width: 100%;
        height: 1px;
        border-left: none;
        border-top: 1px solid #4EC2F6;
        margin-top: 11.5px;
        margin-bottom: 11.5px;
    }
    .UnspecificValuesOptionalCover .Value.Picked.WiderBox:not(.MobileCheckbox){
        display: none!important;
    }
    .AddOn .MobileCheckbox.Value.Picked{
        display: inline-flex;
        flex-direction: row;
        min-width: 0!important;
        width: auto!important;
        flex-grow: 0!important;
        min-height: 0!important;
        font-family: Inter-Regular , sans-serif !important;
    }
    .AddOn .RightGroup {

    }
    .AddOn .plan{
        width: 100%;
    }
    .AddOn .plan .MobilePlanNameCover{
        display: flex;
        flex-direction: column!important;
        align-items: flex-start!important;

    }
    .AddOn .InsuredPersonBox .Icon {
        margin-right: 7px;
        overflow: visible;
    }
    .AddOn .RightGroup .PlanName {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .AddOn .Value {
        min-width: unset;
        width: 100%;
        flex-grow: 5;
        margin-right: 0px;
        display: none;
        font-size: 18px;
        line-height: 21px;
        margin-left: 0px;
    }
    .AddOn .Value .CircleTick {
        margin-right: 12px;
    }
    .AddOn .Value svg.CircleTick {
        width: 30px;
        height: 30px;
        flex-shrink: 0;
    }
    .AddOn .Value .CircleTick .Tick {
        fill: transparent;
    }
    .AddOn .Value .CircleTick .Border {
        stroke: #8E90A2;
        opacity: 0.3;
    }
    .AddOn .Value .CircleTick.Checked .Tick {
        fill: #702F8A;
        opacity: 1;
    }
    .AddOn .Value .CircleTick.Checked .Border {
        stroke: #702F8A;
        opacity: 1;
    }
    .AddOn .Value .CircleTick.Checked ~ span {
        font-weight: bold;
    }
    .AddOn .Value.Picked {
        display: flex;
        background-color: transparent !important;
        font-size: 18px;
        line-height: 21px;
    }
    .AddOn .Arrow {
        width: 49px;
        text-align: left;
        flex-shrink: 0;
        display: none;
    }
    .AddOn .NotApplicableValue {
        font-size: 12px;
        width: 40%!important;
        letter-spacing: 0;
        line-height: 14px;
        flex-grow: 3;
    }
}

.OptionalCover1Remark,
.OptionalCover2Remark{
    color: #282B3E;
    font-family: Inter, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
}
.OptionalCover1Remark ul,
.OptionalCover2Remark ul{
    padding: 0 0 0 36px;
}

.OptionalCover1Remark li,
.OptionalCover2Remark li{
    display: table-row;

}
.OptionalCover1Remark li::before,
.OptionalCover2Remark li::before{
    content: '•';
    display: table-cell;
    padding-right: 15px;
}
.BottomRemarkAndControl{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 31px;
}

.BottomActionAndControl{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 48px;
    margin-top: 32px;
}

.BottomRemarkAndControl .ContactAgentRemark{
    color: #282B3E;
    font-family: Inter-Bold, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 32px;
}
.BottomRemarkAndControl .ContactAgentRemark .Link{
    color: #009AE4;
    cursor: pointer;
}
.BottomRemarkAndControl .ContactAgentRemark .Link:hover{
    text-decoration: underline;
}
.BottomRemarkAndControl .RemarkHeading .Img{
    height: 34.91px;
    width: 34.91px;
    margin-right: 16.04px;
    cursor: pointer;
}

.RemarkHeading.RHspace{
    padding-bottom: 79px;
}
.RemarkBody ul{
    list-style: none;
}
.RemarkBody ul ol{
    padding-inline-start: 0;
}
.RemarkBody{
    /*margin-top: 5px;*/
    color: #282B3E;
    font-family: Inter, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    /*padding-bottom: 60px;*/
}
.RemarkBody .link{
    color: #009AE4;
    cursor: pointer;
}
.FullCoverage{
    color: #282B3E;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    cursor: pointer;
    margin: 15px 0;
}
.FullCoverage>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.FullCoverage .Img{
    margin-right: 16px;
}

.ContactYourAgent .ContentImage{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*width: 63px;*/
    /*height:63px;*/
    padding-bottom: 40px;
}

.ContactYourAgent .Content{
    color: #000000;
    font-family: Inter, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 60px;
}

.ContactYourAgent .Content.CenterContent{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ContactYourAgent .Content.SubTitle{
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: bold;
}

.ContactYourAgent .ContactMethods {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #000000;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
}

.ContactYourAgent .ContactMethods .VLine{
    height: 38px;
    width: 1px;
    border: 1px solid #4EC2F6;
    color: #4EC2F6;
}

.ContactYourAgent .ContactMethods .ContactMethod{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ContactYourAgent .ContactMethods .ContactMethod.Phone{
    color: #282B3E;
    font-family: Inter-SemiBold, sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
    text-decoration: none!important;
}
.ContactYourAgent .ContactMethods .ContactMethod .PhoneImg{
    height: 24px;
    width: 24px;
    margin-right: 12.99px;
}

.ContactYourAgent .ContactMethods .ContactMethod.WhatsApp{
    color: #282B3E;
    font-family: Inter-SemiBold, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    text-decoration: none;
}
.ContactYourAgent .ContactMethods .ContactMethod .WhatsAppImg{
    height: 35px;
    width: 31px;
    margin-right: 15px;
}
.ContactYourAgent .ContactMethods .ContactMethod.Email{
    margin-right: 18px;
    color: #282B3E;
    font-family: Inter-SemiBold, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    text-decoration: none;
}
.ContactYourAgent .ContactMethods .ContactMethod .EmailImg{
    height: 24px;
    width: 33px;
    margin-right: 17px;
}

.ContactYourCorporateAgent .Content{
    color: #282B3E;
    font-family: Inter-SemiBold, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 60px;
}
.ContactYourCorporateAgent .ContactMethods {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #282B3E;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 21px;
    /*margin-bottom: -36px;*/
}

.ContactYourCorporateAgent .ContactMethods .ContactMethod{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ContactYourCorporateAgent .ContactMethods .ContactMethod.Phone{
    color: #282B3E;
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
}
.ContactYourCorporateAgent .ContactMethods .ContactMethod .PhoneImg{
    height: 28.02px;
    width: 28.02px;
    margin-right: 12.99px;
}
.Declarations{
    margin-left: 38px;
    font-family: Inter , sans-serif !important;
    padding-left: 10px;
}
.Declarations li.DeclarationFormat::before {
    padding-right: 10px;
}
.PaymentDeclarationGroups{
    margin-left: -18px;
}
div.PaymentDeclartionGroupOverride .Item {
    margin-left: 0px;
}
.applyButton,
.backButton {
    width: 200px;
}
@media (max-width: 1024px) {
    .ContactYourAgent .ContactMethods{
        flex-direction: column;
        align-items: flex-start;
    }
    .ContactYourAgent .ContactMethods .VLine{
        border: none!important;
        background-color: transparent!important;
    }
}

/*
   -----------   pop style ----------------
*/
.ModalBody {
    border-radius: 0px !important;
}

.ModalTop {
    margin-top: -48px !important;
    margin-left: -24px !important;
    height: 4px;
    width: 512px;
    background: linear-gradient(270deg, #68D88E 0%, #00D5BE 100%);
}

.ModalIcon {
    position: absolute;
    top: 55px;
    left: 214px;
    height: auto;
    width: auto;
}

.ModalTitle {
    text-align: left;
    color: #003DA5;
    font-family: Stag-Medium, sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 36px;
}

.ModalContent {
    font-family: Arial,Noto, sans-serif;
}

.large{
    width: 1000px;
    padding: 42px 52px 30px 45px;
}
.large .ModalContent{
    padding-top: 42px;
}

.normal{
    width: 640px;
    padding: 42px 45px 53.5px 37px;
}
.normal .ModalContent{
    padding-top: 33px;
}

.small{
    width: 511px;
    padding: 43px 42px 47px 31px;

}
.small .ModalContent{
    padding-top: 45px;
}
@media (max-width: 1024px) {
    .ModalTitle {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 21px;
    }

    .ModalContent {
        font-family: Arial,Noto, sans-serif;
    }

    .large{
        width: 1000px;
        padding: 21px 14px 15px 14px;
    }
    .large .ModalContent{
        padding-top: 42px;
    }
    .small{
        width: 511px;
        padding: 46px 18px 30px 41px;

    }
    .small .ModalContent{
        padding-top: 45px;
    }
}
/*
   -----------   pop style ----------------
*/

.QuoteTable .OptionalCover td:last-child,
.QuoteTable .PlanCover td:last-child
{
    width: 54px;
}

.Quote .QuoteApplyButton .ProgressControl {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Quote .QuoteApplyButton .ProgressControl .space {
    width: 28px;
}

.Quote .QuoteRemarks {
    color: #000000;
    font-family: Inter, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    padding-top: 15px;
    box-sizing: border-box;
    padding-left: 35px;
}

.Quote .DisableSelect {
    pointer-events:none;
    background-color: #ededed;
}

.Quote .InsuredPersonBanner {
    width: 1350px;
    min-height: 60px;
    display: flex;
    font-family: Inter, sans-serif;
    flex-direction: row;
    align-items: center;
    border-radius: 6px;
    background: rgba(179, 229, 252, 0.5);
}

.Quote .InsuredPersonBanner .InsuredPersonBox {
    width: 366px;
    padding-left: 36px;
    box-sizing: border-box;
    overflow-wrap: break-word;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Quote .InsuredPersonBanner .InsuredPersonBox .Icon {
    height: 30px;
    margin-right: 20px;
    overflow: visible;
}
.Quote .InsuredPersonBanner .InsuredPersonBox .AgeGroup {
    color: #282B3E;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
}

.Quote .WiderBox {
   /*width: 298px;*/
}

@media (max-width: 1440px) {
    /*.QuoteTable tbody {display: block;border-spacing: 0; width: 978px!important;}*/
    .QuoteTableHeader .Arrow{
        max-width: 34px;
        width: 34px;

    }
    .QuoteTable .TopLeft>div .HLine{
        box-sizing: border-box;
        height: 2px;
        width: 139px!important;
        border-bottom: 1px dashed #8E90A2;
        flex-shrink: 0;
    }

    .QuoteTable .SubPlanCover .value, .QuoteTable .OptionalCover .value, .QuoteTable .PlanCover .value {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 3px;
        height: 63px;
    }

    .TitleDesc{
        font-size: 16px;
    }
    .OptionalCover1Remark ul,
    .OptionalCover2Remark ul{
        padding-left: 10px;
    }
}
@media (max-width: 1024px) {
    .TableHeaderGroup.FixTop {
        width: 100vw;
        margin-right: 29px;
        position: fixed;
        background-color: #fafafa;
        z-index: 2;
        margin-top: -18px;
    }
    .TableBody.FixTop {
        padding-top: 191px;
        /* height: 900px; */
        /* background-color: #fafafa; */
    }
    .BottomRemarkAndControl .MinPremiumRemark {
        margin-left: 20px;
        margin-right: 20px;
    }
    .WhiteTick {
        fill: #702F8A;
    }
    .QuoteTableHeader{
        overflow-x: scroll;
        justify-content: flex-start;
        /*overflow-y: hidden;*/
    }
    html{
        max-width: 100vw;
        overflow: hidden;
    }

    .QuoteTableHeader::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .QuoteTableHeader{
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .QuoteTableHeader .Arrow{
        max-width: 32px;
        width: 32px;
    }
    .QuoteTable .TopLeft>div .HLine{
        box-sizing: border-box;
        height: 2px;
        width: 139px!important;
        border-bottom: 1px dashed #8E90A2;
    }

    .QuoteTable .SubPlanCover .value, .QuoteTable .OptionalCover .value, .QuoteTable .PlanCover .value {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 3px;
        height: 63px;
    }

    .TitleDesc{
        font-size: 16px;
    }
    .OptionalCover1Remark ul,
    .OptionalCover2Remark ul{
        padding-left: 10px;
    }
    .QuoteApplyButton{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        z-index: 3;
    }
    .QuoteApplyButton>div{
        width: 100vw;
    }
    .BottomRemarkAndControl .RemarkHeading {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
        box-sizing: border-box;
        padding-top: 17px;
        padding-bottom: 19px;
        border-top: 2px solid #ededed;
        border-bottom: 2px solid #ededed;
        margin-left: 20px;
        margin-right: 20px;
    }
    .QuoteMobileBottomSpace{
        display: block;
        height: 56px;

    }
    .BottomRemarkAndControl .ContactAgentRemark {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
        margin-left: 0px;
        margin-right: 20px;
        margin-bottom: 32px;
    }
    .QuoteMobileDiscount{
        display: flex;
        flex-direction: column;
        width: calc(100vw - 40px);
        box-sizing: border-box;
        margin-left: 20px;
        margin-right: 20px;
        background-color: #361558;
        padding-left: 16px;
        padding-right: 14px;
        padding-top: 15px;
        padding-bottom: 17px;
        margin-top: 9px;
    }
    .QuoteMobileDiscount.Hide{
        display: none;
    }
    .QuoteMobileDiscount .Title{
        color: #FFFFFF;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .QuoteMobileDiscount .Title img{
        height: 12px;
        width: 18px;
    }
    .QuoteMobileDiscount .Content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 12px;
    }
    .QuoteMobileDiscount .Content > div{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .QuoteMobileDiscount .Content .HLine{
        margin-top: 13px;
        margin-bottom: 9px;
        border-bottom: 1px dashed #FFFFFF;
        max-height: 1px;
        height: 1px;
        align-self: stretch;
    }
    .QuoteMobileDiscount .Content .PromoIcon{
        height: 27px;
        width: 27px;
        margin-right: 12px;
    }
    .QuoteMobileDiscount .Content .NavyFamily{
        height: 23px;
        width: 27px;
        margin-right: 12px;
    }
    .QuoteMobileDiscount .Content .Discount{
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 14px;
        color: #FFF;
    }
    .OptionalCover1Remark,
    .OptionalCover2Remark{
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
    }
    .OptionalCover1Remark ul,
    .OptionalCover2Remark ul{
        padding: 0 20px;
    }

    .OptionalCover1Remark li,
    .OptionalCover2Remark li{
        display: table-row;

    }
    .OptionalCover1Remark li::before,
    .OptionalCover2Remark li::before{
        content: '•';
        display: table-cell;
        padding-right: 15px;
    }

    .RemarkBody ul{
        padding-right: 20px;
    }
    .Title .Open{
        transform: rotate(180deg);
    }
    .Quote .QuoteApplyButton .ProgressControl {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 75px;
        background-color: #FFF;

    }

    .Quote .QuoteApplyButton .ProgressControl .space {
        width: 28px;
    }
}
.QuoteModalContent{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.QuoteModalTitle{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.QuoteModalTitle span{
    color: #003DA5;
    font-family: Stag-Medium, sans-serif;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 36px;
}
.QuoteModalTitle .Icon{
    height: 50px;
    width: 50px;
}
.QuoteModalContent .MainContent{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: -1.5px;
}

.QuoteSliderPopupRemark{
    width: 100%;
    border-radius: 6px;
    background-color: rgba(237, 237, 237, 0.7);
    color: #0A1F44;
    font-family: Inter, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    padding: 8px 24px;
    box-sizing: border-box;
    margin-top: 12px;
}
.Title.ToolTip {
    display: flex;
    flex-direction: row;
    align-items: center;
}
@media (max-width: 1024px) {
    .Title.ToolTip {
        flex-direction: column;
        align-items: flex-start;
    }
    .Title.ToolTip .balloontips {
        margin-left: 0;
    }
}
.AdjustMinus,
.AdjustPlus{
    width: 25px;
    height: 25px;
    cursor: pointer;
}
.AdjustMinus .Content,
.AdjustPlus .Content{
    fill: #FFF;
}
.AdjustMinus{
    padding-right: 33px;
}
.AdjustPlus{
    padding-left: 33px;
}
.AdjustMinus.Disabled,
.AdjustPlus.Disabled{
    opacity: 0.3;
    cursor: not-allowed!important;
}

@media (max-width: 1024px) {
    .AdjustMinus .Content,
    .AdjustPlus .Content{
        fill: #009AE4;
    }
    .AdjustMinus{
        padding-right: 2px;
    }
    .AdjustPlus{
        padding-left: 2px;
    }
}


.QuoteTableHeader .Arrow.HKG {
    max-width: 54px;
    width: 54px;
    flex-shrink: 0;
}
@media (max-width: 1440px){
    .QuoteTableHeader .Arrow.HKG {
        padding-right: 5.5px;
    }
}

.AddOn.HKG .RightGroup {
    max-width: 660px;
    width: 660px;
}



.Plan.HKG,.Plan.HKG.Picked,
.OptionalCover.HKG .Value, .PlanCover.HKG .Value,
.OptionalCover.HKG.Sub .Value, .PlanCover.HKG.Sub .Value,
.OptionalCover.HKG.Sub .Value.Picked, .PlanCover.HKG.Sub .Value.Picked,
.OptionalCover.HKG .Value.Picked, .PlanCover.HKG .Value.Picked {
    min-width: 179px;
    flex-grow: 1;
    margin-right: 4px;
    flex-shrink: 0;
    width: 260px;
    max-width: 260px;
}

.OptionalCover.HKG .Value.Picked.WiderBox,
.OptionalCover.HKG .Value.WiderBox{
    width: 660px;
    max-width: 660px;
}

.AddOn.HKG .Value.WiderBox .fieldContainer{
    flex-grow: 1;
    margin-right: 19px;
}
.OptionalCover.HKG .Arrow, .PlanCover.HKG .Arrow {
    width: 54px;
    text-align: center;
    flex-shrink: 0;
}
@media (max-width: 1079px){
    .Plan.HKG,.Plan.HKG.Picked,
    .OptionalCover.HKG .Value, .PlanCover.HKG .Value,
    .OptionalCover.HKG.Sub .Value, .PlanCover.HKG.Sub .Value,
    .OptionalCover.HKG.Sub .Value.Picked, .PlanCover.HKG.Sub .Value.Picked,
    .OptionalCover.HKG .Value.Picked, .PlanCover.HKG .Value.Picked {
        min-width: 179px;
        flex-grow: 1;
        margin-right: 4px;
        flex-shrink: 0;
        width: 250px;
        max-width: 250px;
    }
}
@media (max-width: 1050px){

}
@media (max-width: 1440px){
    .AddOn.HKG .Arrow {
        width: 54px;
        flex-shrink: 0;
        text-align: right;
        padding-right: 5.5px;
    }
}


.AddOn.HKG.UnspecificValuesOptionalCover.Remark{
    margin-top: -8px;
    border-top: none;
    padding-left: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.Plan.HKG .AnnualPremium{
    color: #FFFFFF;
    font-family: Inter-Medium, sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 25px;
}
.Plan.HKG .PlanName{

    font-family: Inter-Bold, sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: 30px;
    margin-bottom: 3px;
}
.Plan.HKG .PlanName,
.Plan.HKG .AnnualPremium {
    color: #009AE4;
}
.Plan.HKG.Picked .AnnualPremium,
.Plan.HKG.Picked .PlanName{
    color: #FFFFFF;
}



.Quote .PaymentDeclarationGroups .container {

    padding-left: 70px;
}
.Quote .PaymentDeclarationGroups .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 4px solid #eee;
    background-color: transparent;
    box-sizing: border-box;
    margin-left: 18px;

}
.Quote .PaymentDeclarationGroups .container .checkmark:after {
    left: 8px;
    top: 4px;
    width: 7px;
    height: 13px;
    border: solid #702F8A;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
    color: #702F8A;
}
.Quote .QuoteUnspecifiedPersonalValuablesRemarkLabel{
    padding-top: 19.5px;
    padding-bottom: 32px;
    color: #0A1F44;
    font-family: Inter-Bold, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
}

.Quote .PaymentDeclarationGroups  .checkmark1 {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 4px solid #eee;
    background-color: transparent;
    box-sizing: border-box;
    margin-left: 18px;
    margin-right: 13px;
}
.Quote .PaymentDeclarationGroups .checkmark1.purple{
    border: 4px solid #702F8A;
}
.Quote .PaymentDeclarationGroups  .checkmark1.checked {
    border-color: #702F8A;
    color: #702F8A;
}
.Quote .PaymentDeclarationGroups  .checkmark1.checked::after {
    left: 31px;
    top: 13px;
    width: 7px;
    height: 13px;
    border: solid #702F8A;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
    color: #702F8A;
    position: absolute;
    content: ' ';
}

.ErrorFormat {
    display: block;
}

@media (max-width: 1024px) {
    .HKG-template .Plan.HKG,.HKG-template  .Plan.HKG.Picked,
    .HKG-template  .OptionalCover.HKG .Value,
    .HKG-template  .PlanCover.HKG .Value,
    .HKG-template  .OptionalCover.HKG.Sub .Value,
    .HKG-template  .PlanCover.HKG.Sub .Value,
    .HKG-template  .OptionalCover.HKG.Sub .Value.Picked,
    .HKG-template  .PlanCover.HKG.Sub .Value.Picked,
    .HKG-template  .OptionalCover.HKG .Value.Picked,
    .HKG-template  .PlanCover.HKG .Value.Picked {
         min-width: unset;
        /*flex-grow: 1;*/
        /*margin-right: 4px;*/
         flex-shrink: 1;
         width: auto;
        max-width: unset;
        /*max-width: 290px;*/
    }
    .HKG-template .Plan.HKG,
    .HKG-template .Plan.HKG.Picked{
        width: 166px;
    }
    .HKG-template .Plan .PriceGroup .Price {
        font-size: 28px;
        line-height: 32px;

    }
    .HKG-template .Plan {
        padding-bottom: 15px;
    }
    .HKG-template .Plan.HKG .PlanName {
        margin-top: 16px;

    }
    .HKG-template .TableHeaderGroup{
        top: 109px!important;
    }
    .QuoteMobileDiscount{
        margin-top: -30px;
        background: linear-gradient(90deg, #003DA5 0%, #00A8F3 100%);
        margin-left: 0px;
        border-radius: 6px;
        margin-bottom: 12px;
    }
    .QuoteWhiteCoupon{
        margin-right: 12px;
    }
}
@media (min-width: 1024.0001px) {
    .sgpQuoteApplyBtn,
    .sgpQuoteBackBtn{
        width: 200px;
    }
}
.promoteCodeContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.promoteCodeContainer .label{
    margin-top: -18px;
    font-family: Inter-Medium, sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    margin-right: 16px;
}
.promoteCodeContainer .pCodeInput{
    width: 300px;
}
#Template > div.Quote.QuoteTemplateBodyContainer.QuoteTablePoint div.PlanName.MobilePlanNameCover > span{
    color: #000000;
    font-family: Inter, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
}
.RightGroup .VLine {
    height: 152px;
}
.VLine {
     height: 26px;
     width: 1px;
     margin-left: 2%;
     margin-right: 2%;
     box-sizing: border-box;
     border-left: 1px solid #4EC2F6;
}
@media (max-width: 1024px) {
    .promoteCodeContainer{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .promoteCodeContainer .label{
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 12px;
    }
    .QuoteModalTitle span{
        color: #003DA5;
        font-family: Stag-Medium, sans-serif;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 25px;
    }
    .QuoteModalTitle .Icon{
        height: 50px;
        width: 50px;
    }
    .AdditionalCoverageModalContainer .Button{
        width: 200px!important;
    }
    .Quote .PaymentDeclarationGroups .checkmark1 {
        width: 30px!important;
        height: 30px;
        border-radius: 50%;
        border: 3.5px solid #eee;
        background-color: transparent;
        box-sizing: border-box;
        margin-left: 18px;
        margin-right: 13px;
        min-width: 30px;
    }
    .Quote .PaymentDeclarationGroups .checkmark1.purple{
        border: 3.5px solid #702F8A;
    }
    .Quote .PaymentDeclarationGroups .checkmark1.checked::after {
        left: 29px;
        top: 7px;
        width: 5px;
        height: 10px;
        border: solid #702F8A;
        border-width: 0 3.5px 3.5px 0;
        transform: rotate(45deg);
        color: #702F8A;
        position: absolute;
        content: ' ';
    }
    .Quote .QuoteRemarks{
        padding-left: 27px;
    }
    .RemarkBody ul{
        /*list-style: none;*/
        list-style: disc;
        padding-left: 0px;
        padding-right: 0;
    }
    .RemarkBody ul li{
        padding-left: 23.5px;
    }
    .RemarkBody ul ol{
        padding-inline-start: 0;
    }
    .Declarations{
        margin-left: 0px;
        padding-left: 25px;
    }
    .Declarations ul{
        padding-left: 0;
    }
    .Declarations ul li.DeclarationFormat{
        padding-left: 23.5px;
    }
    .Declarations li.DeclarationFormat::before {
        padding-right: 32px;
    }
    .Quote .PaymentDeclarationGroups .checkmark1 {

         margin-right: 19px;

    }
    .BottomRemarkAndControl .ContactAgentRemark{
        font-family: Inter-Bold, sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
        margin-right: 0;
    }
    .BottomRemarkAndControl {
        margin-top: 18px;
    }
    .Value.Picked.WiderBox{
        font-family: Inter-Bold, sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
        margin-bottom: 16px;
    }
    .TableHeading {
        font-family: Inter-Bold, sans-serif;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
    }
    .OptionalHeading{
        font-family: Inter-Bold, sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
    }
    .PlanCover .Icon{
        padding-left: 20px!important;
    }
    .PlanCover .Title.title-group .Title ,
    .PlanCover .Title.title-group .Content {
        padding-left: 12px;
        min-width: unset;
        flex-grow: 1;
    }
    .PlanCover .Title.title-group{
        padding-left: 0px;
        box-sizing: border-box;
        min-width: 30%;
        width: 30%;
        /*width: 120px;*/
        /*max-width: 40%;*/
    }
    .OptionalCover .Value, .PlanCover .Value {
        min-width: 110px;
    }
    .Icon.AdjustMinus{
        padding-left: 8px!important;
        padding-right: 6px!important;
    }
    .Icon.AdjustPlus {
        padding-left: 6px!important;
    }
    .AddOn .InsuredPersonBox{
        padding-left: 0;
        align-items: flex-start;
    }
    .AddOn .InsuredPersonBox .AgeGroup {
        padding-top: 3px;
        margin-bottom: 4px;
        font-family: Inter-Bold, sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
    }
    #Template:not(.HKG-template) .Quote .Plan.Picked {
        margin-left: 111px!important;
    }
    .AddOn .Value {
        /* width: 223px; */
        min-height: 60px;

    }
    .Value.Picked.WiderBox img{
        width: 26px;
        height: 26px;
    }
    .AddOn .RightGroup {
        padding-bottom: 0;
        font-family: Inter, sans-serif;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 27px;
    }
    #Template > div.Quote.QuoteTemplateBodyContainer.QuoteTablePoint div.RightGroup .Value.Picked.WiderBox{
        padding-bottom: 0;
        font-family: Inter, sans-serif;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 27px;
    }
    .AddOn.UnspecificValuesOptionalCover{
        padding-bottom: 12px;
    }

    .AddOn.UnspecificValuesOptionalCover .RightGroup {
        padding-bottom: 0;
        font-family: Inter, sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
    }
    #Template > div.Quote.QuoteTemplateBodyContainer.QuoteTablePoint .AddOn.UnspecificValuesOptionalCover  div.RightGroup .Value.Picked.WiderBox {
        margin-bottom: 0;
    }
    .TableHeaderGroup {
        z-index: 1;
        top: 110px!important;
    }
    .Declarations{
        margin-left: 19px;
        padding-left: 5px!important;
        box-sizing: border-box;
    }
    .sgpQuoteApplyBtn{
        width: 200px;
    }

}
@media(min-width: 1024.0001px) {
    .RemarkBody ul{
        padding-left: 0;
        list-style: disc;
    }
    .RemarkBody ul li {
        padding-left: 15px;
    }
    .Declarations{
        margin-left:22.5px;
    }
    .Declarations li.DeclarationFormat::before {
        padding-right: 25px;
    }
}
@media (max-width: 650px) {
    .Plan .PriceGroup{
        font-size: 12px;
    }
    .HKG-template .Plan .PriceGroup .Price{
        font-size: 18px;
    }
    .HKG-template .Plan.HKG, .HKG-template .Plan.HKG,
    .HKG-template .Plan.HKG, .HKG-template .Plan.HKG.Picked {
        min-width: 166px;
    }
}
@media (max-width: 450px){
    .PlanCover .Icon {
        padding-left: 5px!important;
    }
    .PlanCover>.Title{
        max-width: 45%;
    }
    .PlanCover .Title.title-group{
        width: 45%;
        min-width: 45%;
    }
    div.Title.title-group > div{
        max-width: none;
    }

}
